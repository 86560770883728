import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { FreeMode, Pagination } from "swiper/modules";
import Card from "../Home/SliderCard";
import { SectionSixReviewInterface } from "../../interfaces";

const HomeSlider: React.FC<{ review: SectionSixReviewInterface[] }> = ({
  review,
}) => {
  return (
    <Swiper
      slidesPerView={4}
      spaceBetween={30}
      freeMode={true}
      pagination={{
        clickable: true,
      }}
      modules={[FreeMode, Pagination]}
      className="mySwiper h-full w-full"
      breakpoints={{
        0: {
          slidesPerView: 1.2,
        },
        768: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 3.3,
        },
        1280: {
          slidesPerView: 4,
        },
      }}
    >
      {review &&
        review.map((item: SectionSixReviewInterface, index: number) => (
          <SwiperSlide
            key={index}
            className={
              index % 2 === 0
                ? "h-auto pb-20 md:pb-5 2xl:pb-20 flex justify-center"
                : "pb-20 md:pb-20 2xl:pb-20 pt-0 md:pt-20 flex items-center justify-center"
            }
          >
            <Card review={item} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default HomeSlider;
