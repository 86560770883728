import React from "react";

interface propTypes {
  isActive: boolean;
  url: string;
  onClick: () => void;
}

const Card = ({ isActive, url, onClick }: propTypes) => {
  return (
    <div
      className={`w-16 h-16 rounded-lg border p-2 md:p-3 transition-all duration-300 flex items-center justify-center cursor-pointer ${
        isActive
          ? "bg-mainColor bg-opacity-40 border-mainColor border-opacity-20"
          : "bg-gray-50"
      }`}
      onClick={onClick}
    >
      <img src={url} alt="" />
    </div>
  );
};

export default Card;
