import React from "react";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../../../interfaces";
import { Badge } from "primereact/badge";

const Badges: React.FC<{
  data: string[];
}> = ({ data }) => {
  return (
    <>
      <div className="flex items-center space-x-2 py-5 2xl:py-16 border border-gray-50 border-r-0 border-l-0 h-20">
        {data.length > 0 &&
          data
            .slice(0, 2)
            .map((industry: string, index: number) => (
              <Badge
                key={index}
                value={industry}
                className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
              />
            ))}
        {data.length - 2 > 0 && (
          <Badge
            value={`${data.length - 2}+`}
            className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
          />
        )}
      </div>
    </>
  );
};

export default Badges;
