export const REQ = {
  AUTH: {
    SIGN_IN: "/auth/signin",
    SIGN_UP: "/auth/signup",
    VERIFY: "/auth/verify-user",
    LOG_OUT: "/auth/logout",
    CHANGE_PASSWORD: "/user/change-password",
    FORGOT_PASSWORD: "/auth/forgotPassword",
    GOOGLE_SIGN_IN: "/auth/google-signin",
    RESET_PASSWORD: "/auth/reset-password",
    VERIFY_ACCOUNT: "/auth/verify-account",
    RESEND_EMAIL: "/auth/resend-email",
  },
  USER: {
    GET_ALL_PAGINATED: "/user/paginated",
    CREATE: "/user",
    UPDATE: "/user/:id",
  },
  SUBSCRIPTION: {
    GET_FILTERED_SUBSCRIPTIONS: "/subscription/paginated-filters",
    CREATE_SUBSCRIPTION: "/subscription/stripe/create-checkout-session",
    UPDATE_SUBSCRIPTION: "/subscription/:id",
    MANAGE_BILLING_PORTAL: "/subscription/stripe/create-portal-session",
  },
  INFLUENCER: {
    GET_ALL_PAGINATED: "/influencer/paginated",
    FILTERS_PAGINATED: "/influencer/paginated-filters",
    CREATE: "/influencer",
    GET_COUNT: "/influencer/count",
    GET_BY_ID: "/influencer/:id",
  },
  INVESTOR: {
    GET_ALL_PAGINATED: "/investor/paginated",
    FILTERS_PAGINATED: "/investor/paginated-filters",
    CREATE: "/investor",
    GET_COUNT: "/investor/count",
    GET_BY_ID: "/investor/:id",
  },
  FUND: {
    GET_ALL_PAGINATED: "/fund/paginated",
    FILTERS_PAGINATED: "/fund/paginated-filters",
    CREATE: "/fund",
    GET_COUNT: "/fund/count",
    GET_BY_ID: "/fund/:id",
  },
  CONTACT_US: {
    CREATE: "/contact-us",
  },
  STORE_HTML: {
    GET: "/store-html",
  },
  SAVED_FILTER: {
    CREATE: "/saved-filter",
    GET_NAMES_BY_USER_ID: "saved-filter/names/:userId",
    GET_BY_ID: "/saved-filter/get-paginated-filters-data/:id",
    REMOVE_FROM_SAVE_LIST_BY_ID: "/saved-filter/remove-from-save-list/:id",
    ADD_FROM_SAVE_LIST_BY_ID: "/saved-filter/add-to-save-list/:id",
    DELETE_BY_ID: "/saved-filter/:id",
  },
  OUT_REACH: {
    CREATE: "/out-reach",
    GET_FILTERS_PAGINATED_BY_USER_ID: "/out-reach/paginated-filters/:userId",
    UPDATE: "/out-reach/:id",
  },
  FILTER: {
    GET_BY_NAME: "/filter",
  },
  GENERATE_AI_EMAIL: "/test",
  UPLOAD_FILE: "/upload-file",
};
