import React, { ButtonHTMLAttributes } from "react";
import LoaderSpinner from "../../Loaders/LoaderSpinner";

interface propsTypes extends ButtonHTMLAttributes<HTMLButtonElement> {
  className: string;
  padding: string;
  img?: string;
  text: string;
  icon: React.ReactElement | string;
  disabled?: boolean;
  isLoader?: boolean;
}

const Button = React.forwardRef(
  (
    {
      className,
      padding,
      img,
      text,
      icon,
      disabled,
      isLoader,
      ...props
    }: propsTypes,
    ref: any
  ) => {
    return (
      <button
        {...props}
        ref={ref}
        className={`${className} ${padding} transition-all duration-300 font-medium flex items-center gap-3`}
        disabled={disabled}
      >
        {img && (
          <img className="w-10 h-10 rounded-full" alt="userImg" src={img} />
        )}
        {text}
        {icon}
        {isLoader && <LoaderSpinner className="group-hover:text-mainColor" />}
      </button>
    );
  }
);

export default Button;
