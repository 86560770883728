import React from "react";

const VerifiedCheck = () => {
  return (
    <img
      className="w-[22px] h-[22px]"
      src={"/images/AllInvestors/Circle Wavy Check.svg"}
      alt="Check"
    />
  );
};

export default VerifiedCheck;
