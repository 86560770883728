import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES_CONSTANT } from "../../../constant";

const Logo = () => {
  return (
    <NavLink to={ROUTES_CONSTANT.HOME}>
      <img
        alt="logo"
        className="h-auto w-auto 2xl:w-44"
        src="/images/Logo.svg"
      />
    </NavLink>
  );
};

export default Logo;
