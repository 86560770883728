import React from "react";
import Input from "../components/Auth/Input";
import Button from "../components/common/Button";
import Cross from "../components/svg/Cross";
import { useUi } from "../hooks/user-interface";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { REQ } from "../constant/api-endpoints";
import { fetchRequest } from "../utils/axios";
import { HTTP_METHODS, HTTP_STATUS_CODES } from "../constant";
import SmallLoader from "../components/Loaders/SmallLoader";
import { ForgotPasswordSchema } from "../validation-schema";

const ForgotPass = () => {
  const { hideModal } = useUi();

  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit: fromSubmit,
    formState: { errors },
  } = useForm({ resolver: ForgotPasswordSchema });

  const handleSubmit = async (body: any) => {
    try {
      setLoading(true);

      const response = await fetchRequest({
        url: REQ.AUTH.FORGOT_PASSWORD,
        type: HTTP_METHODS.POST,
        body,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Reset link sent successfully");
        hideModal();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed top-0 left-0 bg-black bg-opacity-20 h-full w-full flex items-center justify-center">
      <form
        className="bg-white rounded-2xl md:rounded-3xl pt-12 pb-7 md:py-20 w-11/12 md:w-10/12 lg:w-7/12 xl:w-6/12 flex justify-center relative"
        onSubmit={fromSubmit(handleSubmit)}
      >
        <div
          onClick={hideModal}
          className="absolute -right-4 md:right-4 top-3 md:top-4"
        >
          <Cross className="w-6/12 h-auto md:w-auto" />
        </div>
        <div className="w-11/12 md:w-9/12 lg:w-8/12 flex flex-col gap-10">
          <Input
            {...register("email", { required: true })}
            className="mb-4 rounded-full"
            label="Enter your Email Address"
            placeHolder="Email Address"
            error={errors.email?.message}
          />
          {loading ? (
            <div className="py-4 2xl:py-6 mb-12">
              <SmallLoader />
            </div>
          ) : (
            <Button
              padding="py-3 2xl:py-6"
              className="hover:bg-mainColor bg-mainTextColor w-full font-medium text-base 2xl:text-2xl text-white flex justify-center rounded-rounded63"
              text="Send Email"
              icon=""
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default ForgotPass;
