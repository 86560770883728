import React from 'react'

const Instagram = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 2xl:h-7 2xl:w-7">
      <path d="M12.9375 0H5.0625C2.27087 0 0 2.27087 0 5.0625V12.9375C0 15.7291 2.27087 18 5.0625 18H12.9375C15.7291 18 18 15.7291 18 12.9375V5.0625C18 2.27087 15.7291 0 12.9375 0ZM9 13.5C6.51873 13.5 4.5 11.4818 4.5 9C4.5 6.51819 6.51873 4.5 9 4.5C11.4813 4.5 13.5 6.51819 13.5 9C13.5 11.4818 11.4813 13.5 9 13.5ZM14.0625 4.5C13.7519 4.5 13.5 4.24814 13.5 3.9375C13.5 3.62679 13.7519 3.375 14.0625 3.375C14.3731 3.375 14.625 3.62679 14.625 3.9375C14.625 4.24814 14.3731 4.5 14.0625 4.5Z" fill="#111111" />
    </svg>
  )
}

export default Instagram