import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANT } from "../../constant";
import { InfoBoxInterface } from "../../interfaces";
import CountdownTimer from "../CountdownTimer";
import { useUserAuth } from "../../hooks/auth.hook";

const InfoBox: React.FC<InfoBoxInterface> = ({
  mainText,
  secondaryText,
  image,
}) => {
  const navigate = useNavigate();
  const { refetchUser } = useUserAuth();

  return (
    <div className="flex items-center justify-center flex-col gap-10 flex-shrink-0 rounded-3xl w-5/6 h-4/6 mb-25 max-w-[1180px] lg:max-w-[1210px] 2xl:max-w-[2200px] mx-auto px-2 md:px-8 lg:px-2 bg-mainColor">
      <div className="flex items-center justify-center flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row md:px-4 gap-3 w-3/5 sm:w-3/5 md:w-2/5 lg:2/5 xl:2/5 h-2/6 bg-white shadow-box_shadow rounded-2xl">
        {mainText && (
          <h1 className="text-mainTextColor text-sm md:text-sm lg:text-sm font-bold text-center leading-[70px] capitalize">
            {mainText}
          </h1>
        )}
        {!mainText && image && (
          <img
            src={image}
            alt={image}
            className="max-w-full h-auto rounded-lg"
          />
        )}
        {secondaryText && (
          <p className="text-xs sm:text-sm md:text-[15px] lg:text-[15px] xl:text-[15px] font-normal text-center text-gray-500">
            {secondaryText}
          </p>
        )}
      </div>
      <div className="p-6 text-[14px] text-gray-500 flex items-center text-center justify-center flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row md:px-4 gap-3 w-2/5 sm:w-3/5 md:w-2/5 lg:w-2/5 xl:w-2/5 bg-white shadow-box_shadow rounded-2xl">
        Do not refresh the page.
        <br /> You will be redirected to the dashboard in{" "}
        <CountdownTimer
          name={"redirectPageTimer"}
          startSeconds={"5"}
          onStop={() => {
            refetchUser();
            navigate(ROUTES_CONSTANT.ALL_INVESTORS);
          }}
        />
        seconds.
      </div>
    </div>
  );
};

export default InfoBox;
