import React, { useRef } from "react";
import SmallLoader from "../../Loaders/SmallLoader";
import useOutsideAlerter from "../../../hooks/UseOutsideAlerter";

interface propTypes {
  className: string;
  loading: boolean;
  setShowRemoveSearchDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
}

const RemoveSearchDropDown = ({
  className,
  loading,
  setShowRemoveSearchDropDown,
  onClick,
}: propTypes) => {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, setShowRemoveSearchDropDown);

  return (
    <div
      ref={wrapperRef}
      className={`w-48 absolute right-0 top-12 2xl:top-20 shadow-lg py-2 bg-white rounded-md translate-y-2 ${className} transition-all duration-300 z-10`}
    >
      <div className="flex flex-col gap-y-2">
        {loading ? (
          <div className="flex item-center gap-2 pl-4 py-2">
            <div className="w-fit">
              <SmallLoader width="20" />
            </div>
            Deleting...{" "}
          </div>
        ) : (
          <p
            className="w-full transition-all duration-300 hover:bg-black hover:bg-opacity-10 pl-4 cursor-pointer py-2 text-sm 2xl:text-2xl font-medium text-red-500"
            onClick={onClick}
          >
            Remove Search
          </p>
        )}
      </div>
    </div>
  );
};

export default RemoveSearchDropDown;
