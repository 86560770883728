import React from 'react'

const Globe = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 9C16.5 9.9849 16.306 10.9602 15.9291 11.8701C15.5521 12.7801 14.9998 13.6069 14.3033 14.3033C13.6069 14.9998 12.7801 15.5521 11.8701 15.9291C10.9602 16.306 9.9849 16.5 9 16.5C8.0151 16.5 7.03981 16.306 6.12988 15.9291C5.21993 15.5521 4.39314 14.9998 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.9849 1.5 9C1.5 8.0151 1.69399 7.03981 2.07091 6.12987C2.44781 5.21993 3.00026 4.39314 3.6967 3.6967C4.39314 3.00026 5.21993 2.44781 6.12988 2.0709C7.03981 1.69399 8.0151 1.5 9 1.5C9.9849 1.5 10.9602 1.69399 11.8701 2.07091C12.7801 2.44781 13.6069 3.00026 14.3033 3.6967C14.9998 4.39314 15.5521 5.21993 15.9291 6.12988C16.306 7.03981 16.5 8.0151 16.5 9Z" stroke="#222222" />
      <path d="M12 9C12 9.9849 11.9224 10.9602 11.7716 11.8701C11.6209 12.7801 11.3999 13.6069 11.1213 14.3033C10.8427 14.9998 10.512 15.5521 10.148 15.9291C9.78405 16.306 9.39397 16.5 9 16.5C8.60602 16.5 8.21595 16.306 7.85198 15.9291C7.48797 15.5521 7.15726 14.9998 6.87868 14.3033C6.6001 13.6069 6.37912 12.7801 6.22836 11.8701C6.07759 10.9602 6 9.9849 6 9C6 8.0151 6.07759 7.03981 6.22836 6.12987C6.37912 5.21993 6.6001 4.39314 6.87868 3.6967C7.15726 3.00026 7.48797 2.44781 7.85198 2.0709C8.21595 1.69399 8.60602 1.5 9 1.5C9.39397 1.5 9.78405 1.69399 10.148 2.07091C10.512 2.44781 10.8427 3.00026 11.1213 3.6967C11.3999 4.39314 11.6209 5.21993 11.7716 6.12988C11.9224 7.03981 12 8.0151 12 9Z" stroke="#222222" />
      <path d="M1.5 9H16.5" stroke="#222222" strokeLinecap="round" />
    </svg>
  )
}

export default Globe