import { ROLE_TYPE } from "../constant";
import {
  EmailClientInterface,
  InfluencerInterface,
  InvestorInterface,
  StoreHtmlDataInterface,
} from "../interfaces";
import { UploadMedia } from "./upload-media";

export const ConvertArrayIntoObject = (
  array: StoreHtmlDataInterface[]
): StoreHtmlDataInterface => {
  return array.reduce((accumulator, currentObject) => {
    accumulator.id = currentObject.id;
    accumulator.sectionOne = currentObject.sectionOne;
    accumulator.sectionTwo = currentObject.sectionTwo;
    accumulator.sectionThree = currentObject.sectionThree;
    accumulator.sectionFour = currentObject.sectionFour;
    accumulator.sectionFive = currentObject.sectionFive;
    accumulator.sectionSix = currentObject.sectionSix;
    accumulator.sectionSeven = currentObject.sectionSeven;

    return accumulator;
  }, {} as StoreHtmlDataInterface);
};

export const CleanObject = (obj: { [key: string]: any }) => {
  const cleanedEntries = Object.entries(obj).filter(
    ([key, value]) =>
      value !== null && !(Array.isArray(value) && value.length === 0)
  );
  return Object.fromEntries(cleanedEntries);
};

export const CapitalizeFirstLetter = (input: any) => {
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  if (Array.isArray(input)) {
    return input.map((str) => {
      if (typeof str === "string") return capitalize(str);

      return str;
    });
  } else if (typeof input === "string") return capitalize(input);
  else return input;
};

export const ConvertToCSV = (
  objArray: InvestorInterface[] | InfluencerInterface[]
) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  const headers = Object.keys(array[0]);
  str += headers.join(",") + "\r\n";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in headers) {
      if (line !== "") line += ",";

      let value = array[i][headers[index]];
      if (Array.isArray(value)) {
        value = value.join(";");
      }
      line += value;
    }

    str += line + "\r\n";
  }

  return str;
};

export const ExportCSVFile = (name: string, data: any) => {
  const csvStr = ConvertToCSV(data);
  const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${name}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const UploadImage = async (
  image: File | null,
  callback: (res: string) => void
) => {
  if (!image) return "";

  const res: string = await UploadMedia(image);
  callback(res);
};

export const ConvertArrayIntoSelectOptions = (arr: string[]) => {
  return (
    arr &&
    arr.length > 0 &&
    arr.map((item: string) => ({
      label: item.charAt(0).toUpperCase() + item.slice(1),
      value: item.toLowerCase(),
    }))
  );
};

export const ConvertFiltersIntoQueryParams = (obj: { [key: string]: any }) => {
  const cleanBody = CleanObject(obj);
  const filterKeys = ["type", "industries", "geography", "stages", "location"];

  return filterKeys.reduce((acc: any, key) => {
    if (cleanBody[key]) {
      acc[key] = {
        $in: cleanBody[key].map(
          (value: string) => new RegExp(`^${value}$`, "i")
        ),
      };
    }
    return acc;
  }, {});
};

export const GetCurrentRole = (type: string): string => {
  return type === ROLE_TYPE.INVESTOR
    ? ROLE_TYPE.INVESTOR
    : type === ROLE_TYPE.INFLUENCER
    ? ROLE_TYPE.INFLUENCER
    : ROLE_TYPE.FUND;
};

export const GenerateRandomPassword = (): string => {
  const length = 8;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const ReplaceNewlineWithBreak = (str: string): string => {
  return str.replace(/\n/g, "<br/>");
};

export const RemoveBeforeFirstString = (
  str: string,
  letter: string
): string => {
  const index = str.indexOf(letter);
  if (index !== -1) {
    return str.slice(index);
  }
  return str;
};

export const onOpenEmailClient = (payload: EmailClientInterface) => {
  const { to, subject, body } = payload;

  const recipients = to.join(",");

  const mailtoLink = `mailto:${recipients}?subject=${encodeURIComponent(
    subject || ""
  )}&body=${encodeURIComponent(body)}`;

  window.open(mailtoLink);
};

export const GroupDataByRoleType = (data: any[]) => {
  return (
    data &&
    data.length > 0 &&
    data.reduce((acc, item) => {
      acc[item.type] = acc[item.type] || [];
      acc[item.type].push(item);
      return acc;
    }, {})
  );
};
