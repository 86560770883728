import { Button } from "@mui/material";
import React from "react";
import ActionDropDown from "../ActionDropDown";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../../../interfaces";

interface propTypes {
  rowData: InvestorInterface[] | InfluencerInterface[] | FundInterface[];
  text: string;
  style: React.CSSProperties;
  icon?: React.ReactElement;
}

const AddFilterDropDown: React.FC<propTypes> = ({
  rowData,
  text,
  style,
  icon,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        id="basic-button"
        style={style}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {text} {icon}
      </Button>
      <ActionDropDown
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        data={rowData}
      />
    </>
  );
};

export default AddFilterDropDown;
