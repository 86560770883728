import React, { useState } from "react";
import {
  HTTP_METHODS,
  HTTP_STATUS_CODES,
  ROUTES_CONSTANT,
} from "../../constant";
import LeftManImage from "../../components/Auth/LeftMan";
import RightManImage from "../../components/Auth/RightMan";
import { NavLink } from "react-router-dom";
import Input from "../../components/Auth/Input";
import Button from "../../components/common/Button";
import EyeOpen from "../../components/svg/EyeOpen";
import EyeClose from "../../components/svg/EyeClose";
import Cross from "../../components/svg/Cross";
import { ChangePasswordInterface } from "../../interfaces";
import toast from "react-hot-toast";
import { fetchRequest } from "../../utils/axios";
import { REQ } from "../../constant/api-endpoints";
import SmallLoader from "../../components/Loaders/SmallLoader";

const ChangePassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [formErrors, setFormErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const validateForm = () => {
    let errors: any = {};

    if (!currentPassword) {
      errors.currentPassword = "Current password is required";
    }

    if (!newPassword) {
      errors.newPassword = "New password is required";
    } else if (newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters";
    } else if (newPassword === currentPassword) {
      errors.newPassword =
        "New password must be different from current password";
    }

    if (!confirmNewPassword) {
      errors.confirmNewPassword = "Please confirm your new password";
    } else if (newPassword !== confirmNewPassword) {
      errors.confirmNewPassword = "Passwords do not match";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const onUpdateUser = async (payload: ChangePasswordInterface) => {
    try {
      setLoading(true);

      if (validateForm()) {
        const response = await fetchRequest({
          url: REQ.AUTH.CHANGE_PASSWORD,
          type: HTTP_METHODS.POST,
          body: payload,
        });

        if (response.status === HTTP_STATUS_CODES.OK) {
          toast.success("Password updated successfully");
          setLoading(false);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onChangePassword = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateForm();

    const payload: ChangePasswordInterface = {
      currentPassword,
      newPassword,
    };

    onUpdateUser(payload);
  };

  return (
    <section id="contactUs" className="w-full h-full relative">
      <NavLink
        to={ROUTES_CONSTANT.ALL_INVESTORS}
        className="absolute top-6 md:top-8 left-6 md:left-10 z-20 cursor-pointer"
      >
        <Cross className="" />
      </NavLink>
      <img
        alt="_contact"
        className="absolute left-0 top-0 w-full h-full object-cover"
        src="/images/SignIn/loginPattern.svg"
      />
      <div className="w-full py-28 md:py-20 lg:py-16 z-10 relative min-h-vh100">
        <LeftManImage
          className="max-sm:hidden hidden md:block"
          imgSize="w-full"
        />
        <RightManImage
          className="hidden md:block max-sm:hidden"
          imgSize="w-full"
        />
        <form className="max-md:px-3" onSubmit={onChangePassword}>
          <div className="border rounded-3xl w-2/5 mx-auto max-xl:w-[500px] max-sm:w-full bg-white bg-opacity-50">
            <div className="px-10 py-10 max-lg:pb-0 max-md:px-4 pb-0 2xl:py-16">
              <div className="gap-10 grid max-md:gap-6 2xl:gap-16 w-full">
                <h1 className="font-semibold text-2xl md:text-3xl xl:text-4xl text-mainTextColor text-center">
                  Change <span className="text-mainColor">Password</span>
                </h1>
                <div className="relative">
                  <div className="relative">
                    <Input
                      inputClassName="mb-4 rounded-full"
                      label="Current Password"
                      placeHolder="Current password"
                      type={showCurrentPassword ? "text" : "password"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentPassword(e.target.value)
                      }
                    />
                    <div
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      className="absolute right-4 bottom-8 text-xs cursor-pointer"
                    >
                      {showCurrentPassword ? <EyeClose /> : <EyeOpen />}
                    </div>
                  </div>
                  <p className="text-sm text-red-600">
                    {formErrors.currentPassword}
                  </p>
                </div>
                <div className="relative">
                  <div className="relative">
                    <Input
                      inputClassName="mb-4 rounded-full"
                      label="New Password"
                      placeHolder="New password"
                      type={showNewPassword ? "text" : "password"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewPassword(e.target.value)
                      }
                    />
                    <div
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      className="absolute right-4 bottom-8 text-xs cursor-pointer"
                    >
                      {showNewPassword ? <EyeClose /> : <EyeOpen />}
                    </div>
                  </div>
                  <p className="text-sm text-red-600">
                    {formErrors.newPassword}
                  </p>
                </div>
                <div className="relative">
                  <div className="relative">
                    <Input
                      inputClassName="mb-4 rounded-full"
                      label="Confirm Password"
                      placeHolder="Confirm password"
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setConfirmNewPassword(e.target.value)
                      }
                    />
                    <div
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="absolute right-4 bottom-8 text-xs cursor-pointer"
                    >
                      {showConfirmPassword ? <EyeClose /> : <EyeOpen />}
                    </div>
                  </div>
                  <p className="text-sm text-red-600">
                    {formErrors.confirmNewPassword}
                  </p>
                </div>
                {loading ? (
                  <div className="mb-10">
                    <SmallLoader />
                  </div>
                ) : (
                  <Button
                    padding="py-4 2xl:py-6"
                    className="rounded-rounded63 w-full hover:bg-mainTextColor bg-mainColor text-white text-xl 2xl:text-3xl font-medium flex justify-center mb-12"
                    text="Save"
                    icon=""
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ChangePassword;
