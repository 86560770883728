import React, { useEffect, useState } from "react";
import { OUTREACH_STATUS_OPTIONS } from "../../../constant/data";
import { OutReachInterface } from "../../../interfaces";
import toast from "react-hot-toast";
import { HTTP_METHODS, HTTP_STATUS_CODES } from "../../../constant";
import { REQ } from "../../../constant/api-endpoints";
import { fetchRequest } from "../../../utils/axios";
import { useUserAuth } from "../../../hooks/auth.hook";

const StatusDropdown: React.FC<{
  rowData: OutReachInterface;
  setBgColorClass: (colorClass: string) => void;
}> = ({ rowData, setBgColorClass }) => {
  const { user } = useUserAuth();

  const [status, setStatus] = useState(rowData.status || "");
  const [loading, setLoading] = useState(false);
  const [selectedColorClass, setSelectedColorClass] =
    useState("bg-color-default");

  useEffect(() => {
    const colorClass = rowData.status
      ? `bg-color-${rowData.status}`
      : "bg-color-default";
    setBgColorClass(rowData.status ? colorClass : "");
    setSelectedColorClass(colorClass);
  }, []);

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLoading(true);
    const selectedValue = e.target.value;
    setStatus(selectedValue);
    const colorClass = `bg-color-${e.target.value}`;
    setBgColorClass(colorClass);
    setSelectedColorClass(colorClass);

    const payload = {
      ...rowData,
      investor: rowData.investor ? rowData.investor._id : null,
      influencer: rowData.influencer ? rowData.influencer._id : null,
      fund: rowData.fund ? rowData.fund._id : null,
      user: user.id,
      status: e.target.value,
    };

    onUpdateStatus(rowData._id, payload as OutReachInterface);
    setLoading(false);
  };

  const onUpdateStatus = async (id: string, payload: OutReachInterface) => {
    try {
      const response = await fetchRequest({
        url: REQ.OUT_REACH.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Status updated successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="w-56 relative !border-[1.5px] !border-solid !border-gray-500 rounded-md">
      <select
        className={`reactSelectBox text-sm w-full text-white p-2 pr-10 rounded ${selectedColorClass}`}
        value={status}
        onChange={onStatusChange}
        disabled={loading}
      >
        <option value="" disabled>
          Select Status
        </option>
        {OUTREACH_STATUS_OPTIONS.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span className="custom-arrow"></span>
    </div>
  );
};

export default StatusDropdown;
