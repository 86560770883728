import axios from "axios";
import { HTTP_STATUS_CODES } from "../constant";
import { REQ } from "../constant/api-endpoints";
import { BASE_URL } from "./axios";
import { getToken } from "./get-token";

export const UploadMedia = async (file: File) => {
  const token = getToken();
  const data = new FormData();
  data.append("file", file);

  const url = BASE_URL + REQ.UPLOAD_FILE;

  const response = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (
    response &&
    response.data &&
    response.data.status === HTTP_STATUS_CODES.OK
  ) {
    return response.data.data.url;
  }
};
