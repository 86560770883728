import React from "react";
import ArrowDiv from "../ArrowDiv";

const GeneratedByRaiderly: React.FC<{ image: string }> = ({ image }) => {
  return (
    <>
      <div className="relative w-full">
        <img alt="_raiderly" src={image} className="w-full object-cover" />

        <img
          alt="_sitMan"
          className="absolute -top-20 md:-top-28 lg:-top-40 inset-x-1/4 translate-x-3 md:translate-x-6 lg:-translate-x-6 xl:translate-x-0 -translate-y-1 h-40 w-40 md:h-56 md:w-56 lg:h-72 lg:w-72 sittingMan"
          src="/images/Home/sittingMan.svg"
        />
      </div>
      <ArrowDiv
        className="w-full mb-6"
        text="Our trained AI uses public data to craft hyper-specific messages"
      />
    </>
  );
};

export default GeneratedByRaiderly;
