import React from "react";
import Button from "../common/Button";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../../interfaces";
import { ExportCSVFile } from "../../utils/functions";

const ExportCSVButton: React.FC<{
  name: string;
  data: InvestorInterface[] | InfluencerInterface[] | FundInterface[];
  type?: string;
  role?: string;
}> = ({ type, name, role, data }) => {
  const getField = (item: any, field: string) => {
    return type === "outreach" && role
      ? item[role][field] || ""
      : item[field] || "";
  };

  const onExportCSV = () => {
    const payload =
      data.length > 0
        ? data.map((item: any) => ({
            ["Organization Person Name"]: getField(item, "name"),
            ["Title"]: getField(item, "jobTitle"),
            ["Email"]: getField(item, "email"),
            ["Linkedin"]: getField(item, "linkedin"),
            ["Twitter"]: getField(item, "twitter"),
            ["Facebook"]: getField(item, "facebook"),
            ["Crunchbase"]: getField(item, "crunchbase"),
            ["Website"]: getField(item, "website"),
            ["City"]: getField(item, "city"),
            ["State"]: getField(item, "state"),
            ["Country"]: getField(item, "country"),
            ["Organization"]:
              type === "outreach" && role
                ? item[role].organization?.name || ""
                : item.organization?.name || "",
          }))
        : [];

    ExportCSVFile(name, payload);
  };

  return (
    <Button
      padding="py-2 2xl:py-3 px-3 lg:px-4 2xl:px-5"
      className="bg-mainColor text-white hidden md:block text-sm 2xl:text-2xl font-medium border border-white hover:bg-opacity-70 transition-all duration-300 rounded-rounded63"
      icon=""
      text="Export CSV"
      onClick={onExportCSV}
    />
  );
};

export default ExportCSVButton;
