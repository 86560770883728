import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../hooks/auth.hook";
import { ROUTES_CONSTANT } from "../../constant";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import { getToken } from "../../utils/get-token";

const PrivateRoute = () => {
  const { isAuthenticated, isLoading, refetchUser } = useUserAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = getToken();

  useEffect(() => {
    (async () => {
      token && !isAuthenticated && (await refetchUser());
    })();
  }, [pathname]);

  useEffect(() => {
    if (!isLoading && !token && !isAuthenticated) {
      navigate(ROUTES_CONSTANT.SIGN_IN);
    }
  }, [isAuthenticated, isLoading]);

  return <>{isAuthenticated && token ? <Outlet /> : <ScreenLoader />}</>;
};

export default PrivateRoute;
