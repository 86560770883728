import React from "react";

const TotalCount: React.FC<{ count: string | number }> = ({ count }) => {
  return (
    <div className="h-7 w-7 rounded-full px-3 bg-white flex items-center justify-center text-sm text-black font-medium">
      <p>{count}</p>
    </div>
  );
};

export default TotalCount;
