import React, { useRef } from "react";
import SmallLoader from "../../Loaders/SmallLoader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useProps } from "../../../contexts/PropsContext";
import { fetchRequest } from "../../../utils/axios";
import { REQ } from "../../../constant/api-endpoints";
import { HTTP_METHODS, HTTP_STATUS_CODES, ROLE_TYPE } from "../../../constant";
import toast from "react-hot-toast";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../../../interfaces";
import {
  CapitalizeFirstLetter,
  GroupDataByRoleType,
} from "../../../utils/functions";

interface propTypes {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  open: boolean;
  data: InfluencerInterface[] | InvestorInterface[] | FundInterface[];
}
const ActionDropDown = ({ anchorEl, setAnchorEl, open, data }: propTypes) => {
  const { savedFilterNames, refetchSavedFilterNames } = useProps();

  const handleClose = ({}) => {
    setAnchorEl(null);
  };

  const onAddToList = async (filterId: string) => {
    for (const item of data) {
      const payload = {
        type: item.type,
        id: item.id,
      };

      await onUpdateList(filterId, payload).then(() => {
        refetchSavedFilterNames();
      });
    }
  };

  const onUpdateList = async (filterId: string, payload: any) => {
    try {
      const response = await fetchRequest({
        url: REQ.SAVED_FILTER.ADD_FROM_SAVE_LIST_BY_ID.replace(":id", filterId),
        type: HTTP_METHODS.POST,
        body: payload,
      });
      if (response && response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Added Successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const ShowSavedList = () => {
    const groupedData = GroupDataByRoleType(savedFilterNames);

    if (!groupedData) {
      return (
        <MenuItem
          sx={{ fontSize: "12px", textTransform: "capitalize" }}
          onClick={handleClose}
        >
          No saved filters yet
        </MenuItem>
      );
    }

    const renderRoleItems = (role: string, label: string) => (
      <>
        <p className={`text-[16px] font-semibold mx-4 my-2`}>{label}s:</p>
        {groupedData[role] &&
          groupedData[role].map((item: any, index: number) => (
            <MenuItem
              key={index}
              sx={{
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              onClick={() => onAddToList(item.id)}
            >
              <div className="flex gap-3 text-sm capitalize">
                <img className="w-5 h-5" src={item.emoji} alt="" />
                {item.name}
              </div>
            </MenuItem>
          ))}
      </>
    );

    return (
      <div>
        {groupedData.investor &&
          renderRoleItems(
            ROLE_TYPE.INVESTOR,
            CapitalizeFirstLetter(ROLE_TYPE.INVESTOR)
          )}
        {groupedData.influencer &&
          renderRoleItems(
            ROLE_TYPE.INFLUENCER,
            CapitalizeFirstLetter(ROLE_TYPE.INFLUENCER)
          )}
        {groupedData.fund &&
          renderRoleItems(
            ROLE_TYPE.FUND,
            CapitalizeFirstLetter(ROLE_TYPE.FUND)
          )}
      </div>
    );
  };

  return (
    <Menu
      id="basic-menu"
      disableScrollLock={true}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ borderRadius: "52px", marginTop: "10px" }}
    >
      {savedFilterNames && ShowSavedList()}
    </Menu>
  );
};

export default ActionDropDown;
