import React from "react";
import Select, { components } from "react-select";
import DownArrow from "../../svg/DownArrow";
import { customStyles } from "./customStyling";
import Check from "../../svg/SelectBoxCheck";
import { ReactSelectInterface } from "../../../interfaces";
import { useFilterQuery } from "../../../hooks/filterQuery.hook";
import { useProps } from "../../../contexts/PropsContext";

interface SelectBoxProps {
  placeHolder: string;
  menuOpen?: boolean;
  options?: ReactSelectInterface[];
  selectedOptions: ReactSelectInterface[];
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<ReactSelectInterface[]>
  >;
  label: string;
  disabled: boolean;
}

interface PlaceholderWithBadgeProps {
  placeholder: string;
  badgeContent: React.ReactNode;
}

const PlaceholderWithBadge: React.FC<PlaceholderWithBadgeProps> = ({
  placeholder,
  badgeContent,
}) => (
  <div className="flex items-center gap-1">
    {badgeContent}
    <div>{placeholder}</div>
  </div>
);

const SelectBox: React.FC<SelectBoxProps> = ({
  placeHolder,
  menuOpen,
  options,
  label,
  disabled,
  selectedOptions,
  setSelectedOptions,
}) => {
  const { setSearchText } = useProps();
  const { query, addQuery, removeQuery } = useFilterQuery();

  const handleChange = (selectedOption: any) => {
    setSearchText("");
    removeQuery("text");

    const updatedOptions = [...selectedOptions];
    const optionIndex = updatedOptions.findIndex(
      (option) => option.value === selectedOption.value
    );

    if (optionIndex === -1) {
      updatedOptions.push(selectedOption);
    } else {
      updatedOptions.splice(optionIndex, 1);
    }

    setSelectedOptions(updatedOptions);
    addQuery({
      ...query,
      [label.toLowerCase()]: updatedOptions.map(
        (item: ReactSelectInterface) => item.value
      ) as string[],
    });
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      isSearchable={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      isDisabled={disabled}
      placeholder={
        <PlaceholderWithBadge
          placeholder={placeHolder}
          badgeContent={
            <>
              {selectedOptions.length > 0 && (
                <div className="h-4 w-4 rounded-full bg-mainColor flex items-center justify-center text-xs text-white font-medium">
                  <p>{selectedOptions.length}</p>
                </div>
              )}
            </>
          }
        />
      }
      menuIsOpen={menuOpen}
      value={selectedOptions}
      onChange={handleChange}
      components={{
        DropdownIndicator: (props) => {
          return (
            <components.DropdownIndicator {...props}>
              <DownArrow className="fill-mainTextColor h-5 w-5" />
            </components.DropdownIndicator>
          );
        },
        Option: ({ label, innerProps, isFocused }) => {
          const isSelected = selectedOptions.some(
            (option: { label: string }) =>
              option.label.toLowerCase() === label.toLowerCase()
          );

          const customOptionStyles = {
            padding: "8px",
            paddingLeft: "12px",
            fontWeight: "600",
            color: "black",
            cursor: "pointer",
            backgroundColor: isFocused ? "#00000010" : "white",
          };

          return (
            <div
              {...innerProps}
              style={{ ...customOptionStyles, ...innerProps.style }}
            >
              <div className="flex justify-between items-center text-[10px] md:text-xs">
                {label}
                {isSelected ? (
                  <span>
                    <Check />
                  </span>
                ) : null}
              </div>
            </div>
          );
        },
      }}
    />
  );
};

export default SelectBox;
