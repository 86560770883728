import React from "react";

interface PropsTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeHolder: string;
  className?: string;
  type?: string;
  inputClassName?: string;
  error?: any;
}

const Input = React.forwardRef(
  (
    {
      label,
      placeHolder,
      className,
      type,
      inputClassName,
      error,
      ...props
    }: PropsTypes,
    ref: any
  ) => {
    return (
      <div className="">
        <p
          className={`${className} text-lg 2xl:text-2xl text-mainTextColor font-normal max-sm:text-sm  max-sm:tracking-tight max-sm:font-medium`}
        >
          {label}
        </p>
        <input
          {...props}
          ref={ref}
          type={type}
          placeholder={placeHolder}
          className={`${inputClassName} w-full p-3 pl-5 2xl:p-4 h-14  text-xs 2xl:text-xl border focus:border-mainColor outline-none transition-all duration-300 max-md:h-11 2xl:h-20`}
        />
        {error && (
          <p className="mt-5 text-sm text-red-600">{error}</p>
        )}
      </div>
    );
  }
);

export default Input;
