import React, { useState } from "react";
import Cross from "../components/svg/Cross";
import { useUi } from "../hooks/user-interface";
import InfoModalInput from "../components/StartUpInfoModal/InfoModalInput";
import CheckBox from "../components/common/CheckBox.tsx/CheckBox";
import SelectBox from "../components/ReachOutModal/SelectBox";
import Button from "../components/common/Button";
import {
  PURPOSE_OPTIONS,
  TONE_OPTIONS,
  URGENCY_LEVEL_OPTIONS,
} from "../constant/data";
import { ReachOutSchema } from "../validation-schema";
import { useForm } from "react-hook-form";
import { UploadImage } from "../utils/functions";
import toast from "react-hot-toast";
import { HTTP_METHODS, HTTP_STATUS_CODES } from "../constant";
import { fetchRequest } from "../utils/axios";
import { REQ } from "../constant/api-endpoints";
import { useUserAuth } from "../hooks/auth.hook";
import { modalType } from "../store/slices/ui.slice";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../interfaces";

interface ModalStateInterface {
  type: string;
  investor: InvestorInterface;
  influencer: InfluencerInterface;
  fund: FundInterface;
}

const ReachOutModal: React.FC = () => {
  const { hideModal, modalState, updateModal } = useUi();

  const state = modalState as ModalStateInterface;

  const { user } = useUserAuth();

  const {
    register,
    handleSubmit: fromSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: ReachOutSchema });

  const [previousInteraction, setPreviousInteraction] =
    useState<boolean>(false);

  const [linkInputs, setLinkInputs] = useState<string[]>([]);
  const [attachmentFile, setAttachmentsFile] = useState<string[]>([]);

  const onAddAttachments = () => {
    setAttachmentsFile((prevFiles: any) => [...prevFiles, null]);
  };

  const onAddLinks = () => {
    setLinkInputs([...linkInputs, ""]);
  };

  const handleAttachmentChange = (index: number, file: File | null) => {
    const updatedFiles: string[] = [...attachmentFile];
    UploadImage(file, (res) => {
      updatedFiles[index] = res;
    });
    setAttachmentsFile(updatedFiles);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedInputs = [...linkInputs];
    updatedInputs[index] = value;
    setLinkInputs(updatedInputs);
  };

  const onSubmit = (values: any) => {
    const payload = {
      type: state.type,
      investor: state.investor ? state.investor.id : null,
      influencer: state.influencer ? state.influencer.id : null,
      fund: state.fund ? state.fund.id : null,
      purpose: values.purpose,
      tone: values.tone,
      highlightPoint: values.highlightPoint,
      previousInteraction: values.previousInteraction,
      urgencyLevel: values.urgencyLevel,
      companyName: values.companyName,
      companyWebsite: values.companyWebsite,
      // attachments: attachmentFile,
      callToAction: values.callToAction,
      user: user.id,
    };

    onAddReachOut(payload);

    hideModal();

    updateModal({
      type: modalType.generate_email,
      state: {
        data: {
          ...payload,
          investor: state.investor || null,
          influencer: state.influencer || null,
          fund: state.fund || null,
        },
      },
    });
  };

  const onAddReachOut = async (payload: any) => {
    try {
      const response = await fetchRequest({
        url: REQ.OUT_REACH.CREATE,
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Reach out added successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="bg-white rounded-3xl px-4 md:px-12 xl:px-12 py-5 pb-8 w-11/12 md:w-4/5 xl:w-1/2 relative m-auto">
      <div onClick={hideModal}>
        <Cross className="h-8 w-8 md:h-10 md:w-10 absolute top-4 left-3 md:left-7" />
      </div>
      <div className="flex flex-col items-center gap-9 pt-8">
        <h1 className="font-semibold text-2xl">Reach Out</h1>
        <form
          className="w-full flex flex-col gap-7"
          onSubmit={fromSubmit(onSubmit)}
        >
          <div className="w-full flex flex-col gap-7">
            <SelectBox
              {...register("purpose", { required: true })}
              label="Purpose"
              placeHolder="Purpose"
              options={[
                {
                  label: "Select Purpose",
                  value: "",
                  isDisabled: true,
                },
                ...PURPOSE_OPTIONS,
              ]}
              onChange={(e: any) => setValue("purpose", e.value)}
              error={errors.purpose?.message}
            />
            <SelectBox
              {...register("tone", { required: true })}
              label="Tone"
              placeHolder="Tone"
              options={[
                {
                  label: "Select Tone",
                  value: "",
                  isDisabled: true,
                },
                ...TONE_OPTIONS,
              ]}
              onChange={(e: any) => setValue("tone", e.value)}
              error={errors.tone?.message}
            />
            <InfoModalInput
              {...register("highlightPoint", { required: true })}
              label="Highlight Point"
              placeHolder="Highlight Point"
              error={errors.highlightPoint?.message || ""}
            />
            <CheckBox
              {...register("previousInteraction", { required: false })}
              label="Previous Interaction"
              checked={previousInteraction}
              onChange={(e) => {
                setValue("previousInteraction", e.target.checked);
                setPreviousInteraction(e.target.checked);
              }}
              error={errors.previousInteraction?.message || ""}
            />
            <SelectBox
              {...register("urgencyLevel", { required: true })}
              label="Urgency Level"
              placeHolder="Urgency Level"
              options={[
                {
                  label: "Select Urgent Level",
                  value: "",
                  isDisabled: true,
                },
                ...URGENCY_LEVEL_OPTIONS,
              ]}
              onChange={(e: any) => setValue("urgencyLevel", e.value)}
              error={errors.urgencyLevel?.message || ""}
            />
            <InfoModalInput
              {...register("companyName", { required: true })}
              label="Company Name"
              placeHolder="Company Name"
              error={errors.companyName?.message || ""}
            />
            {/* <div>
              <p className="mb-2 font-medium text-sm md:text-base text-mainTextColor 2xl:text-lg flex items-center justify-between w-full">
                Attachment
                <IoAddCircleOutline
                  onClick={() => onAddAttachments()}
                  className="w-6 h-6 cursor-pointer"
                />
              </p>
              {attachmentFile.map((item, index: any) => (
                <InputFile
                  key={`attachmentInputFile-${index}`}
                  placeHolder={item || `Attachment ${index + 1}`}
                  label=""
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleAttachmentChange(
                      index,
                      e.target.files ? e.target.files[0] : null
                    )
                  }
                />
              ))}
            </div> */}
            {/* <div>
              <p className="mb-2 font-medium text-sm md:text-base text-mainTextColor 2xl:text-lg flex items-center justify-between w-full">
                Link
                <IoAddCircleOutline
                  onClick={() => onAddLinks()}
                  className="w-6 h-6 cursor-pointer"
                />
              </p>
              {linkInputs.map((link, index: any) => (
                <input
                  key={"Link" + index}
                  type="text"
                  placeholder={`Links ${index + 1}`}
                  value={link}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  className={`outline-none border w-full p-3 rounded-md text-sm 2xl:text-lg py-4 bg-gray-50 focus:border-mainColor transition-all duration-300 mb-3`}
                />
              ))}
            </div> */}
            <InfoModalInput
              {...register("companyWebsite", { required: true })}
              label="Company Website Link"
              placeHolder="Company Website Link"
              error={errors.companyWebsite?.message || ""}
            />
            <InfoModalInput
              {...register("callToAction", { required: true })}
              label="Call To Action"
              placeHolder="Call To Action"
              error={errors.callToAction?.message || ""}
            />
          </div>
          <Button
            padding="py-3"
            className="w-full rounded-xl bg-mainColor text-white text-lg flex justify-center cursor-pointer hover:bg-opacity-70"
            text="Reach Out"
            icon=""
          />
        </form>
      </div>
    </div>
  );
};

export default ReachOutModal;
