import React from "react";
import RequirementBox from "../RequirementBox";
import Button from "../../common/Button";
import {
  HTTP_METHODS,
  HTTP_STATUS_CODES,
  PRICING_PLAN,
  ROUTES_CONSTANT,
  STRIPE_MONTHLY_PRICE_ID,
  STRIPE_YEARLY_PRICE_ID,
} from "../../../constant";
import { NavLink, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../hooks/auth.hook";
import SmallLoader from "../../Loaders/SmallLoader";
import { fetchRequest } from "../../../utils/axios";
import { REQ } from "../../../constant/api-endpoints";
import toast from "react-hot-toast";

interface Requirement {
  text: string;
  checkBg: string;
  stroke: string;
}

interface propsTypes {
  subscriptionsImg: string;
  levels: string;
  levelsTextColor: string;
  price: string;
  planDuration: string;
  pricingPlan: string;
  priceTextColor: string;
  priceTextSpanColor: string;
  subscriptionsDivBackground: string;
  diamondBackground: string;
  buttonStyling: string;
  requirementTextColor: string;
  requirements: Requirement[];
}

const SubscriptionCard = ({
  subscriptionsImg,
  levels,
  levelsTextColor,
  price,
  planDuration,
  pricingPlan,
  priceTextColor,
  subscriptionsDivBackground,
  diamondBackground,
  buttonStyling,
  requirementTextColor,
  requirements,
  priceTextSpanColor,
}: propsTypes) => {
  const navigate = useNavigate();

  const { user, refetchUser } = useUserAuth();

  const [loading, setLoading] = React.useState<boolean>(false);

  const onCreateSubscription = async () => {
    const payload: { plan: string; email: string } = {
      plan:
        planDuration === PRICING_PLAN.MONTHLY
          ? STRIPE_MONTHLY_PRICE_ID
          : STRIPE_YEARLY_PRICE_ID,
      email: user.email,
    };

    setLoading(true);

    const response = await fetchRequest({
      url: REQ.SUBSCRIPTION.CREATE_SUBSCRIPTION,
      type: HTTP_METHODS.POST,
      body: payload,
    });

    if (response && response.status === HTTP_STATUS_CODES.OK) {
      window.location.href = response.data.url;
    }
  };

  const onCreateBillingPortalSession = async () => {
    try {
      setLoading(true);

      const payload = {
        sessionId: user.subscription.stripeSessionId,
        returnUrl: window.location.href,
      };

      const response = await fetchRequest({
        url: REQ.SUBSCRIPTION.MANAGE_BILLING_PORTAL,
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response && response.status === HTTP_STATUS_CODES.OK) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const isCurrentPlanPurchased = () => {
    if (user.subscription) {
      const currentDate = new Date();
      const expiryDate = new Date(user.subscription.expiredAt);

      return (
        user.subscription.plan + "ly" === planDuration &&
        user.subscription.subscriptionStatus === "active" &&
        currentDate < expiryDate
      );
    }
  };

  const purchased = isCurrentPlanPurchased();

  const RenderActionButton = ({
    isDisabled,
    text,
    onClick,
  }: {
    isDisabled: boolean;
    text: string;
    onClick: any;
  }) => {
    return (
      <div className="w-full flex justify-center px-5">
        <Button
          padding="py-2 lg:py-2 px-12"
          className={`${buttonStyling} ${
            isDisabled
              ? "disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-100"
              : ""
          } text-base lg:text-lg font-medium w-full lg:w-auto flex justify-center rounded-rounded63`}
          text={text}
          icon=""
          onClick={onClick}
          disabled={isDisabled}
        />
      </div>
    );
  };

  const RenderActionState = () => {
    if (loading) {
      return (
        <div className="w-full flex justify-center items-center">
          <SmallLoader color="#ffffff" />
        </div>
      );
    } else if (!user) {
      return (
        <RenderActionButton
          isDisabled={false}
          text="Sign Up / Log In"
          onClick={() => navigate(ROUTES_CONSTANT.SIGN_UP)}
        />
      );
    } else if (planDuration === PRICING_PLAN.FREE || purchased) {
      const buttonText =
        planDuration === PRICING_PLAN.FREE
          ? "Already Purchased"
          : "Manage Subscription";
      const isDisabled = planDuration === PRICING_PLAN.FREE;
      const onClickHandler = isDisabled
        ? undefined
        : onCreateBillingPortalSession;

      return (
        <RenderActionButton
          isDisabled={isDisabled}
          text={buttonText}
          onClick={onClickHandler}
        />
      );
    } else {
      return (
        <RenderActionButton
          isDisabled={false}
          text={"Upgrade"}
          onClick={onCreateSubscription}
        />
      );
    }
  };

  return (
    <div
      className={`w-full rounded-3xl relative overflow-hidden pb-16 lg:pb-5 ${subscriptionsDivBackground} flex flex-col justify-between shadow-customShadow`}
    >
      <div
        className={`absolute right-0 -translate-y-5 translate-x-5 h-32 w-32 md:h-36 md:w-36 rounded-full flex items-center justify-center ${diamondBackground}`}
      >
        <img className="w-3/6" src={subscriptionsImg} alt="Subscription" />
      </div>
      <div className="pl-5 xl:pl-8 pt-16 mb-12 lg:mb-8">
        <h1 className={`font-bold text-3xl mb-9 ${levelsTextColor}`}>
          {levels}
        </h1>
        <div className="flex flex-col gap-7">
          <h2 className={`text-4xl capitalize font-bold ${priceTextColor}`}>
            {price}{" "}
            <span className={`text-xl font-medium ${priceTextSpanColor}`}>
              /{pricingPlan}
            </span>
          </h2>
          <div className="flex flex-col gap-3">
            {requirements.map((requirement, index) => (
              <RequirementBox
                key={index}
                textColor={requirementTextColor}
                text={requirement.text}
                checkBg={requirement.checkBg}
                stroke={requirement.stroke}
              />
            ))}
          </div>
        </div>
      </div>

      <RenderActionState />
    </div>
  );
};

export default React.memo(SubscriptionCard);
