import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum modalType {
  "none",
  "filter_modal",
  "forgot_password",
  "search_modal",
  "save_modal",
  "start_up_modal",
  "reachout_modal",
  "choose_subscription_modal",
  "generate_email",
  "message_all",
}

interface UiState {
  modal: modalType | false;
  modalState: any;
}

const initialState: UiState = {
  modal: false,
  modalState: null,
};

export const uiSlice = createSlice({
  name: "userInterface",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modal = false;
      state.modalState = null;
    },
    updateModalAndState: (
      state,
      action: PayloadAction<{ type: modalType; state: unknown }>
    ) => {
      state.modal = action.payload.type;
      state.modalState = action.payload.state;
    },
  },
});

export const { closeModal, updateModalAndState } = uiSlice.actions;
export default uiSlice.reducer;
