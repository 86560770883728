import React from "react";
import AllInvestorsTable from "../../components/AllInvestors/AllInvestorsTable";

const AllInvestors = () => {
  return (
    <section
      id="allInvestors"
      className="w-full bg-white px-3 py-8 rounded-2xl"
    >
      <AllInvestorsTable />
    </section>
  );
};

export default AllInvestors;
