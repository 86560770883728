import React from "react";
import { ROUTES_CONSTANT } from "../../../constant";
import { NavLink } from "react-router-dom";
import { useUi } from "../../../hooks/user-interface";
import { modalType } from "../../../store/slices/ui.slice";
import { useUserAuth } from "../../../hooks/auth.hook";

interface propTypes {
  className: string;
}

const ProfileDropDown = ({ className }: propTypes) => {
  const { updateModal } = useUi();
  const { user, logoutUser } = useUserAuth();

  return (
    <div
      className={`w-48 absolute right-0 top-12 2xl:top-20 shadow-lg py-2 bg-white rounded-xl translate-y-2 ${className} transition-all duration-300 z-10`}
    >
      <div className="flex flex-col gap-y-2">
        {user.status === "active" && (
          <>
            <p
              onClick={() =>
                updateModal({
                  type: modalType.start_up_modal,
                  state: {},
                })
              }
              className="w-full transition-all duration-300 hover:bg-black hover:bg-opacity-10 pl-4 cursor-pointer py-2 text-sm 2xl:text-2xl font-medium"
            >
              Startup Info
            </p>
            <NavLink
              to={ROUTES_CONSTANT.PLANS}
              className="w-full transition-all duration-300 hover:bg-black hover:bg-opacity-10 pl-4 cursor-pointer py-2 text-sm 2xl:text-2xl font-medium"
            >
              Plans
            </NavLink>
            <NavLink
              to={ROUTES_CONSTANT.CHANGE_PASSWORD}
              className="w-full transition-all duration-300 hover:bg-black hover:bg-opacity-10 pl-4 cursor-pointer py-2 text-sm 2xl:text-2xl font-medium"
            >
              Change Password
            </NavLink>
          </>
        )}
        <p
          className="w-full transition-all duration-300 hover:bg-black hover:bg-opacity-10 pl-4 cursor-pointer py-2 text-sm 2xl:text-2xl font-medium"
          onClick={logoutUser}
        >
          Sign Out
        </p>
      </div>
    </div>
  );
};

export default ProfileDropDown;
