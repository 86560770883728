import React from "react";

interface propTypes {
  className?: string;
}

const WhiteCross = ({ className }: propTypes) => {
  return (
    <svg
      className={`h-8 w-8 md:h-10 md:w-10 2xl:h-16 2xl:w-16 ${className}`}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 38.5C30.665 38.5 38.5 30.665 38.5 21C38.5 11.335 30.665 3.5 21 3.5C11.335 3.5 3.5 11.335 3.5 21C3.5 30.665 11.335 38.5 21 38.5Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M25.375 16.625L16.625 25.375M16.625 16.625L25.375 25.375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default WhiteCross;
