import React from "react";
import Button from "../common/Button";

interface propTypes {
  text: string;
}

const Tag = ({ text }: propTypes) => {
  return (
    <div>
      <Button
        padding="py-1 px-2"
        className="border border-mainTextColor capitalize rounded-[26px] font-semibold text-base"
        text={text}
        icon=""
      />
    </div>
  );
};

export default Tag;
