import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export const SignInSchema = yupResolver(
  Yup.object().shape({
    email: Yup.string().required("Field is Required *").email(),
    password: Yup.string()
      .required("Field is Required *")
      .min(8, "Min 8 Characters !"),
  })
);

export const SignUpSchema = yupResolver(
  Yup.object().shape({
    email: Yup.string().required("Field is Required *").email(),
    username: Yup.string().required("Field is Required *"),
    phone_number: Yup.string().required("Field is Required *"),
    password: Yup.string()
      .required("Field is Required *")
      .min(8, "Min 8 Characters !"),
  })
);

export const ForgotPasswordSchema = yupResolver(
  Yup.object().shape({
    email: Yup.string().required("Field is Required *").email(),
  })
);

export const ContactUsSchema = yupResolver(
  Yup.object().shape({
    name: Yup.string().required("Field is Required *"),
    email: Yup.string().required("Field is Required *").email(),
    subject: Yup.string().required("Field is Required *"),
    message: Yup.string().required("Field is Required *"),
  })
);

export const SaveFilterSchema = yupResolver(
  Yup.object().shape({
    name: Yup.string().required("Field is Required *"),
    type: Yup.string().required("Field is Required *"),
  })
);

export const StartupInfoSchema = yupResolver(
  Yup.object().shape({
    username: Yup.string().required("Field is Required *"),
    jobTitle: Yup.string().notRequired(),
    email: Yup.string().required("Field is Required *").email(),
    website: Yup.string().notRequired(),
    startUpName: Yup.string().notRequired(),
    description: Yup.string().notRequired(),
    pitchDeckLink: Yup.string().notRequired(),
    location: Yup.string().notRequired(),
    mainIndustry: Yup.string().notRequired(),
    additionalIndustry1: Yup.string().notRequired(),
    additionalIndustry2: Yup.string().notRequired(),
    companyStage: Yup.string().notRequired(),
    fundingRoundSize: Yup.string().notRequired(),
  })
);

export const ReachOutSchema = yupResolver(
  Yup.object().shape({
    purpose: Yup.string().required("Field is Required *"),
    tone: Yup.string().required("Field is Required *"),
    highlightPoint: Yup.string().required("Field is Required *"),
    previousInteraction: Yup.boolean().notRequired(),
    urgencyLevel: Yup.string().required("Field is Required *"),
    companyName: Yup.string().required("Field is Required *"),
    // attachments: Yup.array().of(Yup.string()).notRequired(),
    companyWebsite: Yup.string().required("Field is Required *"),
    callToAction: Yup.string().required("Field is Required *"),
  })
);

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Field is Required *")
    .min(8, "Password must contain min 8 Characters"),
  confirmPassword: Yup.string()
    .required("Field is Required *")
    .min(8, "Password must contain min 8 Characters")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export const OutReachSchema = yupResolver(
  Yup.object().shape({
    status: Yup.string().notRequired(),
  })
);
