import React, { useEffect, useState } from "react";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { useProps } from "../../contexts/PropsContext";
import SmallLoader from "../Loaders/SmallLoader";
import { OutReachInterface } from "../../interfaces";
import { PAGE_TITLE, ROLE_TYPE, ROUTES_CONSTANT } from "../../constant";
import { useNavigate } from "react-router-dom";
import VerifiedCheck from "../VerifiedCheck";
import Badges from "./Badges";
import Contacts from "./Contacts";
import { REQ } from "../../constant/api-endpoints";
import { useUserAuth } from "../../hooks/auth.hook";
import { useFilterQuery } from "../../hooks/filterQuery.hook";
import StatusDropdown from "./StatusDropdown";
import Button from "../common/Button";
import ExportCSVButton from "../ExportCSVButton";
import { CapitalizeFirstLetter } from "../../utils/functions";

const OutReachTable: React.FC<{ role: string }> = ({ role }) => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const { query, clearAllQuery } = useFilterQuery();

  const {
    searchText,
    outReachData,
    outReachDataLoading,
    outReachPage,
    outReachLimit,
    outReachPostMutation,
    setOutReachPage,
    setOutReachLimit,
    setHeaderData,
    setSearchText,
  } = useProps();

  const [rowBgColors, setRowBgColors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    clearAllQuery();

    return () => {
      setSearchText("");
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (role) {
        await outReachPostMutation({
          url: `${REQ.OUT_REACH.GET_FILTERS_PAGINATED_BY_USER_ID.replace(
            ":userId",
            user.id
          )}?page=${outReachPage}&limit=${outReachLimit}`,
          body: query["text"]
            ? { type: "search", role, text: searchText }
            : { role },
        });
      }
    })();
  }, [query, role, outReachPage]);

  useEffect(() => {
    if (outReachData) {
      setHeaderData({
        pageTitle: PAGE_TITLE.OUTREACH,
        dataCount: outReachData.count,
      });
    }

    return () => {
      setHeaderData({
        pageTitle: "",
        pageIcon: "",
        dataCount: 0,
      });
    };
  }, [outReachData]);

  const onPaginate = (event: DataTablePageEvent) => {
    setOutReachPage(event.first / event.rows + 1);
    setOutReachLimit(event.rows);
  };

  const onNavigateDetailPage = (type: string, data: OutReachInterface) => {
    if (type === ROLE_TYPE.INFLUENCER) {
      navigate(
        ROUTES_CONSTANT.INFLUENCER_DETAIL.replace(
          ":id",
          data.influencer?.id || ""
        )
      );
    }

    if (type === ROLE_TYPE.INVESTOR) {
      navigate(
        ROUTES_CONSTANT.INVESTOR_DETAIL.replace(":id", data.investor?.id || "")
      );
    }

    if (type === ROLE_TYPE.FUND) {
      navigate(ROUTES_CONSTANT.FUND_DETAIL.replace(":id", data.fund?.id || ""));
    }
  };

  const setRowColorClass = (rowId: string, colorClass: string) => {
    setRowBgColors((prevColors) => ({ ...prevColors, [rowId]: colorClass }));
  };

  return (
    <>
      {outReachDataLoading ? (
        <SmallLoader />
      ) : (
        outReachData && (
          <>
            <div className="flex items-center w-full justify-end gap-3 relative mb-6">
              <ExportCSVButton
                type={"outreach"}
                role={role}
                name={`Outreach-${CapitalizeFirstLetter(role)}s`}
                data={outReachData.data}
              />
            </div>
            <DataTable
              value={outReachData.data || []}
              lazy
              resizableColumns
              columnResizeMode="fit"
              className="w-full"
              paginator
              onPage={onPaginate}
              totalRecords={outReachData.count || 0}
              rows={outReachLimit}
              first={(outReachPage - 1) * outReachLimit || 0}
              loading={outReachDataLoading}
              pageLinkSize={outReachData.totalPage || 0}
              emptyMessage={"No Record Found"}
              rowClassName={(rowData: OutReachInterface) => {
                return rowBgColors[rowData._id] || "";
              }}
            >
              <Column
                header="Name"
                style={{ minWidth: "300px", maxWidth: "400px" }}
                headerClassName="py-5 bg-gray-200 font-medium rounded-tl-xl pl-3 text-base 2xl:text-2xl"
                body={(rowData: OutReachInterface) => (
                  <div
                    className="flex items-center cursor-pointer gap-x-3 py-5 2xl:py-16 border border-gray-50 pl-2 border-r-0 border-l-0 h-20"
                    onClick={() => onNavigateDetailPage(rowData.type, rowData)}
                  >
                    <img
                      className="w-12 h-12 rounded-full"
                      src={
                        rowData.type === ROLE_TYPE.INVESTOR
                          ? rowData.investor?.avatar
                          : rowData.type === ROLE_TYPE.INFLUENCER
                          ? rowData.influencer?.avatar
                          : rowData.fund?.avatar
                      }
                      alt={"influencer avatar"}
                    />
                    <div className="flex flex-col gap-1">
                      <h1 className="font-medium text-sm 2xl:text-2xl text-mainTextColor flex items-center gap-3">
                        {rowData.type === ROLE_TYPE.INVESTOR
                          ? rowData.investor?.name
                          : rowData.type === ROLE_TYPE.INFLUENCER
                          ? rowData.influencer?.name
                          : rowData.fund?.name}{" "}
                        {rowData.type === ROLE_TYPE.INVESTOR
                          ? rowData.investor?.isVerified && <VerifiedCheck />
                          : rowData.type === ROLE_TYPE.INFLUENCER
                          ? rowData.influencer?.isVerified && <VerifiedCheck />
                          : rowData.fund?.isVerified && <VerifiedCheck />}
                      </h1>
                      <p className="text-xs 2xl:text-lg text-mainTextColor font-medium">
                        {rowData.type === ROLE_TYPE.INVESTOR
                          ? rowData.investor?.jobTitle
                          : rowData.type === ROLE_TYPE.INFLUENCER
                          ? rowData.influencer?.jobTitle
                          : ""}
                      </p>
                    </div>
                  </div>
                )}
              />
              <Column
                header="Industries"
                style={{ minWidth: "220px" }}
                headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
                body={(rowData: OutReachInterface) =>
                  rowData.type === ROLE_TYPE.INVESTOR ? (
                    <Badges data={rowData.investor?.industries || []} />
                  ) : rowData.type === ROLE_TYPE.INFLUENCER ? (
                    <Badges data={rowData.influencer?.industries || []} />
                  ) : (
                    <Badges data={rowData.fund?.industries || []} />
                  )
                }
              />
              {/* <Column
              header="Geography"
              style={{ minWidth: "180px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(rowData: OutReachInterface) =>
                rowData.type === ROLE_TYPE.INVESTOR ? (
                  <Badges data={rowData.investor?.geography || []} />
                ) : rowData.type === ROLE_TYPE.INFLUENCER ? (
                  <Badges data={rowData.influencer?.geography || []} />
                ) : (
                  <Badges data={rowData.fund?.geography || []} />
                )
              }
            /> */}
              {/* <Column
              header="Stage"
              style={{ minWidth: "150px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(rowData: OutReachInterface) =>
                rowData.type === ROLE_TYPE.INVESTOR ? (
                  <Badges data={rowData.investor?.stages || []} />
                ) : rowData.type === ROLE_TYPE.INFLUENCER ? (
                  <Badges data={rowData.influencer?.stages || []} />
                ) : (
                  <Badges data={rowData.fund?.stages || []} />
                )
              }
            /> */}
              <Column
                header="Contacts"
                style={{ minWidth: "250px", overflow: "visible" }}
                headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
                body={(rowData: OutReachInterface) =>
                  rowData.type === ROLE_TYPE.INVESTOR ? (
                    <Contacts
                      website={rowData.investor?.website || ""}
                      email={rowData.investor?.email || ""}
                      twitter={rowData.investor?.twitter || ""}
                      linkedin={rowData.investor?.linkedin || ""}
                    />
                  ) : rowData.type === ROLE_TYPE.INFLUENCER ? (
                    <Contacts
                      website={rowData.influencer?.website || ""}
                      email={rowData.influencer?.email || ""}
                      twitter={rowData.influencer?.twitter || ""}
                      linkedin={rowData.influencer?.linkedin || ""}
                    />
                  ) : (
                    <Contacts
                      website={rowData.fund?.website || ""}
                      email={rowData.fund?.email || ""}
                      twitter={rowData.fund?.twitter || ""}
                      linkedin={rowData.fund?.linkedin || ""}
                    />
                  )
                }
              />
              <Column
                header="Actions"
                style={{ minWidth: "150px" }}
                headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
                body={(rowData: OutReachInterface) => (
                  <StatusDropdown
                    rowData={rowData}
                    setBgColorClass={(color) =>
                      setRowColorClass(rowData._id, color)
                    }
                  />
                )}
              />
            </DataTable>
          </>
        )
      )}
    </>
  );
};

export default OutReachTable;
