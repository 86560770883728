import React from 'react'

interface propTypes {
  className: string;
}

const Twitter = ({className} : propTypes) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9583 7.26155C19.7991 7.41286 19.7142 7.62639 19.726 7.84574C19.7348 8.00702 19.7349 8.17171 19.7349 8.34951C19.7326 9.41587 19.577 10.4764 19.2731 11.4985L19.2683 11.5153C18.6276 13.8697 17.221 15.9436 15.2705 17.4095C13.32 18.8754 10.9367 19.6498 8.49706 19.6104L8.49706 19.6102L8.48411 19.6103C7.2949 19.6116 6.1195 19.4227 5.00082 19.0563C6.35004 18.7608 7.6241 18.1634 8.72281 17.2986C8.97193 17.1025 9.07085 16.771 8.96991 16.4705C8.86898 16.1699 8.58998 15.9653 8.27301 15.9594C7.55043 15.9458 6.85008 15.7072 6.26956 15.2768C6.00514 15.0807 5.77158 14.8493 5.57457 14.5904C5.86642 14.5631 6.15548 14.509 6.43806 14.4287C6.77141 14.3339 6.9964 14.0231 6.98233 13.6768C6.96825 13.3305 6.71876 13.039 6.37882 12.9716C5.63768 12.8246 4.96528 12.4384 4.46489 11.8723C4.15493 11.5216 3.9216 11.1139 3.77585 10.6753C4.08891 10.7465 4.40928 10.7872 4.73247 10.796C5.06671 10.8051 5.36662 10.5919 5.46779 10.2732C5.56896 9.95449 5.44694 9.60732 5.16864 9.422C4.69438 9.10619 4.30567 8.6779 4.03718 8.17533C3.7687 7.67277 3.62879 7.11156 3.62995 6.54177L3.62994 6.5396C3.62973 6.29692 3.65459 6.0559 3.70353 5.82021C4.66078 6.78658 5.76884 7.5951 6.98682 8.21277C8.57973 9.02058 10.3249 9.48382 12.1088 9.57234C12.344 9.58401 12.571 9.48452 12.7219 9.30367C12.8727 9.12282 12.9299 8.88161 12.8762 8.6523C12.7021 7.90853 12.7783 7.12808 13.0931 6.43207C13.4079 5.73606 13.9436 5.16341 14.6171 4.80299C15.2906 4.44257 16.0643 4.31454 16.818 4.43876C17.5717 4.56298 18.2634 4.93251 18.7856 5.49C18.9625 5.67883 19.2242 5.76315 19.4781 5.71308C20.0504 5.60019 20.6097 5.43329 21.1485 5.21518C21.102 5.40259 21.0426 5.58705 20.9705 5.76723C20.7441 6.33302 20.3993 6.8426 19.9583 7.26155Z" className={`${className}`} strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  )
}

export default Twitter