import React from "react";
import Header from "../../components/Layout/HomeHeader/Header";
import Footer from "../../components/Layout/HomeFooter/Footer";

const BlogDetail = () => {
  return (
    <>
      <Header />

      {/* Main Section Start */}

      <section
        id="blogDetail"
        className="w-full mb-12 md:mb-20 lg:mb-20 2xl:mb-40"
      >
        <div className="pt-28 lg:pt-40 2xl:pt-60 max-w-contentSmallScreenWidth md:max-w-contentLargeScreenWidth mx-auto flex flex-col items-center gap-8 md:gap-20">
          <div className="flex flex-col items-center gap-3 w-11/12 xl:w-10/12">
            <h1 className="font-extrabold text-mainTextColor text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl text-center leading-snug md:leading-snug lg:leading-snug xl:leading-snug 2xl:leading-snug w-auto xl:w-11/12">
              10 Steps into Your{" "}
              <span className="text-mainColor">Successful</span> Fundraising
            </h1>
            <p className="text-sm md:text-base lg:text-2xl 2xl:text-3xl text-mainTextColor font-medium text-center 2xl:leading-normal">
              Raising funds can be a challenging task for startup founders.
              However, by adopting the correct approach you can enhance your
              chances of success.
            </p>
          </div>
          <img
            alt="blogDetail"
            className="w-full"
            src="/images/blogDetail/blogDetailImg.svg"
          />
        </div>
      </section>

      {/* Main Section End */}

      {/* Text Section Start */}

      <section
        id="textSection"
        className="w-full mb-16 md:mb-28 lg:mb-40 2xl:mb-60"
      >
        <div className="max-w-contentSmallScreenWidth md:max-w-contentLargeScreenWidth mx-auto flex flex-col gap-5 lg:gap-12 text-mainTextColor text-sm md:text-base lg:text-2xl 2xl:text-3xl">
          <p className="text-center lg:text-start">
            Hello there! Vagan, the founder of Raiderly, is on the line
          </p>
          <p>
            Raising funds can be a challenging task for startup founders.
            However, by adopting the correct approach you can enhance your
            chances of success. Drawing upon my extensive 7 years of experience
            in successful fundraising, I want to offer you valuable tips to walk
            you through the real efficient fundraising process, whether you're a
            first-time founder or a seasoned entrepreneur.
          </p>
          <p>ere are 10 steps into your successful fundraising:</p>
          <div className="flex flex-col gap-8 lg:gap-12">
            <div>
              <p>
                <span className="font-semibold">
                  1: Define Your Fundraising Goals.
                </span>{" "}
                Start by determining the amount of capital you really need and
                its intended use. Develop a simple straightforward financial
                plan that outlines your post-fundraising runway and the goals
                you plan to achieve with the funds. It's crucial to ensure that
                the funds raised will suffice to:
              </p>
              <ul className="list-disc ml-9">
                <li>
                  reach significant milestones required for the next round and
                  secure time for the next fundraising round, or
                </li>
                <li>
                  attain profitability and sustainability. During this
                  goal-setting process, don’t think about average venture
                  capital metrics or typical funding round amounts, as these
                  metrics vary widely from one company to another. Sometimes
                  $300k can be enough to move your project to sustainability,
                  while in other cases, you might need $15m just to get started.
                </li>
              </ul>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  2: Craft Your Ideal Investor Profile.
                </span>{" "}
                Once you've established your fundraising requirements, define
                the characteristics of investors who are likely to provide you
                with the funding and who might have an interest in your specific
                industry. Consider factors such as the type of investor (venture
                fund or angel investor), focus VC stage, expected investment
                size, geographical preferences, target industries, and other
                criteria.
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  3: Compile a Bullet List of Your Unique Advantages.
                </span>{" "}
                Now that you have a clear investor profile, prepare a bullet
                list of compelling reasons why you and your startup deserve
                investors' attention. Create this list before generating any
                documents to gain a realistic understanding of your current
                standing and whether you have sufficient merits to be able have
                secure funding. In this list, you may include notes on:
              </p>
              <ul className="list-disc ml-9">
                <li>
                  Your personal background and achievements, such as successful
                  exits.
                </li>
                <li>Evidence of product-market fit.</li>
                <li>
                  Impressive metrics related to your client base, waitlists, and
                  other relevant customer indicators.
                </li>
                <li>
                  Comprehensive market insights (maybe something that is not
                  known by everyone).
                </li>
                <li>
                  Unique marketing strategies and partnerships you have in your
                  pocket.
                </li>
                <li>
                  Any other critical factors that provide a light into your
                  project as an attractive investment opportunity.
                </li>
              </ul>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  4: Craft a Unique Pitch Deck.
                </span>{" "}
                 Once you’re sure of your list of unique advantages, it’s time
                to proceed and create a compelling pitch deck that tells your
                story. Create your pitch deck like a narrative book, and avoid
                the strict adherence to templates. Don’t hesitate to be unique
                and creative. Maybe you wanna stop the presentation on slide No.
                3 and forward investors to the product demo, or maybe something
                else. Anyway, ensure that your pitch deck incorporates all the
                key advantages outlined in the previous step. And structure deck
                you’d like to real without blind following everyone.
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  5: Create a List of Perfect Investors.
                </span>{" "}
                 Take the time to assemble a list of highly targeted investors
                who align with your startup's needs. Use multiple filters to
                limit this list to no more than 300 potential investors. Take in
                mind, that investors in this list are your ideal investors, and
                your startup should be able to secure investments from them, in
                another case it’s better to return to step 3 and work on
                enhancing the number of advantages your project offers to become
                more appealing to the initially identified target investors.
              </p>
            </div>
            <div>
              <p>
                At <span className="font-semibold">Raiderly,</span>  we
                simplifies the process of finding the perfect investors for you
                and your startup. We offer 8 powerful filtering options,
                including VC Type, Industry, Geography, Location, Stage,
                Diversity, Leading, and Investor Title. Additionally, our robust
                search feature allows you to research investors already on your
                list and obtain their personal contact details for outreach.
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  6: Outreach Investors and Maintain a CRM List.
                </span>{" "}
                Now your preparations are complete. Begin reaching out to your
                perfect investors. Utilize any available connections, especially
                warm introductions if possible, as they can significantly boost
                your chances. If warm introductions to someone are not
                available, consider reaching out through email, LinkedIn, and
                Twitter. Be sure to establish a CRM funnel for your fundraising
                process. You can use a classic CRM platform or a Notion
                datatable to keep track of your interactions. With cold
                outreach, more than 60% of responses come after the second or
                third contact, so using CRM is crucial to make things right.
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">7: Meet with Passion.</span> The
                next significant step is the investor meeting. It may be
                counterintuitive, but if an investor agrees to meet with
                you, they are already genuinely interested in your startup. What
                investors really looking for – is that understanding of you as a
                person. They want to learn more about who you are, your belief
                reason in the original idea, your core values, and your ability
                to keep meaningful conversation. Your key task is to be
                enthusiastic, maintain a positive attitude, and ensure that you
                come across as someone trustworthy and relatable. After the
                meeting, the investor will form his opinion about your startup
                based on their interaction with you, which will either lead to
                opportunities or raise concerns and red flags.
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  8: Maintain 100% Accuracy Post-First Meeting.
                </span>{" "}
                 Okay, now the real game is on. You need to be 100% accurate
                with investors and all the words you say. Immediately follow up
                with investors as promised, and provide only accurate data and
                insights. Never break your commitments or be tardy. Throughout
                the process after the first meeting, investors take note of
                every interaction, both positive and negative. Punctuality and
                reliability are paramount, as investors evaluate whether they
                can envision a long-term working relationship with you spanning
                3-7 years.
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  9: Forward Conversations to Deals.
                </span>{" "}
                Mostly, investors may be eager to discuss your project
                indefinitely, so it's crucial to recognize the right moment to
                steer discussions toward terms and legal agreement. Investors
                should understand that you are expecting a decision and are not
                willing to waste time in fruitless discussions or false
                expectations. Typically, 3 meetings are the maximum required to
                finalize the decision and start discussing deal terms. Do not
                overlook this moment; remember that YOU ARE leading the deal and
                must take a proactive approach.
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">
                  10: Stay Focused Until Funds Are in Your Account.
                </span>{" "}
                Refrain from celebrating prematurely; your journey isn't
                complete until the funds are securely in your bank account. Even
                after signing documents with an investor, deals can still fall
                through. Maintain your focus, use your time wisely to enhance
                your list of unique advantages (as outlined in step 3), and
                expand the pool of target investors engaged in discussions. More
                options will lead to more committed investors who act swiftly to
                become part of your project ahead of other interested parties.
              </p>
            </div>
            <div>
              <p>
                I hope that this comprehensive guideline will assist you in your
                fundraising. It's essential to maintain a clear understanding
                and broad perspective on the entire process.
              </p>
            </div>
            <div>
              <p>
                At <span className="font-semibold">Raiderly,</span> we are
                delighted to assist founders in connecting with their ideal
                investors and in the creation of innovative and impactful
                products for humanity. Good luck!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Text Section End */}

      <Footer />
    </>
  );
};

export default BlogDetail;
