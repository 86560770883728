import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { sideBarData } from "../SideBarData";
import Logo from "../../common/Logo";
import Mail from "../../svg/Mail";
import Twitter from "../../svg/Twitter";
import { ROLE_TYPE, ROUTES_CONSTANT } from "../../../constant";
import { useProps } from "../../../contexts/PropsContext";
import SmallLoader from "../../Loaders/SmallLoader";
import { FaPlus } from "react-icons/fa6";
import { useUi } from "../../../hooks/user-interface";
import { modalType } from "../../../store/slices/ui.slice";
import { FILTER_DATA } from "../../../constant/data";
import { useUserAuth } from "../../../hooks/auth.hook";

interface propTypes {
  show: boolean;
}

const SideBar = ({ show }: propTypes) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, isAuthenticated } = useUserAuth();

  const {
    influencerCountData,
    investorCountData,
    fundCountData,
    savedFilterNames,
    setSearchText,
    selectedFilters,
  } = useProps();

  const { updateModal } = useUi();

  return (
    <div
      id="sidebar"
      className={`fixed lg:sticky h-screen w-[250px] lg:w-[320px] xl:w-[300px] flex flex-col overflow-y-scroll hideScrollBar ${
        show ? "left-0" : "-left-80"
      } lg:left-0 transition-all duration-500 top-20 lg:top-0 pb-20 lg:pb-0 z-40 `}
    >
      {isAuthenticated && user.status === "active" ? (
        <>
          <div className="bg-white pt-4 lg:pt-20 2xl:pt-24 flex flex-col gap-0 lg:gap-5 w-full h-fit pb-5">
            <div className="flex justify-start lg:justify-center items-start lg:fixed py-3 top-0 left-0 w-auto lg:w-[240px] xl:w-[230px] 2xl:w-[245px] bg-white relative">
              <div className="hidden lg:block">
                <Logo />
              </div>
              <p className="block lg:hidden px-4">Menu</p>
            </div>
            <div className="flex flex-col gap-1">
              {influencerCountData && investorCountData && fundCountData ? (
                sideBarData(location, {
                  influencer: influencerCountData.count,
                  investor: investorCountData.count,
                  fund: fundCountData.count,
                }).map((items, i) => {
                  return (
                    <div
                      className={`py-2 px-4 flex items-center justify-between cursor-pointer hover:bg-mainColor hover:bg-opacity-10 group ${
                        items.active ? "bg-mainColor bg-opacity-10" : ""
                      }`}
                      key={i}
                      onClick={() => {
                        navigate(items.link);
                        setSearchText("");
                      }}
                    >
                      <div className="flex items-center gap-2">
                        {items.icon}
                        <p
                          className={`${
                            items.active
                              ? "text-mainTextColor"
                              : "text-grayColor"
                          } group-hover:text-mainTextColor text-sm 2xl:text-base font-medium capitalize`}
                        >
                          {items.name}
                        </p>
                      </div>
                      <p
                        className={`${
                          items.active ? "text-mainTextColor" : "text-grayColor"
                        } group-hover:text-mainTextColor text-sm font-medium`}
                      >
                        {items.total}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="h-72">
                  <SmallLoader />
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-between pr-2">
            <h1 className="py-3 pl-4 font-semibold text-lg 2xl:text-2xl bg-gray-50">
              Saved Searches
            </h1>
            <FaPlus
              onClick={() =>
                updateModal({
                  type: modalType.save_modal,
                  state: {
                    type:
                      location.pathname === ROUTES_CONSTANT.ALL_INFLUENCERS
                        ? ROLE_TYPE.INFLUENCER
                        : location.pathname === ROUTES_CONSTANT.ALL_INVESTORS
                        ? ROLE_TYPE.INVESTOR
                        : location.pathname === ROUTES_CONSTANT.ALL_FUNDS
                        ? ROLE_TYPE.FUND
                        : "",
                    filters: selectedFilters ? selectedFilters : FILTER_DATA,
                  },
                })
              }
              className="cursor-pointer hover:text-mainColor transition-all duration-300"
            />
          </div>
          <div className="bg-white pt-2 flex flex-col gap-10 w-full h-full pb-5">
            <div className="flex flex-col gap-1">
              {savedFilterNames ? (
                savedFilterNames.length > 0 ? (
                  savedFilterNames.map((item, index: number) => {
                    return (
                      <div
                        className={`py-2 px-4 flex items-center justify-between cursor-pointer hover:bg-mainColor hover:bg-opacity-10 group ${
                          location.pathname.includes(item.id)
                            ? "bg-mainColor bg-opacity-10"
                            : ""
                        }`}
                        key={index}
                        onClick={() =>
                          navigate(
                            ROUTES_CONSTANT.SAVED_FILTERS.replace(
                              ":id",
                              item.id
                            )
                          )
                        }
                      >
                        <div className="flex items-center gap-2">
                          <img className="w-7 h-7" src={item.emoji} alt="" />
                          <p
                            className={`${
                              location.pathname.includes(item.id)
                                ? "text-mainTextColor"
                                : "text-grayColor"
                            } group-hover:text-mainTextColor text-sm 2xl:text-base font-medium capitalize`}
                          >
                            {item.name}
                          </p>
                        </div>
                        <p
                          className={`${
                            location.pathname.includes(item.id)
                              ? "text-mainTextColor"
                              : "text-grayColor"
                          } group-hover:text-mainTextColor text-sm font-medium`}
                        >
                          {item.count}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div className="h-36 flex justify-center items-center text-sm">
                    No saved filters yet
                  </div>
                )
              ) : (
                <div className="h-36">
                  <SmallLoader />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <NavLink
                to={ROUTES_CONSTANT.CONTACT_US}
                className="px-4 py-2 flex items-center gap-2 hover:bg-mainColor group cursor-pointer hover:bg-opacity-10"
              >
                <Mail className="stroke-grayColor group-hover:stroke-mainTextColor" />
                <p className="text-sm 2xl:text-base font-medium text-grayColor group-hover:text-mainTextColor">
                  Contact Us
                </p>
              </NavLink>
              <div className="px-4 py-2 flex items-center gap-2 hover:bg-mainColor group cursor-pointer hover:bg-opacity-10">
                <Twitter className="stroke-grayColor group-hover:stroke-mainTextColor" />
                <p className="text-sm 2xl:text-base font-medium text-grayColor group-hover:text-mainTextColor">
                  Tweet @raiderlyapp
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-white pt-4 lg:pt-20 2xl:pt-24 flex flex-col gap-0 lg:gap-5 w-full h-fit pb-5">
            <div className="flex justify-start lg:justify-center items-start lg:fixed py-3 top-0 left-0 w-auto lg:w-[240px] xl:w-[230px] 2xl:w-[245px] bg-white relative">
              <div className="hidden lg:block">
                <Logo />
              </div>
            </div>
            <div className="flex flex-col gap-1"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default SideBar;
