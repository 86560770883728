import React, { useState } from "react";
import { REQ } from "../../../constant/api-endpoints";
import { HTTP_METHODS, HTTP_STATUS_CODES } from "../../../constant";
import toast from "react-hot-toast";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../../../interfaces";
import { MdDeleteOutline } from "react-icons/md";
import { fetchRequest } from "../../../utils/axios";
import SmallLoader from "../../Loaders/SmallLoader";
import { useProps } from "../../../contexts/PropsContext";

const DeleteButton: React.FC<{
  filterId: string;
  rowData: InfluencerInterface | InvestorInterface | FundInterface;
  refetch: () => void;
}> = ({ filterId, rowData, refetch }) => {
  const [loading, setLoading] = useState(false);

  const { refetchSavedFilterNames } = useProps();

  const onDeleteFilter = async (
    type: string,
    rowId: string,
    saveFilterId: string | undefined
  ) => {
    if (!saveFilterId) {
      return;
    }

    setLoading(true);

    const payload = {
      type,
      id: rowId,
    };
    try {
      const response = await fetchRequest({
        url: REQ.SAVED_FILTER.REMOVE_FROM_SAVE_LIST_BY_ID.replace(
          ":id",
          saveFilterId
        ),
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        refetchSavedFilterNames();
        refetch();
        toast.success("Deleted successfully");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex gap-2 items-center font-semibold text-sm pr-5">
          <SmallLoader width="20" />
          Deleting...
        </div>
      ) : (
        <div
          onClick={() => onDeleteFilter(rowData.type, rowData._id, filterId)}
          className="h-8 w-8 rounded-lg bg-white hover:bg-black transition-all duration-300 hover:bg-opacity-5 flex items-center justify-center border cursor-pointer relative"
        >
          <MdDeleteOutline className="text-red-500 h-5 w-5" />
        </div>
      )}
    </>
  );
};

export default DeleteButton;
