import React, { useEffect } from "react";
import { MdKeyboardArrowDown, MdVerified } from "react-icons/md";
import { IoMail } from "react-icons/io5";
import Tag from "../../components/InvestorsDetail/Tag";
import GetInTouch from "../../components/InvestorsDetail/GetInTouch";
import { useNavigate, useParams } from "react-router-dom";
import { useProps } from "../../contexts/PropsContext";
import { useGetQuery } from "../../store/store-hook";
import { REQ } from "../../constant/api-endpoints";
import SmallLoader from "../../components/Loaders/SmallLoader";
import { ROUTES_CONSTANT } from "../../constant";
import AddFilterDropDown from "../../components/common/AddFilterDropDown";
import { saveButtonStyle } from "../../constant/data";

const FundsDetail = () => {
  const { setHeaderData } = useProps();
  const { id: fundsId } = useParams();

  const navigate = useNavigate();

  const { data: fundsData, isLoading } = useGetQuery(
    REQ.FUND.GET_BY_ID.replace(":id", fundsId ?? ""),
    {
      skip: !fundsId,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (fundsData) {
      setHeaderData({
        pageTitle: fundsData.name,
      });
    }

    return () => {
      setHeaderData({
        pageTitle: "",
        pageIcon: "",
        dataCount: 0,
      });
    };
  }, [fundsData]);
  return (
    <>
      {isLoading && !fundsData ? (
        <SmallLoader />
      ) : (
        <>
          <div className="hidden md:flex justify-end ">
            <AddFilterDropDown
              rowData={[fundsData]}
              text="Save"
              style={saveButtonStyle}
              icon={<MdKeyboardArrowDown />}
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row justify-between gap-8 mb-8 px-3">
            <div className="w-full lg:w-2/3">
              <div className="flex flex-col gap-6 mb-8">
                <img
                  className="h-32 w-32 rounded-full"
                  src={fundsData.avatar || "/images/AllInvestors/userImg4.svg"}
                  alt=""
                />
                <div className="flex flex-col gap-3">
                  <h1 className="flex items-center gap-2 capitalize font-semibold text-2xl text-mainTextColor">
                    {fundsData.name}{" "}
                    {fundsData.isVerified && (
                      <MdVerified className="h-7 w-7 text-green-600" />
                    )}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col gap-8 mb-8">
                {/* <UpgradeBasicPlan /> */}
                <GetInTouch data={fundsData} />
              </div>
              <div className="flex flex-col gap-8">
                <h1 className="font-semibold text-mainTextColor text-2xl">
                  Details
                </h1>
                <div className="flex flex-col gap-2">
                  <h1 className="text-grayColor">Description</h1>
                  <p className="font-semibold text-mainTextColor">
                    {fundsData.description}
                  </p>
                </div>
                {/* <div className="flex flex-col gap-2">
              <h1 className="text-grayColor">Location</h1>
              <p className="font-semibold text-mainTextColor flex items-center gap-2">
                <FaMapPin className="text-red-500" /> San Francisco,
                California, United States
              </p>
            </div> */}
                <div className="flex flex-col gap-2">
                  <h1 className="text-grayColor">Email address</h1>
                  <p
                    className="font-semibold text-blue-500 flex cursor-pointer items-center gap-2"
                    onClick={() => navigate(ROUTES_CONSTANT.PLANS)}
                  >
                    <IoMail className="text-black" /> Available on Basic plan or
                    above
                  </p>
                </div>
                {fundsData.geography && fundsData.geography.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-grayColor">Invest in regions</h1>
                    <div className="flex items-center gap-2 flex-wrap">
                      {fundsData.geography.map(
                        (geography: string, index: number) => (
                          <Tag key={index} text={geography} />
                        )
                      )}
                    </div>
                  </div>
                )}
                {fundsData.stages && fundsData.stages.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-grayColor">Invest in stages</h1>
                    <div className="flex items-center gap-2 flex-wrap">
                      {fundsData.stages.map((stage: string, index: number) => (
                        <Tag key={index} text={stage} />
                      ))}
                    </div>
                  </div>
                )}
                {fundsData.industries && fundsData.industries.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-grayColor">Invest in industries</h1>
                    <div className="flex items-center gap-2 flex-wrap">
                      {fundsData.industries.map(
                        (industry: string, index: number) => (
                          <Tag key={index} text={industry} />
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/3 flex flex-col gap-1">
              {/* <h1 className="font-semibold text-2xl text-mainTextColor">
                Fund
              </h1>
              <div className="pl-2 pt-4 pb-6 border-b mb-4">
                <h1 className="flex items-center gap-5 transition-all duration-300 cursor-pointer hover:text-blue-500 font-semibold">
                  Susa Ventures
                </h1>
              </div> */}
              <div className="flex flex-col gap-8">
                {fundsData.founded &&
                  fundsData.founded.length > 0 &&
                  (fundsData.founded > 0 ? (
                    <div className="flex flex-col gap-1">
                      <h1 className="text-grayColor">Founded</h1>
                      <p className="text-2xl text-mainTextColor font-semibold">
                        {fundsData.founded}
                      </p>
                    </div>
                  ) : (
                    ""
                  ))}
                {fundsData.leadInvestments &&
                  fundsData.leadInvestments.length > 0 &&
                  (fundsData.leadInvestments > 0 ? (
                    <div className="flex flex-col gap-1">
                      <h1 className="text-grayColor">Lead Investments</h1>
                      <p className="text-2xl text-mainTextColor font-semibold">
                        {fundsData.leadInvestments}
                      </p>
                    </div>
                  ) : (
                    ""
                  ))}
                {fundsData.investments &&
                  fundsData.investments.length > 0 &&
                  (fundsData.investments > 0 ? (
                    <div className="flex flex-col gap-1">
                      <h1 className="text-grayColor">Investments</h1>
                      <p className="text-2xl text-mainTextColor font-semibold">
                        {fundsData.investments}
                      </p>
                    </div>
                  ) : (
                    ""
                  ))}
                {fundsData.exits &&
                  fundsData.exits.length > 0 &&
                  (fundsData.exits > 0 ? (
                    <div className="flex flex-col gap-1">
                      <h1 className="text-grayColor">Exits</h1>
                      <p className="text-2xl text-mainTextColor font-semibold">
                        {fundsData.exits}
                      </p>
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FundsDetail;
