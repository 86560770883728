import React from "react";
import Select, { Props, StylesConfig } from "react-select";
import { ReactSelectInterface } from "../../../interfaces";

interface SelectBoxProps {
  placeholder: string;
  label: string;
  width?: string;
  option: ReactSelectInterface[];
  error: string;
  onChange: (event: any) => void;
}

const SelectBox = React.forwardRef(
  (
    {
      placeholder,
      option,
      label,
      width,
      value,
      error,
      onChange,
      ...props
    }: SelectBoxProps | any,
    ref: any
  ) => {
    const customStyles: StylesConfig = {
      control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderRadius: "10px",
        backgroundColor: "#f9fafb",
        paddingTop: "10px",
        paddingBottom: "7px",
        fontSize: "14px",
        width: width || "100%",
        boxShadow: state.isFocused ? "orange" : "",
        border: state.isFocused
          ? "1px solid #FF7425"
          : "1px solid rgba(0, 0, 0, 0.25)",
        "&:hover": {
          border: state.isFocused
            ? "1px solid #FF7425"
            : "1px solid rgba(0, 0, 0, 0.25)",
        },
        minWidth: "71px",
      }),
    };

    return (
      <div className="flex flex-col gap-y-2">
        <p className="font-medium text-mainTextColor text-sm md:text-base 2xl:text-lg min-w-max">
          {label}
        </p>
        <Select
          {...props}
          ref={ref}
          options={option}
          value={value}
          styles={customStyles}
          placeholder={placeholder}
          onChange={onChange}
        />

        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>
    );
  }
);

export default SelectBox;
