import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useUserAuth } from "../../hooks/auth.hook";
import { getToken } from "../../utils/get-token";
import { ROUTES_CONSTANT } from "../../constant";
import ScreenLoader from "../../components/Loaders/ScreenLoader";

const PublicRoute = () => {
  const { isAuthenticated, isLoading, refetchUser } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const token = getToken();

  useEffect(() => {
    token && !isAuthenticated && refetchUser();
  }, [location.pathname]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && token) {
      navigate(ROUTES_CONSTANT.ALL_INVESTORS);
    }
  }, [isAuthenticated, isLoading]);

  return <>{isLoading || token ? <ScreenLoader /> : <Outlet />}</>;
};

export default PublicRoute;
