import React from "react";
import Check from "../svg/HomeCheck";
import { IoCheckmark } from "react-icons/io5";

interface Requirement {
  text: string;
  checkBg: string;
}

interface propsTypes {
  requirements: Requirement[];
  plan: string;
  price: string;
  className: string;
  onClick: () => void;
}

const Card = ({
  requirements,
  plan,
  price,
  className,
  onClick,
}: propsTypes) => {
  return (
    <div
      className={`border w-full rounded-xl py-4 px-3 flex flex-col gap-2 cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div className="flex items-center w-full justify-between">
        <h1 className="text-mainTextColor font-semibold text-base md:text-lg">
          {plan}
        </h1>
        <h1 className="text-mainTextColor font-semibold text-base md:text-lg">
          {price}/mo
        </h1>
      </div>
      <ul className="flex flex-col text-grayColor font-medium text-xs md:text-sm">
        {requirements.map((requirement, index) => {
          return (
            <li key={index} className="flex items-center gap-1">
              <IoCheckmark className={`text-${requirement.checkBg}`} />
              {requirement.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Card;
