import React, { useState } from "react";
import Input from "../../common/SearchBar";
import Search from "../../svg/Search";
import Button from "../../common/Button";
import DownArrow from "../../svg/DownArrow";
import ProfileDropDown from "../../common/ProfileDropDown";
import SideBarFilterIcon from "../../svg/SideBarFilterIcon";
import FilterIcon from "../../svg/FilterIcon";
import SearchIcon from "../../svg/SearchIcon";
import { QUERY_TYPE, ROLE_TYPE, ROUTES_CONSTANT } from "../../../constant";
import { useLocation } from "react-router-dom";
import Dots from "../../svg/Dots";
import SearchDropDown from "../../SavedFilters/SearchDropDown";
import { useUi } from "../../../hooks/user-interface";
import { modalType } from "../../../store/slices/ui.slice";
import { useProps } from "../../../contexts/PropsContext";
import { useUserAuth } from "../../../hooks/auth.hook";
import FilterBar from "../components/FilterBar";
import { REQ } from "../../../constant/api-endpoints";
import ExportCSVButton from "../../ExportCSVButton";
import { CapitalizeFirstLetter } from "../../../utils/functions";
import TotalCount from "../components/TotalCount";
import SmallLoader from "../../Loaders/SmallLoader";
import { useFilterQuery } from "../../../hooks/filterQuery.hook";
import AddFilterDropDown from "../../common/AddFilterDropDown";
import { saveButtonStyle } from "../../../constant/data";
import { MdKeyboardArrowDown } from "react-icons/md";

interface propTypes {
  onShowSidebar: (event: React.MouseEvent<HTMLElement>) => void;
}

const Header = ({ onShowSidebar }: propTypes) => {
  const { updateModal } = useUi();

  const location = useLocation();

  const {
    influencerData,
    investorData,
    fundData,
    investorPage,
    investorLimit,
    influencerPage,
    influencerLimit,
    fundPage,
    fundLimit,
    investorPostMutation,
    influencerPostMutation,
    fundPostMutation,
    headerData,
    searchText,
    setSearchText,
  } = useProps();

  const { user } = useUserAuth();

  const { addQuery, clearAllQuery } = useFilterQuery();

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [showSearchDropDown, setShowSearchDropDown] = useState<boolean>(false);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    clearAllQuery();
    addQuery({ text: e.target.value });
  };

  return (
    <header
      id="header"
      className={`transition-all duration-150 fixed top-0 left-0 right-auto lg:left-auto lg:right-0 bg-mainColor w-full lg:w-100vw-247px pb-8 z-30`}
    >
      <div
        className={`w-full flex flex-col 2xl:gap-12 px-0 lg:px-6 lg:pt-7 2xl:pt-10 ${
          location.pathname.includes(
            ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
          )
            ? "gap-0"
            : "gap-8"
        }`}
      >
        <div className="w-full flex justify-between items-center">
          <div
            className={`flex items-center gap-3 w-auto ${
              location.pathname.includes(
                ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
              ) ||
              location.pathname === ROUTES_CONSTANT.FUND_DETAIL ||
              location.pathname === ROUTES_CONSTANT.INFLUENCER_DETAIL ||
              location.pathname === ROUTES_CONSTANT.INVESTOR_DETAIL
                ? "lg:w-8/12 xl:w-7/12"
                : "lg:w-7/12 xl:w-6/12"
            }`}
          >
            <div
              className={`${
                location.pathname.includes(
                  ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
                )
                  ? ""
                  : "capitalize"
              } text-white text-2xl 2xl:text-4xl font-semibold items-center gap-3 hidden lg:flex`}
            >
              <div
                className={`flex items-center gap-x-3 ${
                  location.pathname.includes(
                    ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
                  )
                    ? "mr-5"
                    : "mr-0"
                }`}
              >
                {headerData.pageIcon &&
                location.pathname.includes(
                  ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
                ) ? (
                  <img className="h-7 w-7" src={headerData.pageIcon} />
                ) : (
                  ""
                )}

                {user.status === "active" ? (
                  headerData.pageTitle ? (
                    <div className="whitespace-nowrap flex gap-3">
                      <span>
                        {headerData.pageTitle.length > 20
                          ? `${headerData.pageTitle.slice(0, 15)}...`
                          : headerData.pageTitle}
                      </span>
                      {headerData.dataCount && +headerData.dataCount > 0 ? (
                        <TotalCount count={headerData.dataCount} />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <SmallLoader width="20" color="#fff" />
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="bg-white py-5 px-5 md:px-6 flex items-center justify-center lg:hidden">
              <img alt="man" className="h-10 w-10" src="/images/Man.svg" />
            </div>
            <div onClick={onShowSidebar}>
              <SideBarFilterIcon className="block lg:hidden" />
            </div>

            {location.pathname.includes(
              ROUTES_CONSTANT.INVESTOR_DETAIL.replace(":id", "")
            ) ||
            location.pathname.includes(
              ROUTES_CONSTANT.INFLUENCER_DETAIL.replace(":id", "")
            ) ||
            location.pathname.includes(
              ROUTES_CONSTANT.FUND_DETAIL.replace(":id", "")
            )
              ? ""
              : user.status === "active" && (
                  <div className="relative w-full hidden md:block">
                    <Input value={searchText} onChange={onSearch} />
                    <div className="absolute left-4 top-[14px]">
                      <Search />
                    </div>
                  </div>
                )}
          </div>
          <div className="flex items-center gap-x-3">
            <img
              alt="csv"
              className={`${
                location.pathname === ROUTES_CONSTANT.OUTREACH
                  ? "block md:hidden"
                  : "hidden"
              }`}
              src="/images/csv.svg"
            />
            <div
              onClick={() =>
                updateModal({
                  type: modalType.search_modal,
                  state: {},
                })
              }
              className="block md:hidden"
            >
              <SearchIcon />
            </div>
            <div
              onClick={() => setShowDropDown(!showDropDown)}
              className="relative mr-4 md:mr-6 lg:mr-0 lg:pr-0"
            >
              <Button
                padding="py-1 px-2"
                className="bg-white hidden md:flex items-center gap-2 text-sm lg:text-base 2xl:text-2xl font-semibold rounded-rounded63"
                icon={<DownArrow className="fill-mainTextColor" />}
                text={user ? CapitalizeFirstLetter(user.username) : "User"}
                img={user ? user.avatar : "/images/userImg.svg"}
              />
              <Button
                padding="py-1 px-2"
                className="bg-white flex md:hidden items-center gap-2 text-sm lg:text-base font-semibold rounded-rounded63"
                icon={<DownArrow className="fill-mainTextColor" />}
                text=""
                img={user ? user.avatar : "/images/userImg.svg"}
              />
              <ProfileDropDown
                className={`${
                  showDropDown ? "opacity-1 visible" : "opacity-0 invisible"
                }`}
              />
            </div>
          </div>
        </div>
        {user.status === "active" ? (
          <div
            className={`flex-wrap xl:flex-nowrap gap-y-5 gap-0 lg:gap-x-20 xl:gap-0 justify-between px-4 md:px-6 lg:px-0 ${
              location.pathname === ROUTES_CONSTANT.OUTREACH ||
              location.pathname === ROUTES_CONSTANT.INVESTOR_DETAIL ||
              location.pathname === ROUTES_CONSTANT.INFLUENCER_DETAIL ||
              location.pathname === ROUTES_CONSTANT.FUND_DETAIL ||
              location.pathname === ROUTES_CONSTANT.PLANS ||
              location.pathname.includes(
                ROUTES_CONSTANT.INVESTOR_DETAIL.replace(":id", "")
              ) ||
              location.pathname.includes(
                ROUTES_CONSTANT.INFLUENCER_DETAIL.replace(":id", "")
              ) ||
              location.pathname.includes(
                ROUTES_CONSTANT.FUND_DETAIL.replace(":id", "")
              ) ||
              location.pathname.includes(
                ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
              )
                ? "hidden"
                : "flex"
            }`}
          >
            {/* Investor filters */}
            {location.pathname === ROUTES_CONSTANT.ALL_INVESTORS && (
              <FilterBar
                callback={investorPostMutation}
                url={`${REQ.INVESTOR.FILTERS_PAGINATED}?page=${investorPage}&limit=${investorLimit}`}
                isSelectBoxsDisabled={false}
                queryType={searchText ? QUERY_TYPE.SEARCH : QUERY_TYPE.FILTERS}
              />
            )}

            {/* Influencer filters */}
            {location.pathname === ROUTES_CONSTANT.ALL_INFLUENCERS && (
              <FilterBar
                callback={influencerPostMutation}
                url={`${REQ.INFLUENCER.FILTERS_PAGINATED}?page=${influencerPage}&limit=${influencerLimit}`}
                isSelectBoxsDisabled={false}
                queryType={searchText ? QUERY_TYPE.SEARCH : QUERY_TYPE.FILTERS}
              />
            )}

            {/* Fund filters */}
            {location.pathname === ROUTES_CONSTANT.ALL_FUNDS && (
              <FilterBar
                callback={fundPostMutation}
                url={`${REQ.FUND.FILTERS_PAGINATED}?page=${fundPage}&limit=${fundLimit}`}
                isSelectBoxsDisabled={false}
                queryType={searchText ? QUERY_TYPE.SEARCH : QUERY_TYPE.FILTERS}
              />
            )}

            <div className="flex items-center gap-2 md:gap-5">
              <div
                onClick={() =>
                  updateModal({
                    type: modalType.filter_modal,
                    state: {},
                  })
                }
                className="p-1 md:p-2 rounded-md md:rounded-lg bg-white flex items-center justify-center lg:hidden"
              >
                <FilterIcon />
              </div>
              <div
                className={`${
                  location.pathname.includes(
                    ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
                  )
                    ? ""
                    : "capitalize"
                } text-white text-sm md:text-xl font-semibold flex items-center gap-2 md:gap-3 lg:hidden`}
              >
                <div
                  className={`flex items-center gap-x-3 ${
                    location.pathname.includes(
                      ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
                    )
                      ? "mr-5"
                      : "mr-0"
                  }`}
                >
                  {headerData.pageIcon &&
                  location.pathname.includes(
                    ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
                  ) ? (
                    <img className="h-7 w-7" src={headerData.pageIcon} />
                  ) : (
                    ""
                  )}

                  {headerData.pageTitle ? (
                    <div className="whitespace-nowrap flex gap-3">
                      <span>{headerData.pageTitle.slice(0, 15)}...</span>
                      {headerData.dataCount && +headerData.dataCount > 0 ? (
                        <TotalCount count={headerData.dataCount} />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <SmallLoader width="20" color="#fff" />
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 relative">
              {location.pathname === ROUTES_CONSTANT.ALL_INFLUENCERS ? (
                <ExportCSVButton
                  name={
                    CapitalizeFirstLetter(ROLE_TYPE.INFLUENCER + "s") as string
                  }
                  data={influencerData?.data || []}
                />
              ) : (
                ""
              )}

              {location.pathname === ROUTES_CONSTANT.ALL_INVESTORS ? (
                <ExportCSVButton
                  name={
                    CapitalizeFirstLetter(ROLE_TYPE.INVESTOR + "s") as string
                  }
                  data={investorData?.data || []}
                />
              ) : (
                ""
              )}

              {location.pathname === ROUTES_CONSTANT.ALL_FUNDS ? (
                <ExportCSVButton
                  name={CapitalizeFirstLetter(ROLE_TYPE.FUND + "s") as string}
                  data={fundData?.data || []}
                />
              ) : (
                ""
              )}

              {location.pathname === ROUTES_CONSTANT.OUTREACH ||
              location.pathname === ROUTES_CONSTANT.PLANS ? (
                ""
              ) : (
                <>
                  {location.pathname.includes(
                    ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
                  ) ||
                  location.pathname.includes(
                    ROUTES_CONSTANT.INVESTOR_DETAIL.replace(":id", "")
                  ) ||
                  location.pathname.includes(
                    ROUTES_CONSTANT.INFLUENCER_DETAIL.replace(":id", "")
                  ) ||
                  location.pathname.includes(
                    ROUTES_CONSTANT.FUND_DETAIL.replace(":id", "")
                  ) ? (
                    ""
                  ) : (
                    <div
                    // onClick={
                    //   // updateModal({
                    //   //   type: modalType.save_modal,
                    //   //   state: {
                    //   //     type:
                    //   //       location.pathname ===
                    //   //       ROUTES_CONSTANT.ALL_INFLUENCERS
                    //   //         ? ROLE_TYPE.INFLUENCER
                    //   //         : location.pathname ===
                    //   //           ROUTES_CONSTANT.ALL_INVESTORS
                    //   //         ? ROLE_TYPE.INVESTOR
                    //   //         : location.pathname === ROUTES_CONSTANT.ALL_FUNDS
                    //   //         ? ROLE_TYPE.FUND
                    //   //         : "",
                    //   //     filters: selectedFilters
                    //   //       ? selectedFilters
                    //   //       : FILTER_DATA,
                    //   //   },
                    //   // })
                    // }
                    >
                      <AddFilterDropDown
                        rowData={
                          location.pathname === ROUTES_CONSTANT.ALL_INVESTORS
                            ? investorData && investorData.data
                            : location.pathname ===
                              ROUTES_CONSTANT.ALL_INFLUENCERS
                            ? influencerData && influencerData.data
                            : location.pathname === ROUTES_CONSTANT.ALL_FUNDS
                            ? fundData && fundData.data
                            : []
                        }
                        text="Save"
                        style={saveButtonStyle}
                        icon={<MdKeyboardArrowDown />}
                      />
                    </div>
                  )}
                </>
              )}
              <img
                alt="csv"
                className="block md:hidden"
                src="/images/csv.svg"
              />
              {location.pathname.includes(
                ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
              ) ? (
                ""
              ) : (
                <img
                  alt="save"
                  className="block md:hidden"
                  onClick={() =>
                    updateModal({
                      type: modalType.save_modal,
                      state: {},
                    })
                  }
                  src="/images/Save.svg"
                />
              )}
              {location.pathname === ROUTES_CONSTANT.ALL_FUNDS ? (
                ""
              ) : (
                <img
                  alt="message"
                  className="block md:hidden"
                  src="/images/message all.svg"
                />
              )}
              {location.pathname.includes(
                ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
              ) ? (
                <div className="relative block md:hidden">
                  <div
                    onClick={() => setShowSearchDropDown(!showSearchDropDown)}
                    className="flex items-center justify-center border border-white hover:bg-white hover:bg-opacity-20 transition-all duration-300 px-1 md:px-3 py-1 md:py-2 rounded-md md:rounded-lg cursor-pointer"
                  >
                    <Dots />
                  </div>
                  <SearchDropDown
                    className={`${
                      showSearchDropDown
                        ? "opacity-1 visible"
                        : "opacity-0 invisible"
                    }`}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </header>
  );
};

export default Header;
