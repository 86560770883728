import React from "react";
import RequirementBox from "../RequirementBox";
import { NavLink } from "react-router-dom";
import { ROUTES_CONSTANT } from "../../../constant";
import Button from "../../common/Button";

interface Requirement {
  text: string;
  checkBg: string;
}

interface propsTypes {
  subscriptionsImg: string;
  levels: string;
  levelsTextColor: string;
  price: string;
  priceTextColor: string;
  startedTextColor: string;
  subscriptionsDivBackground: string;
  diamondBackground: string;
  buttonStyling: string;
  requirementTextColor: string;
  requirements: Requirement[];
  planDuration: string;
  priceTextSpanColor: string;
}

const SubscriptionCard = ({
  subscriptionsImg,
  levels,
  levelsTextColor,
  price,
  planDuration,
  priceTextColor,
  subscriptionsDivBackground,
  diamondBackground,
  buttonStyling,
  requirementTextColor,
  requirements,
  priceTextSpanColor,
  startedTextColor,
}: propsTypes) => {
  return (
    <div
      className={`w-full rounded-3xl relative overflow-hidden pb-16 lg:pb-5 ${subscriptionsDivBackground} flex flex-col justify-between`}
    >
      <div
        className={`absolute right-0 -translate-y-5 translate-x-5 h-32 w-32 md:h-36 md:w-36 rounded-full flex items-center justify-center ${diamondBackground}`}
      >
        <img className="w-3/6" src={subscriptionsImg} alt="Subscription" />
      </div>
      <div className="pl-5 xl:pl-10 pt-16 mb-12 lg:mb-8">
        <h1 className={`font-bold text-3xl mb-9 ${levelsTextColor}`}>
          {levels}
        </h1>
        <div className="flex flex-col gap-7">
          <h2 className={`text-4xl font-bold ${priceTextColor}`}>
            {price}{" "}
            <span className={`text-xl font-medium ${priceTextSpanColor}`}>
              /{planDuration}
            </span>
          </h2>
          <h3
            className={`font-bold text-xl text-mainTextColor ${startedTextColor}`}
          >
            Everything in starter
          </h3>
          <div className="flex flex-col gap-4">
            {requirements.map((requirement, index) => (
              <RequirementBox
                key={index}
                textColor={requirementTextColor}
                text={requirement.text}
                checkBg={requirement.checkBg}
              />
            ))}
          </div>
        </div>
      </div>
      <NavLink
        to={ROUTES_CONSTANT.SIGN_UP}
        className="w-full flex justify-center px-5"
      >
        <Button
          padding="py-3 lg:py-4 px-9"
          className={`${buttonStyling} text-base lg:text-xl w-full lg:w-auto flex justify-center rounded-rounded63`}
          text="Get Started"
          icon=""
        />
      </NavLink>
    </div>
  );
};

export default SubscriptionCard;
