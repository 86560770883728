import React from "react";
import { CardEmojiInterface } from "../../interfaces";
import Card from "./Card";
import Plus from "../svg/Plus";
import { UploadImage } from "../../utils/functions";
import { nanoid } from "@reduxjs/toolkit";

const EmojiSelection: React.FC<{
  cardState: CardEmojiInterface[];
  activeCard: CardEmojiInterface;
  setActiveCard: React.Dispatch<React.SetStateAction<CardEmojiInterface>>;
  setCardState: React.Dispatch<React.SetStateAction<CardEmojiInterface[]>>;
}> = ({ cardState, activeCard, setActiveCard, setCardState }) => {
  return (
    <div className="w-full overflow-x-auto overflow-y-hidden">
      <div className="min-w-min flex gap-5 items-center justify-between">
        {cardState.map((card) => (
          <Card
            key={card.id}
            isActive={activeCard.id === card.id}
            url={card.url}
            onClick={() => setActiveCard(card)}
          />
        ))}
        <label htmlFor="uploadEmoji">
          <div className="h-12 w-12 md:h-16 md:w-16 rounded-full flex items-center justify-center bg-red-200 bg-opacity-90 text-mainTextColor text-3xl font-bold focus:bg-mainColor cursor-pointer hover:bg-red-100 transition-all duration-300">
            <Plus />
          </div>
        </label>
        <input
          id="uploadEmoji"
          className="hidden"
          type="file"
          onChange={(e) =>
            UploadImage(e.target.files ? e.target.files[0] : null, (res) =>
              setCardState([...cardState, { id: nanoid(), url: res }])
            )
          }
        />
      </div>
    </div>
  );
};

export default EmojiSelection;
