import React from 'react'

interface propTypes {
  className : string;
}

const DownArrow = ({className} : propTypes) => {
  return (
    <svg className={`m-0 p-0 ${className}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.694 9.21053L12.3575 13.547L8.02103 9.21053C7.87958 9.07392 7.69013 8.99832 7.49348 9.00003C7.29684 9.00174 7.10873 9.08061 6.96967 9.21967C6.83061 9.35873 6.75174 9.54684 6.75003 9.74348C6.74832 9.94013 6.82392 10.1296 6.96053 10.271L11.825 15.1355C11.8948 15.2056 11.9778 15.261 12.0692 15.2986C12.1607 15.3362 12.2587 15.3552 12.3575 15.3545C12.4564 15.355 12.5543 15.3359 12.6457 15.2983C12.7371 15.2608 12.8202 15.2054 12.89 15.1355L17.7545 10.271C17.8912 10.1296 17.9667 9.94013 17.965 9.74348C17.9633 9.54684 17.8845 9.35873 17.7454 9.21967C17.6063 9.08061 17.4182 9.00174 17.2216 9.00003C17.0249 8.99832 16.8355 9.07392 16.694 9.21053Z"/>
</svg>
  )
}

export default DownArrow