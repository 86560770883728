import React from "react";
import UsersImg from "../UserImg";
import { SectionSixReviewInterface } from "../../../interfaces";

const Card: React.FC<{ review: SectionSixReviewInterface }> = ({ review }) => {
  return (
    <div className="border border-gray-200 bg-white shadow-xl rounded-3xl px-4 py-6 md:py-10 h-fit">
      <div className="relative mb-6 md:mb-10">
        <img
          alt="_card"
          className="absolute top-[-10px]"
          src="/images/Home/commas.svg"
        />
        <p className="text-mainTextColor text-sm 2xl:text-xl z-10 relative leading-relaxed">
          {review.review}
        </p>
      </div>
      <div className="flex gap-2">
        <UsersImg className="min-w-1/5" img={review.avatar} />
        <div className="flex flex-col gap-1">
          <h1 className="text-sm 2xl:text-xl font-semibold">{review.name}</h1>
          <p className="text-xs 2xl:text-base font-medium text-gray-500">
            {review.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
