import React from "react";
import Cross from "../components/svg/Cross";
import InfoModalInput from "../components/StartUpInfoModal/InfoModalInput";
import Button from "../components/common/Button";
import SelectBox from "../components/SavedFilters/SelectBox";
import { useUi } from "../hooks/user-interface";
import {
  INDUSTRY_OPTIONS,
  LOCATION_OPTIONS,
  STAGE_OPTIONS,
} from "../constant/data";
import { useForm } from "react-hook-form";
import { StartupInfoSchema } from "../validation-schema";
import { ReactSelectInterface, UserInterface } from "../interfaces";
import SmallLoader from "../components/Loaders/SmallLoader";
import {
  CapitalizeFirstLetter,
  ConvertArrayIntoSelectOptions,
  UploadImage,
} from "../utils/functions";
import { useUserAuth } from "../hooks/auth.hook";
import { useProps } from "../contexts/PropsContext";

const StartUpInfoModal = () => {
  const { hideModal } = useUi();

  const { user, updateUserDetails } = useUserAuth();
  const { onUpdateUser } = useProps();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [uploadAvatarLoading, setUploadAvatarLoading] =
    React.useState<boolean>(false);

  const [selectedValues, setSelectedValues] = React.useState({
    location: [
      {
        label: user.location
          ? CapitalizeFirstLetter(user.location)
          : "Choose country",
        value: user.location ? user.location.toLowerCase() : "",
        isDisabled: user.location ? false : true,
      },
    ],
    mainIndustry: [
      {
        label: user.mainIndustry
          ? CapitalizeFirstLetter(user.mainIndustry)
          : "Choose main industry",
        value: user.mainIndustry ? user.mainIndustry.toLowerCase() : "",
        isDisabled: user.mainIndustry ? false : true,
      },
    ],
    additionalIndustry1: [
      {
        label: user.additionalIndustry1
          ? CapitalizeFirstLetter(user.additionalIndustry1)
          : "Choose additional industry",
        value: user.additionalIndustry1
          ? user.additionalIndustry1.toLowerCase()
          : "",
        isDisabled: user.additionalIndustry1 ? false : true,
      },
    ],
    additionalIndustry2: [
      {
        label: user.additionalIndustry2
          ? CapitalizeFirstLetter(user.additionalIndustry2)
          : "Choose additional industry",
        value: user.additionalIndustry2
          ? user.additionalIndustry2.toLowerCase()
          : "",
        isDisabled: user.additionalIndustry2 ? false : true,
      },
    ],
    companyStage: [
      {
        label: user.companyStage
          ? CapitalizeFirstLetter(user.companyStage)
          : "Choose stage",
        value: user.companyStage ? user.companyStage.toLowerCase() : "",
        isDisabled: user.companyStage ? false : true,
      },
    ],
  });

  const {
    register,
    handleSubmit: fromSubmit,
    formState: { errors, isLoading },
    setValue,
  } = useForm({ resolver: StartupInfoSchema });

  const onUpdateUserAvatar = async (avatar: string) => {
    const payload: UserInterface | any = {
      ...user,
      avatar,
      subscription: user.subscription ? user.subscription.id : null,
    };

    updateUserDetails({ ...user, avatar });
    onUpdateUser(user.id, payload);
    setLoading(false);
    setUploadAvatarLoading(false);
  };

  const onSaveStartupInfo = (values: any) => {
    const payload: UserInterface | any = {
      ...user,
      ...values,
      subscription: user.subscription ? user.subscription.id : null,
    };
    setLoading(true);
    updateUserDetails({ ...user, ...values });
    onUpdateUser(user.id, payload, () => {
      setLoading(false);
      hideModal();
    });
  };

  return (
    <div
      id="StartUpInfoModal"
      className="bg-black bg-opacity-20 w-full h-full flex items-center justify-center fixed top-0 left-0 z-50 py-5 my-auto overflow-y-auto hideScrollBar"
    >
      <div className="bg-white rounded-3xl px-4 md:px-12 xl:px-12 pt-10 xl:pt-12 pb-8 w-11/12 md:w-4/5 xl:w-2/5 relative m-auto">
        <div className="w-full flex flex-col items-center gap-5 xl:gap-7">
          <div onClick={hideModal}>
            <Cross className="h-8 w-8 md:h-10 md:w-10 absolute top-4 left-3 md:left-7" />
          </div>
          <div className="flex flex-col items-center gap-9 pt-8">
            <div className="grid place-items-center gap-8">
              <p className="text-sm md:text-base text-mainTextColor 2xl:text-xl 2xl:text-center text-center">
                Please, invest some time to filling this form. We use this info
                to craft perfect emails with the power of AI.
              </p>
              <div className="grid place-items-center">
                {uploadAvatarLoading ? (
                  <>
                    <SmallLoader width="25" />
                    <p className="text-mainColor mt-3 font-medium 2xl:text-xl transition-all duration-300">
                      Uploading...
                    </p>
                  </>
                ) : (
                  <>
                    <div className="w-16 2xl:w-28 mb-3">
                      <img
                        src={
                          user
                            ? user.avatar
                            : "/images/AllInvestors/userImg1.svg"
                        }
                        alt="user img"
                        className="w-full rounded-full"
                      />
                    </div>
                    <label htmlFor="avatarUpload">
                      <p className="text-mainColor font-medium 2xl:text-xl transition-all duration-300 cursor-pointer hover:text-opacity-80">
                        Upload Your Logo
                      </p>
                    </label>

                    <input
                      id="avatarUpload"
                      type="file"
                      className="hidden"
                      onChange={(e) => {
                        setLoading(true);
                        setUploadAvatarLoading(true);
                        UploadImage(
                          e.target.files ? e.target.files[0] : null,
                          (res) => onUpdateUserAvatar(res)
                        );
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <form className="w-full" onSubmit={fromSubmit(onSaveStartupInfo)}>
              <div className="w-full flex flex-col gap-7">
                <InfoModalInput
                  {...register("username", {
                    required: true,
                    value: user.username,
                  })}
                  placeHolder="Enter your primary contact's name"
                  label="Primary contact's name"
                  error={errors.username?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("username", e.target.value)
                  }
                />
                <InfoModalInput
                  {...register("jobTitle", {
                    required: false,
                    value: user.jobTitle,
                  })}
                  placeHolder="Enter your primary contact's job title"
                  label="Primary contact's job title"
                  error={errors.jobTitle?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("jobTitle", e.target.value)
                  }
                />
                <InfoModalInput
                  {...register("email", {
                    required: true,
                    value: user.email,
                  })}
                  placeHolder="Enter your primary contact's email address"
                  label="Primary contact's email address"
                  error={errors.email?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("email", e.target.value)
                  }
                />
                <InfoModalInput
                  {...register("startUpName", {
                    required: false,
                    value: user.startUpName,
                  })}
                  placeHolder="Enter your company name"
                  label="Company name"
                  error={errors.startUpName?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("startUpName", e.target.value)
                  }
                />
                <InfoModalInput
                  {...register("website", {
                    required: false,
                    value: user.website,
                  })}
                  placeHolder="Enter your company website"
                  label="Company website"
                  error={errors.website?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("website", e.target.value)
                  }
                />
                <InfoModalInput
                  {...register("description", {
                    required: false,
                    value: user.description,
                  })}
                  placeHolder="Enter your company description"
                  label="Short description"
                  className="pb-28"
                  error={errors.description?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("description", e.target.value)
                  }
                />
                <InfoModalInput
                  {...register("pitchDeckLink", {
                    required: false,
                    value: user.pitchDeckLink,
                  })}
                  placeHolder="Enter your pitch deck link"
                  label="Pitch deck link"
                  error={errors.pitchDeckLink?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("pitchDeckLink", e.target.value)
                  }
                />
                <SelectBox
                  {...register("location", {
                    required: false,
                    value: user.location,
                  })}
                  label="Company location"
                  placeholder="Choose location"
                  option={[
                    {
                      label: "Choose country",
                      value: "",
                      isDisabled: true,
                    },
                    ...LOCATION_OPTIONS,
                  ]}
                  value={selectedValues.location}
                  error={errors.location?.message || ""}
                  onChange={(e: any) => {
                    setValue("location", e.value);
                    setSelectedValues({
                      ...selectedValues,
                      location: [e],
                    });
                  }}
                />
                <SelectBox
                  {...register("mainIndustry", {
                    required: false,
                    value: user.mainIndustry,
                  })}
                  label="Main Industry"
                  placeholder="Choose industry"
                  option={[
                    {
                      label: "Choose main industry",
                      value: "",
                      isDisabled: true,
                    },
                    ...INDUSTRY_OPTIONS,
                  ]}
                  value={selectedValues.mainIndustry}
                  error={errors.mainIndustry?.message || ""}
                  onChange={(e: any) => {
                    setValue("mainIndustry", e.value);
                    setSelectedValues({
                      ...selectedValues,
                      mainIndustry: [e],
                    });
                  }}
                />
                <SelectBox
                  {...register("additionalIndustry1", {
                    required: false,
                    value: user.additionalIndustry1,
                  })}
                  label="Additional Industry 1"
                  placeholder="Choose additional industry"
                  option={[
                    {
                      label: "Choose additional industry",
                      value: "",
                      isDisabled: true,
                    },
                    ...INDUSTRY_OPTIONS,
                  ]}
                  value={selectedValues.additionalIndustry1}
                  error={errors.additionalIndustry1?.message || ""}
                  onChange={(e: any) => {
                    setValue("additionalIndustry1", e.value);
                    setSelectedValues({
                      ...selectedValues,
                      additionalIndustry1: [e],
                    });
                  }}
                />
                <SelectBox
                  {...register("additionalIndustry2", {
                    required: false,
                    value: user.additionalIndustry2,
                  })}
                  label="Additional Industry 2"
                  placeholder="Choose additional industry"
                  option={[
                    {
                      label: "Choose additional industry",
                      value: "",
                      isDisabled: true,
                    },
                    ...INDUSTRY_OPTIONS,
                  ]}
                  value={selectedValues.additionalIndustry2}
                  error={errors.additionalIndustry2?.message || ""}
                  onChange={(e: any) => {
                    setValue("additionalIndustry2", e.value);
                    setSelectedValues({
                      ...selectedValues,
                      additionalIndustry2: [e],
                    });
                  }}
                />
                <SelectBox
                  {...register("companyStage", {
                    required: false,
                    value: user.companyStage,
                  })}
                  label="Company stage"
                  placeholder="Choose Company stage"
                  option={[
                    {
                      label: "Choose stage",
                      value: "",
                      isDisabled: true,
                    },
                    ...STAGE_OPTIONS,
                  ]}
                  value={selectedValues.companyStage}
                  error={errors.companyStage?.message || ""}
                  onChange={(e: any) => {
                    setValue("companyStage", e.value);
                    setSelectedValues({
                      ...selectedValues,
                      companyStage: [e],
                    });
                  }}
                />
                <InfoModalInput
                  {...register("fundingRoundSize", {
                    required: false,
                    value: user.fundingRoundSize,
                  })}
                  placeHolder="Enter your funding round size"
                  label="Funding round size"
                  error={errors.fundingRoundSize?.message || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("fundingRoundSize", e.target.value)
                  }
                />
              </div>
              {loading || isLoading ? (
                <div className="mt-8">
                  <SmallLoader width="25" />
                </div>
              ) : (
                <Button
                  padding="py-2"
                  className="mt-8 w-full bg-mainColor text-white font-medium rounded-lg text-lg capitalize 2xl:text-xl flex justify-center"
                  text="save"
                  icon=""
                  disabled={isLoading}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartUpInfoModal;
