import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import React from "react";
import {
  GOOGLE_CLIENT_ID,
  HTTP_METHODS,
  HTTP_STATUS_CODES,
} from "../../constant";
import axios from "axios";
import SmallLoader from "../Loaders/SmallLoader";
import { fetchRequest } from "../../utils/axios";
import { REQ } from "../../constant/api-endpoints";
import toast from "react-hot-toast";
import {
  GoogleSignInInterface,
  SignInResponseInterface,
} from "../../interfaces";
import { useUserAuth } from "../../hooks/auth.hook";

const SignInWithGoogle: React.FC<{ text: string }> = ({ text }) => {
  const { updateUserDetails, loggedInUser } = useUserAuth();

  const [loading, setLoading] = React.useState<boolean>(false);

  const GoogleLoginComponent = () => {
    const onLogin: any = useGoogleLogin({
      onSuccess: async (response) => {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        const payload: GoogleSignInInterface = {
          email: res.data.email,
          username: res.data.email.split("@")[0],
          avatar: res.data.picture,
          googleId: res.data.sub,
          isVerified: true,
        };

        setLoading(true);

        const result = await fetchRequest({
          url: REQ.AUTH.GOOGLE_SIGN_IN,
          type: HTTP_METHODS.POST,
          body: payload,
        });

        if (result && result.status === HTTP_STATUS_CODES.OK) {
          const data: SignInResponseInterface = result.data;
          setLoading(false);
          toast.success("Signed in successfully");
          updateUserDetails(data.user);
          loggedInUser({
            access: data.accessToken,
            refresh: data.refreshToken,
          });

          window.location.reload();
        } else {
          setLoading(false);
        }
      },
    });

    return (
      <>
        {loading ? (
          <div className="w-full flex justify-center">
            <SmallLoader />
          </div>
        ) : (
          <div
            className="flex justify-center items-center gap-4 cursor-pointer transition-all duration-300 hover:bg-opacity-95 hover:bg-blue-100 bg-blue-500 bg-opacity-10 py-3 md:py-4 rounded-full"
            onClick={onLogin}
          >
            <span>
              <img
                alt="_google"
                className="h-auto w-auto 2xl:h-12 2xl:w-12"
                src="/images/SignIn/google.png"
              />
            </span>
            <span className="text-sm 2xl:text-2xl text-blue-400">{text}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleLoginComponent />
    </GoogleOAuthProvider>
  );
};

export default SignInWithGoogle;
