import {
  modalType,
  closeModal,
  updateModalAndState,
} from "../store/slices/ui.slice";
import { useAppDispatch, useAppSelector } from "./store";

export const useUi = () => {
  const state = useAppSelector((state) => state.userInterface);
  const dispatch = useAppDispatch();

  const hideModal = () => dispatch(closeModal());
  const updateModal = (val: { type: modalType; state: unknown }) =>
    dispatch(updateModalAndState(val));

  return {
    ...state,
    hideModal,
    updateModal,
  };
};
