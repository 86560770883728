import React, { lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoBox from "../../components/InfoBox";
import { PostResponseInterface, SubscriptionInterface } from "../../interfaces";
import {
  HTTP_METHODS,
  HTTP_STATUS_CODES,
  ROUTES_CONSTANT,
} from "../../constant";
import { useUserAuth } from "../../hooks/auth.hook";
import { getToken } from "../../utils/get-token";
import toast from "react-hot-toast";
import { fetchRequest } from "../../utils/axios";
import { REQ } from "../../constant/api-endpoints";
import Header from "../../components/Layout/HomeHeader/Header";

const PaymentSession = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const token = getToken() || "";

  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<boolean>();

  const params = new URLSearchParams(window.location.search);

  const success = params.get("success");
  const sessionId = params.get("session_id");
  const cancel = params.get("canceled");

  useEffect(() => {
    if (success === "true" && sessionId) {
      setIsPaymentSuccessful(true);
      onPaymentSuccess();
    } else if (cancel === "true") {
      setIsPaymentSuccessful(false);
    } else {
      navigate(ROUTES_CONSTANT.PLANS);
    }
  }, []);

  const getFilteredSubscriptions = async (query: { userId: string }) => {
    try {
      const response = await fetchRequest({
        url: REQ.SUBSCRIPTION.GET_FILTERED_SUBSCRIPTIONS,
        type: HTTP_METHODS.POST,
        body: query,
      });

      if (response && response.status === HTTP_STATUS_CODES.OK) {
        return response;
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const updateSubscription = async (
    id: string,
    query: { stripeSessionId: string }
  ) => {
    try {
      const response = await fetchRequest({
        url: REQ.SUBSCRIPTION.UPDATE_SUBSCRIPTION.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: query,
      });

      if (response && response.status === HTTP_STATUS_CODES.OK) {
        return response;
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const onPaymentSuccess = async () => {
    const query = {
      userId: user.id,
    };

    const response = await getFilteredSubscriptions(query);

    if (
      response &&
      response.status === HTTP_STATUS_CODES.OK &&
      response.data.length > 0
    ) {
      const userSubscription: SubscriptionInterface = response.data[0];
      await updateSubscription(userSubscription.id, {
        stripeSessionId: sessionId as string,
      });
    } else {
      toast.error("No subscription found for the user");
    }
  };

  return (
    <>
      <Header />
      <div className="w-full py-16 pb-28 h-[100vh] relative mb-9 flex flex-col gap-y-20 justify-center items-center">
        <div className="flex flex-col items-center gap-3">
          <h1 className="text-4xl text-mainBlackColor font-semibold">
            Payment Status
          </h1>
        </div>
        {isPaymentSuccessful ? (
          <InfoBox
            image="/images/success.gif"
            secondaryText="Your payment was successful"
          />
        ) : (
          <InfoBox
            image="/images/failure.jpeg"
            secondaryText="Your payment failed due to unexpected error."
          />
        )}
      </div>
    </>
  );
};

export default PaymentSession;
