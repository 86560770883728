import React from "react";

interface propTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeHolder: string;
  className?: string;
  error: string;
}

const InfoModalInput = React.forwardRef<any, propTypes>(
  ({ label, placeHolder, className, error, ...props }, ref: any) => {
    return (
      <div>
        <p className="mb-2 font-medium text-sm md:text-base text-mainTextColor 2xl:text-lg">
          {label}
        </p>
        <input
          ref={ref}
          {...props}
          type="text"
          placeholder={placeHolder}
          className={`outline-none border w-full p-3 rounded-md text-sm 2xl:text-lg py-4 bg-gray-50 focus:border-mainColor transition-all duration-300 ${className}`}
        />

        {error && <p className="text-red-500 text-sm mt-3">{error}</p>}
      </div>
    );
  }
);

export default InfoModalInput;
