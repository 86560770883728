import React, { useState, useEffect } from "react";

const CountdownTimer: React.FC<{
  name: string;
  startSeconds: string;
  className?: string;
  onStop: () => void;
}> = ({ name, startSeconds, className, onStop }) => {
  const [seconds, setSeconds] = useState(
    parseInt(localStorage.getItem(name) || startSeconds)
  );

  useEffect(() => {
    if (seconds > 0) {
      localStorage.setItem(name, seconds.toString());
      const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      localStorage.removeItem(name);
      onStop();
    }
  }, [seconds]);

  return <>{seconds} </>;
};

export default CountdownTimer;
