import { createSlice } from "@reduxjs/toolkit";
import { verifyUser } from "../action/user-verify";
import { AuthStateInterface } from "../../interfaces";
import { HTTP_STATUS_CODES } from "../../constant";
import { deleteCookie } from "../../utils/cookies";

export const initialState: AuthStateInterface = {
  user: {
    id: "",
    googleId: "",
    username: "",
    email: "",
    avatar: "",
    password: "",
    status: "in-active",
    role: "",
    subscription: {
      id: "",
      stripeSessionId: "",
      stripeCustomerId: "",
      stripeSubscriptionId: "",
      subscriptionStatus: "",
      paymentStatus: "",
      plan: "",
      price: "",
      startedAt: "",
      expiredAt: "",
      createdAt: "",
      userId: "",
    },
    vipStatus: false,
    phone_number: "",
    jobTitle: "",
    website: "",
    startUpName: "",
    description: "",
    pitchDeckLink: "",
    location: "",
    mainIndustry: "",
    additionalIndustry1: "",
    additionalIndustry2: "",
    companyStage: "",
    fundingRoundSize: "",
    sms_otp: "",
    sms_otp_created_at: "",
    sms_otp_expires_at: "",
    number_verified_at: "",
    createdAt: "",
    updatedAt: "",
  },
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

const authUserInfo = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state: AuthStateInterface, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    logout: (state: AuthStateInterface) => {
      state.isAuthenticated = false;
    },
    loggedIn: (state: AuthStateInterface) => {
      state.isAuthenticated = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUser.pending, (state: AuthStateInterface) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state: AuthStateInterface, action) => {
        state.isLoading = false;

        if (action.payload.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
          state.isAuthenticated = false;
          state.error = action.payload.data.message ?? "An error occurred";
          state.user = initialState.user;
          deleteCookie("access_token");
          deleteCookie("refresh_token");
          localStorage.clear();
        }

        if (action.payload.status === HTTP_STATUS_CODES.OK) {
          state.isAuthenticated = true;
          state.user = action.payload.data.user;
          state.error = null;
        } else {
          state.isAuthenticated = false;
          state.user = initialState.user;
          state.error = action.payload.data.message ?? "An error occurred";
        }
      })
      .addCase(verifyUser.rejected, (state: AuthStateInterface, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export const { updateUser, logout, loggedIn } = authUserInfo.actions;
export default authUserInfo.reducer;
