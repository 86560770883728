import React from "react";
import SavedFiltersTable from "../../components/SavedFilters/SavedFiltersTable/SavedFiltersTable";

const SavedFilters = () => {
  return (
    <section
      id="savedFilters"
      className="w-full bg-white px-3 rounded-2xl py-2"
    >
      <SavedFiltersTable />
    </section>
  );
};

export default SavedFilters;
