import React, { createContext, useEffect, useState } from "react";
import { createCtx } from "./Context";
import { REQ } from "../constant/api-endpoints";
import {
  FilterInterface,
  FilterTypeInterface,
  GetCountResponseInterface,
  GetPaginatedResponseInterface,
  HeaderDataInterface,
  SaveFilterResponseInterface,
  SaveFiltersModalFormInterface,
  SaveFiltersNamesResponseInterface,
  SectionsDataInterface,
  StoreHtmlDataInterface,
  UserInterface,
} from "../interfaces";
import { useGetQuery, usePostMutation } from "../store/store-hook";
import { getToken } from "../utils/get-token";
import {
  SectionFiveData,
  SectionFourData,
  SectionOneData,
  SectionSevenData,
  SectionSixData,
  SectionThreeData,
  SectionTwoData,
} from "./data";
import { ConvertArrayIntoObject } from "../utils/functions";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from "@reduxjs/toolkit/query";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { FILTER_DATA } from "../constant/data";
import { fetchRequest } from "../utils/axios";
import { HTTP_METHODS, HTTP_STATUS_CODES } from "../constant";
import toast from "react-hot-toast";
import { useUserAuth } from "../hooks/auth.hook";

type PropsContextType = {
  influencerData: GetPaginatedResponseInterface;
  influencerCountData: GetCountResponseInterface;
  investorData: GetPaginatedResponseInterface;
  investorCountData: GetCountResponseInterface;
  fundData: GetPaginatedResponseInterface;
  outReachData: GetPaginatedResponseInterface;
  fundCountData: GetCountResponseInterface;
  storeHtmlObj: StoreHtmlDataInterface;
  sectionsData: SectionsDataInterface;
  savedFilterNames: SaveFiltersNamesResponseInterface[];
  savedFiltersData: SaveFilterResponseInterface;
  filterTypeData: FilterTypeInterface[];
  filterIndustryData: FilterTypeInterface[];
  filterGeographyData: FilterTypeInterface[];
  filterStageData: FilterTypeInterface[];
  filterLocationData: FilterTypeInterface[];
  headerData: HeaderDataInterface;
  searchText: string;
  influencerPage: number;
  influencerLimit: number;
  investorPage: number;
  investorLimit: number;
  fundPage: number;
  fundLimit: number;
  outReachPage: number;
  outReachLimit: number;
  dataTableLoading: boolean;
  influencerDataLoading: boolean;
  influencerCountDataLoading: boolean;
  investorDataLoading: boolean;
  investorCountDataLoading: boolean;
  fundDataLoading: boolean;
  fundCountDataLoading: boolean;
  storeHtmlDataLoading: boolean;
  savedFilterNamesLoading: boolean;
  savedFiltersDataLoading: boolean;
  outReachDataLoading: boolean;
  selectedFilters: FilterInterface;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setHeaderData: React.Dispatch<React.SetStateAction<HeaderDataInterface>>;
  setSelectedFilters: React.Dispatch<React.SetStateAction<FilterInterface>>;
  setSectionsData: React.Dispatch<
    React.SetStateAction<SectionsDataInterface | any>
  >;
  setStoreHtmlObj: React.Dispatch<
    React.SetStateAction<StoreHtmlDataInterface | any>
  >;
  setInfluencerPage: React.Dispatch<React.SetStateAction<number>>;
  setInfluencerLimit: React.Dispatch<React.SetStateAction<number>>;
  setInvestorPage: React.Dispatch<React.SetStateAction<number>>;
  setInvestorLimit: React.Dispatch<React.SetStateAction<number>>;
  setFundPage: React.Dispatch<React.SetStateAction<number>>;
  setFundLimit: React.Dispatch<React.SetStateAction<number>>;
  setOutReachPage: React.Dispatch<React.SetStateAction<number>>;
  setOutReachLimit: React.Dispatch<React.SetStateAction<number>>;
  setDataTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetchInfluencerCount: () => void;
  refetchInvestorCount: () => void;
  refetchFundCount: () => void;
  refetchStoreHtml: () => void;
  refetchSavedFilterNames: () => void;
  onSaveFilters: (payload: SaveFiltersModalFormInterface) => void;
  influencerPostMutation: MutationTrigger<
    MutationDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      never,
      any,
      "fetchAllQuery"
    >
  >;
  investorPostMutation: MutationTrigger<
    MutationDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      never,
      any,
      "fetchAllQuery"
    >
  >;
  fundPostMutation: MutationTrigger<
    MutationDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      never,
      any,
      "fetchAllQuery"
    >
  >;
  savedFiltersPostMutation: MutationTrigger<
    MutationDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      never,
      any,
      "fetchAllQuery"
    >
  >;
  outReachPostMutation: MutationTrigger<
    MutationDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      never,
      any,
      "fetchAllQuery"
    >
  >;
  onUpdateUser: (
    id: string,
    payload: UserInterface,
    callback?: () => void
  ) => void;
};

export const [useProps, CtxProvider] = createCtx<PropsContextType>();

export const PropsContext = createContext<PropsContextType | undefined>(
  undefined
);

export const PropsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const token = getToken();

  const { user, refetchUser } = useUserAuth();

  const [dataTableLoading, setDataTableLoading] = useState<boolean>(false);
  const [influencerPage, setInfluencerPage] = useState<number>(1);
  const [influencerLimit, setInfluencerLimit] = useState<number>(10);
  const [investorPage, setInvestorPage] = useState<number>(1);
  const [investorLimit, setInvestorLimit] = useState<number>(10);
  const [fundPage, setFundPage] = useState<number>(1);
  const [fundLimit, setFundLimit] = useState<number>(10);
  const [outReachPage, setOutReachPage] = useState<number>(1);
  const [outReachLimit, setOutReachLimit] = useState<number>(10);
  const [storeHtmlObj, setStoreHtmlObj] = useState<
    StoreHtmlDataInterface | any
  >();
  const [sectionsData, setSectionsData] = useState<SectionsDataInterface>({
    sectionOne: SectionOneData,
    sectionTwo: SectionTwoData,
    sectionThree: SectionThreeData,
    sectionFour: SectionFourData,
    sectionFive: SectionFiveData,
    sectionSix: SectionSixData,
    sectionSeven: SectionSevenData,
  });
  const [selectedFilters, setSelectedFilters] =
    useState<FilterInterface>(FILTER_DATA);

  const [headerData, setHeaderData] = useState<HeaderDataInterface>({
    pageIcon: "",
    pageTitle: "",
    dataCount: 0,
  });

  const [searchText, setSearchText] = useState<string>("");

  const [
    influencerPostMutation,
    { isLoading: influencerDataLoading, data: influencerData },
  ] = usePostMutation();

  const [
    investorPostMutation,
    { isLoading: investorDataLoading, data: investorData },
  ] = usePostMutation();

  const [fundPostMutation, { isLoading: fundDataLoading, data: fundData }] =
    usePostMutation();

  const [
    savedFiltersPostMutation,
    { isLoading: savedFiltersDataLoading, data: savedFiltersData },
  ] = usePostMutation();

  const [
    outReachPostMutation,
    { isLoading: outReachDataLoading, data: outReachData },
  ] = usePostMutation();

  const {
    data: influencerCountData,
    isLoading: influencerCountDataLoading,
    refetch: refetchInfluencerCount,
  } = useGetQuery(REQ.INFLUENCER.GET_COUNT, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: investorCountData,
    isLoading: investorCountDataLoading,
    refetch: refetchInvestorCount,
  } = useGetQuery(REQ.INVESTOR.GET_COUNT, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: fundCountData,
    isLoading: fundCountDataLoading,
    refetch: refetchFundCount,
  } = useGetQuery(REQ.FUND.GET_COUNT, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: storeHtmlData,
    isLoading: storeHtmlDataLoading,
    refetch: refetchStoreHtml,
  } = useGetQuery(`${REQ.STORE_HTML.GET}`, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: savedFilterNames,
    isLoading: savedFilterNamesLoading,
    refetch: refetchSavedFilterNames,
  } = useGetQuery(
    REQ.SAVED_FILTER.GET_NAMES_BY_USER_ID.replace(":userId", user.id),
    {
      skip: token && user.id ? false : true,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: filterTypeData,
    isLoading: filtersTypeLoading,
    refetch: refetchTypeFilters,
  } = useGetQuery(`${REQ.FILTER.GET_BY_NAME}?name=type`, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: filterIndustryData,
    isLoading: filtersIndustryLoading,
    refetch: refetchIndustryFilters,
  } = useGetQuery(`${REQ.FILTER.GET_BY_NAME}?name=industry`, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: filterGeographyData,
    isLoading: filtersGeographyLoading,
    refetch: refetchGeographyFilters,
  } = useGetQuery(`${REQ.FILTER.GET_BY_NAME}?name=geography`, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: filterStageData,
    isLoading: filtersStageLoading,
    refetch: refetchStageFilters,
  } = useGetQuery(`${REQ.FILTER.GET_BY_NAME}?name=stage`, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: filterLocationData,
    isLoading: filtersLocationLoading,
    refetch: refetchLocationFilters,
  } = useGetQuery(`${REQ.FILTER.GET_BY_NAME}?name=location`, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (storeHtmlData && storeHtmlData.length > 0) {
      const data: StoreHtmlDataInterface = ConvertArrayIntoObject(
        storeHtmlData as StoreHtmlDataInterface[]
      );

      setStoreHtmlObj(data);
      setSectionsData({
        sectionOne: data.sectionOne,
        sectionTwo: data.sectionTwo,
        sectionThree: data.sectionThree,
        sectionFour: data.sectionFour,
        sectionFive: data.sectionFive,
        sectionSix: data.sectionSix,
        sectionSeven: data.sectionSeven,
      });
    }
  }, [storeHtmlData]);

  const onSaveFilters = async (payload: SaveFiltersModalFormInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.SAVED_FILTER.CREATE,
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Filters saved successfully");
        refetchSavedFilterNames();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onUpdateUser = async (
    id: string,
    payload: UserInterface,
    callback?: () => void
  ) => {
    try {
      const response = await fetchRequest({
        url: REQ.USER.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Profile updated successfully");
        refetchUser();
        if (callback) callback();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <CtxProvider
      value={{
        influencerData,
        influencerCountData,
        investorData,
        investorCountData,
        fundData,
        outReachData,
        fundCountData,
        headerData,
        storeHtmlObj,
        sectionsData,
        savedFilterNames,
        filterTypeData,
        filterIndustryData,
        filterGeographyData,
        filterStageData,
        filterLocationData,
        savedFiltersData,
        influencerPage,
        searchText,
        influencerLimit,
        investorPage,
        investorLimit,
        fundPage,
        fundLimit,
        outReachPage,
        outReachLimit,
        dataTableLoading,
        influencerDataLoading,
        influencerCountDataLoading,
        investorDataLoading,
        investorCountDataLoading,
        fundDataLoading,
        fundCountDataLoading,
        storeHtmlDataLoading,
        savedFilterNamesLoading,
        savedFiltersDataLoading,
        outReachDataLoading,
        selectedFilters,
        setSearchText,
        setHeaderData,
        setSelectedFilters,
        setStoreHtmlObj,
        setSectionsData,
        setInfluencerPage,
        setInfluencerLimit,
        setInvestorPage,
        setInvestorLimit,
        setFundPage,
        setFundLimit,
        setOutReachPage,
        setOutReachLimit,
        setDataTableLoading,
        onSaveFilters,
        refetchInfluencerCount,
        refetchInvestorCount,
        refetchFundCount,
        refetchStoreHtml,
        refetchSavedFilterNames,
        influencerPostMutation,
        investorPostMutation,
        fundPostMutation,
        savedFiltersPostMutation,
        outReachPostMutation,
        onUpdateUser,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export default PropsProvider;
