import React from "react";
import { RotatingLines } from "react-loader-spinner";

const ScreenLoader = () => {
  return (
    <div className="fixed top-0 left-0 z-[999] grid h-screen w-screen place-items-center ">
      <RotatingLines
        strokeColor="#FF7425"
        strokeWidth="5"
        animationDuration="0.75"
        width="30"
        visible={true}
      />
    </div>
  );
};

export default ScreenLoader;
