import React from 'react'

interface propTypes {
  className: string
}

const SearchDropDown = ({ className }: propTypes) => {
  return (
    <div className={`w-48 absolute right-0 top-9 2xl:top-20 shadow-lg py-1 bg-white rounded-md translate-y-2 ${className} transition-all duration-300`}>
      <div className='flex flex-col gap-y-2'>
        <p className='w-full transition-all duration-300  hover:bg-red-200 pl-4 cursor-pointer py-2 text-sm 2xl:text-2xl font-medium text-red-500'>Search</p>
      </div>
    </div>
  )
}

export default SearchDropDown