import React from "react";
import { ROUTES_CONSTANT } from "../../../constant";
import { FaArrowRightLong } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Button from "../../common/Button";

const MessageInvestorsSection: React.FC<{ image: string }> = ({ image }) => {
  return (
    <div className="flex flex-col items-center gap-8 lg:gap-12 w-full">
      <div className="relative w-full">
        <img alt="_dashboardImg" className="w-full" src={image} />

        <img
          alt="manSitting"
          className="absolute -top-16 md:-top-36 lg:-top-52 -translate-y-2 lg:translate-y-0 -translate-x-8 md:-translate-x-28 inset-x-2/4 h-auto w-auto md:h-56 md:w-56 lg:h-80 lg:w-80"
          src="/images/Home/manSittingDashboardImg.svg"
        />
      </div>
      <p className="text-base md:text-lg lg:text-2xl 2xl:text-3xl font-medium text-mainTextColor w-full text-center">
        Yes, it's that simple.
      </p>
      <NavLink to={ROUTES_CONSTANT.SIGN_UP}>
        <Button
          text="Message investors"
          padding="px-6 lg:px-9 py-3 2xl:py-5"
          className="hover:bg-mainTextColor bg-mainColor text-white font-medium text-base lg:text-lg 2xl:text-2xl hover:opacity-95 rounded-rounded63"
          icon={<FaArrowRightLong />}
        />
      </NavLink>
    </div>
  );
};

export default MessageInvestorsSection;
