import ChangePassword from "../pages/ChangePassword";
import ContactUs from "../pages/ContactUs";
import AllFunds from "../pages/allFunds";
import AllInfluencer from "../pages/allInfluencers";
import AllInvestors from "../pages/allInvestors";
import FundsDetail from "../pages/fundsDetail";
import InfluencersDetail from "../pages/influencersDetail";
import InvestorsDetail from "../pages/investorsDetail";
import OutReach from "../pages/outReach";
import PaymentSession from "../pages/paymentSession";
import Plans from "../pages/plans";
import SavedFilters from "../pages/savedFilters";

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

export const STRIPE_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID || "";
export const STRIPE_YEARLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID || "";

export const ROUTES_CONSTANT = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  RESET_PASSWORD: "/reset-password",
  PRICING: "/pricing",
  FAQ: "/faq",
  BLOG: "/blog",
  BLOG_DETAIL: "/blog-detail",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-and-condition",
  CONTACT_US: "/dashboard/contact-us",
  ALL_INVESTORS: "/dashboard/all-investors",
  INVESTOR_DETAIL: "/dashboard/investor-detail/:id",
  INFLUENCER_DETAIL: "/dashboard/influencer-detail/:id",
  FUND_DETAIL: "/dashboard/fund-detail/:id",
  ALL_INFLUENCERS: "/dashboard/all-influencers",
  ALL_FUNDS: "/dashboard/all-funds",
  OUTREACH: "/dashboard/outreach",
  PLANS: "/dashboard/plans",
  SETTING: "/dashboard/setting",
  SAVED_FILTERS: "/dashboard/saved-filters/:id",
  CHANGE_PASSWORD: "/dashboard/change-password",
  PAYMENT_SESSION: "/payment-session",
  VERIFY_ACCOUNT: "/verify-account",
};

export const ROUTE_LIST = [
  { link: ROUTES_CONSTANT.CONTACT_US, element: ContactUs, isLayout: false },
  {
    link: ROUTES_CONSTANT.ALL_INVESTORS,
    element: AllInvestors,
    isLayout: true,
  },
  {
    link: ROUTES_CONSTANT.INVESTOR_DETAIL,
    element: InvestorsDetail,
    isLayout: true,
  },
  {
    link: ROUTES_CONSTANT.ALL_INFLUENCERS,
    element: AllInfluencer,
    isLayout: true,
  },
  {
    link: ROUTES_CONSTANT.INFLUENCER_DETAIL,
    element: InfluencersDetail,
    isLayout: true,
  },
  { link: ROUTES_CONSTANT.ALL_FUNDS, element: AllFunds, isLayout: true },
  {
    link: ROUTES_CONSTANT.FUND_DETAIL,
    element: FundsDetail,
    isLayout: true,
  },
  { link: ROUTES_CONSTANT.OUTREACH, element: OutReach, isLayout: true },
  { link: ROUTES_CONSTANT.PLANS, element: Plans, isLayout: true },
  {
    link: ROUTES_CONSTANT.SAVED_FILTERS,
    element: SavedFilters,
    isLayout: true,
  },
  {
    link: ROUTES_CONSTANT.CHANGE_PASSWORD,
    element: ChangePassword,
    isLayout: false,
  },
  {
    link: ROUTES_CONSTANT.PAYMENT_SESSION,
    element: PaymentSession,
    isLayout: false,
  },
];

export const SIDE_NAVIGATION = {
  ALL_INVESTORS: "All Investors",
  ALL_INFLUENCERS: "All Influencer",
  ALL_FUNDS: "All Funds",
  OUTREACH: "Outreach",
  RAIDERLY_PRO: "Raiderly Pro",
};

export const SAVED_SEARCHES = {
  PRE_SEED: "Pre-seed",
  SEED: "Seed",
  SERIES_A: "Series A",
  INVEST_IN_UK: "Invest in UK",
};

/* --- HTTP Request Methods --- */
export const HTTP_METHODS = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  DELETE: "delete",
};

/* --- HTTP Status Codes --- */
export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  RESOURCE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const ROLE_TYPE = {
  USER: "user",
  INFLUENCER: "influencer",
  INVESTOR: "investor",
  FUND: "fund",
};

export const CONTACT_US_OPTIONS = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

export const PRICING_PLAN = {
  FREE: "free",
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export const PRICING_PLAN_NAME = {
  BASIC: {
    NAME: "Basic",
    PRICE: {
      MONTHLY: "free",
      YEARLY: "free",
    },
  },
  PRO: {
    NAME: "Pro",
    PRICE: {
      MONTHLY: "$14.99",
      YEARLY: "$149.99",
    },
  },
};

export const PAGE_TITLE = {
  INFLUENCERS: "Influencers",
  INVESTORS: "Investors",
  FUNDS: "Funds",
  PLANS: "Plans",
  OUTREACH: "Outreach",
};

export const QUERY_TYPE = {
  FILTERS: "filters",
  SEARCH: "search",
};
