import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ScrollToTop from "../hooks/ScrollToTop";
import { ROUTES_CONSTANT, ROUTE_LIST } from "../constant";
import ScreenLoader from "../components/Loaders/ScreenLoader";
import PublicRoute from "./Public";
import PrivateRoute from "./Private";
import SignIn from "../pages/SignIn";
import HomePage from "../pages/HomePage";
import Pricing from "../pages/Pricing";
import FAQ from "../pages/FAQ";
import Blog from "../pages/Blog";
import BlogDetail from "../pages/BlogDetail";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import SignUp from "../pages/SignUp";
import TermsAndCondition from "../pages/TermsAndCondition";
import MainLayout from "../Layout";
import PaymentSession from "../pages/paymentSession";
import ResetPassword from "../pages/resetPassword";
import VerifyAccount from "../pages/VerifyAccount";

const MainRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.document.title = `${
      pathname.includes(`${ROUTES_CONSTANT.DASHBOARD}/`)
        ? pathname
            .replace(ROUTES_CONSTANT.DASHBOARD + "/", "")
            .replace("-", " ")
            .replace("all", "")
            .toUpperCase()
        : pathname === "/"
        ? "HOME"
        : pathname.replace("/", "").replace("-", " ").toUpperCase()
    } | Dashboard | Raiderly`;
  }, [pathname]);

  return (
    <>
      <Suspense fallback={<ScreenLoader />}>
        <ScrollToTop />
        <Routes>
          <Route path={ROUTES_CONSTANT.HOME} element={<HomePage />} />
          <Route path={ROUTES_CONSTANT.PRICING} element={<Pricing />} />
          <Route path={ROUTES_CONSTANT.FAQ} element={<FAQ />} />
          <Route path={ROUTES_CONSTANT.BLOG} element={<Blog />} />
          <Route path={ROUTES_CONSTANT.BLOG_DETAIL} element={<BlogDetail />} />
          <Route
            path={ROUTES_CONSTANT.VERIFY_ACCOUNT}
            element={<VerifyAccount />}
          />
          <Route
            path={ROUTES_CONSTANT.PRIVACY_POLICY}
            element={<PrivacyPolicy />}
          />
          <Route
            path={ROUTES_CONSTANT.TERMS_AND_CONDITION}
            element={<TermsAndCondition />}
          />
          {/* <Route
            path={ROUTES_CONSTANT.PAYMENT_SESSION}
            element={<PaymentSession />}
          /> */}

          {/* for Public Route */}
          <Route element={<PublicRoute />}>
            <Route path={ROUTES_CONSTANT.SIGN_IN} element={<SignIn />} />
            <Route path={ROUTES_CONSTANT.SIGN_UP} element={<SignUp />} />
            <Route
              path={ROUTES_CONSTANT.RESET_PASSWORD}
              element={<ResetPassword />}
            />
          </Route>
          {/* for Private Route  */}
          <Route element={<PrivateRoute />}>
            {ROUTE_LIST.map(({ link, element: Component, isLayout }, i) =>
              isLayout ? (
                <Route
                  key={"routes---" + i}
                  path={link}
                  element={
                    <MainLayout>
                      <Component />
                    </MainLayout>
                  }
                />
              ) : (
                <Route
                  key={"routes---" + i}
                  path={link}
                  element={<Component />}
                />
              )
            )}
          </Route>

          <Route
            path="*"
            element={<Navigate to={ROUTES_CONSTANT.HOME} replace />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default MainRouter;
