import React, { useEffect } from "react";
import Button from "../../components/common/Button";
import SubscriptionCard from "../../components/Plans/SubscriptionCard";
import { PAGE_TITLE, PRICING_PLAN, PRICING_PLAN_NAME } from "../../constant";
import {
  PRICING_PLAN_BASIC_FEATURES,
  PRICING_PLAN_PRO_FEATURES,
} from "../../constant/data";
import { useProps } from "../../contexts/PropsContext";

const Plans = () => {
  const { setHeaderData } = useProps();

  const [activePlanOption, setActivePlanOption] = React.useState<string>(
    PRICING_PLAN.MONTHLY
  );

  useEffect(() => {
    setHeaderData({
      pageTitle: PAGE_TITLE.PLANS,
    });

    return () => {
      setHeaderData({
        pageTitle: "",
        pageIcon: "",
        dataCount: 0,
      });
    };
  }, []);

  return (
    <>
      <section
        id="subscriptionMain"
        className="w-full 2xl:pt-30 flex flex-col items-center gap-8 lg:gap-16 mb-10 px-4 md:px-0"
      >
        <div className="flex flex-col items-center gap-10 lg:gap-12 w-full md:w-9/12 lg:w-8/12 xl:w-8/12 2xl:w-6/12">
          <div className="flex items-center rounded-rounded63 bg-gray-100 pt-4 md:pt-3 pb-3 md:pb-2 px-2 2xl:px-4 gap-0 md:gap-4">
            <Button
              padding="py-1 px-2 md:px-3"
              className={`rounded-rounded63 text-mainBlackColor font-medium text-xs md:text-sm 2xl:text-2xl 
              ${
                activePlanOption === PRICING_PLAN.MONTHLY
                  ? "bg-mainTextColor text-white hover:bg-opacity-80"
                  : ""
              }
              `}
              text="Monthly"
              icon={""}
              onClick={() => setActivePlanOption(PRICING_PLAN.MONTHLY)}
            />
            <Button
              padding="py-1 px-2 md:px-3"
              className={`rounded-rounded63 text-mainBlackColor font-medium text-xs md:text-sm 2xl:text-2xl ${
                activePlanOption === PRICING_PLAN.YEARLY
                  ? "bg-mainTextColor text-white hover:bg-opacity-80"
                  : ""
              }`}
              text="Yearly"
              icon={""}
              onClick={() => setActivePlanOption(PRICING_PLAN.YEARLY)}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-10 lg:gap-4 xl:gap-6">
            {activePlanOption === PRICING_PLAN.MONTHLY ? (
              <>
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/propricing_icon.svg"
                  levels={PRICING_PLAN_NAME.BASIC.NAME}
                  levelsTextColor="text-mainTextColor"
                  price={PRICING_PLAN_NAME.BASIC.PRICE.MONTHLY}
                  planDuration={PRICING_PLAN.FREE}
                  pricingPlan={PRICING_PLAN.MONTHLY}
                  priceTextColor="text-mainTextColor"
                  priceTextSpanColor="text-mainColor"
                  subscriptionsDivBackground="bg-gray-50"
                  buttonStyling="bg-mainTextColor hover:bg-mainColor text-white"
                  diamondBackground="bg-gray-200"
                  requirementTextColor="text-mainTextColor"
                  requirements={PRICING_PLAN_BASIC_FEATURES}
                />
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/basicpricing_icon.svg"
                  levels={PRICING_PLAN_NAME.PRO.NAME}
                  levelsTextColor="text-white"
                  price={PRICING_PLAN_NAME.PRO.PRICE.MONTHLY}
                  planDuration={PRICING_PLAN.MONTHLY}
                  pricingPlan={PRICING_PLAN.MONTHLY}
                  priceTextColor="text-white"
                  priceTextSpanColor="text-mainTextColor"
                  subscriptionsDivBackground="bg-mainColor/80"
                  buttonStyling="bg-white hover:bg-mainTextColor hover:text-white text-mainTextColor"
                  diamondBackground="bg-mainColor"
                  requirementTextColor="text-white"
                  requirements={PRICING_PLAN_PRO_FEATURES}
                />
              </>
            ) : (
              <>
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/propricing_icon.svg"
                  levels={PRICING_PLAN_NAME.BASIC.NAME}
                  levelsTextColor="text-mainTextColor"
                  price={PRICING_PLAN_NAME.BASIC.PRICE.YEARLY}
                  planDuration={PRICING_PLAN.FREE}
                  pricingPlan={PRICING_PLAN.YEARLY}
                  priceTextColor="text-mainTextColor"
                  priceTextSpanColor="text-mainColor"
                  subscriptionsDivBackground="bg-gray-50"
                  buttonStyling="bg-mainTextColor hover:bg-mainColor text-white"
                  diamondBackground="bg-gray-200"
                  requirementTextColor="text-mainTextColor"
                  requirements={PRICING_PLAN_BASIC_FEATURES}
                />
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/basicpricing_icon.svg"
                  levels={PRICING_PLAN_NAME.PRO.NAME}
                  levelsTextColor="text-white"
                  price={PRICING_PLAN_NAME.PRO.PRICE.YEARLY}
                  planDuration={PRICING_PLAN.YEARLY}
                  pricingPlan={PRICING_PLAN.YEARLY}
                  priceTextColor="text-white"
                  priceTextSpanColor="text-mainTextColor"
                  subscriptionsDivBackground="bg-mainColor/80"
                  buttonStyling="bg-white hover:bg-mainTextColor hover:text-white text-mainTextColor"
                  diamondBackground="bg-mainColor"
                  requirementTextColor="text-white"
                  requirements={PRICING_PLAN_PRO_FEATURES}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Plans;
