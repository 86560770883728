import React from "react";
import LeftManImage from "../../components/Auth/LeftMan";
import RightManImage from "../../components/Auth/RightMan";
import Input from "../../components/Auth/Input";
import SelectBox from "../../components/Auth/SelectBox";
import Button from "../../components/common/Button";
import {
  HTTP_METHODS,
  HTTP_STATUS_CODES,
  ROUTES_CONSTANT,
} from "../../constant";
import { NavLink } from "react-router-dom";
import Cross from "../../components/svg/Cross";
import { ContactUsFormInterface } from "../../interfaces";
import { useForm } from "react-hook-form";
import { ContactUsSchema } from "../../validation-schema";
import SmallLoader from "../../components/Loaders/SmallLoader";
import { REQ } from "../../constant/api-endpoints";
import { fetchRequest } from "../../utils/axios";
import toast from "react-hot-toast";

const ContactUs = () => {
  const {
    register,
    handleSubmit: fromSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ resolver: ContactUsSchema });

  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmitForm = async (values: ContactUsFormInterface) => {
    try {
      setLoading(true);

      const response = await fetchRequest({
        url: REQ.CONTACT_US.CREATE,
        type: HTTP_METHODS.POST,
        body: values,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Message sent successfully");
        reset();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="contactUs" className="w-full h-full relative">
      <NavLink
        to={ROUTES_CONSTANT.ALL_INVESTORS}
        className="absolute top-6 md:top-8 left-6 md:left-10 z-20 cursor-pointer"
      >
        <Cross className="" />
      </NavLink>
      <img
        alt="_contact"
        className="absolute left-0 top-0 w-full h-full object-cover"
        src="/images/SignIn/loginPattern.svg"
      />
      <div className="w-full py-28 md:py-20 lg:py-16 z-10 relative min-h-vh100">
        <LeftManImage
          className="max-sm:hidden hidden md:block"
          imgSize="w-full"
        />
        <RightManImage
          className="hidden md:block max-sm:hidden"
          imgSize="w-full"
        />
        <form className="max-md:px-3" onSubmit={fromSubmit(onSubmitForm)}>
          <div className="border rounded-3xl w-2/5 mx-auto max-xl:w-[500px] max-sm:w-full bg-white bg-opacity-50">
            <div className="px-10 py-10 max-lg:pb-0 max-md:px-4 pb-0 2xl:py-16">
              <div className="gap-6 grid max-md:gap-6 2xl:gap-16 w-full">
                <h1 className="font-semibold text-4xl text-mainTextColor text-center">
                  Contact Us
                </h1>
                <Input
                  {...register("name", { required: true })}
                  className="mb-4"
                  inputClassName="rounded-full"
                  label="Full Name"
                  placeHolder="Full name"
                  error={errors.name?.message}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("name", e.target.value)
                  }
                />
                <Input
                  {...register("email", { required: true })}
                  className="mb-4"
                  inputClassName="rounded-full"
                  label="Email address"
                  placeHolder="Email address"
                  error={errors.email?.message}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("email", e.target.value)
                  }
                />
                <Input
                  {...register("subject", { required: true })}
                  className="mb-4"
                  inputClassName="rounded-full"
                  label="Subject"
                  placeHolder="Subject"
                  error={errors.subject?.message}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("subject", e.target.value)
                  }
                />
                <Input
                  {...register("message", { required: true })}
                  className="mb-4"
                  inputClassName="pb-20 h-auto rounded-xl pt-3"
                  label="Message"
                  placeHolder="Write your message..."
                  error={errors.message?.message}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("message", e.target.value)
                  }
                />
                {loading ? (
                  <div className="py-4 2xl:py-6 mb-12">
                    <SmallLoader />
                  </div>
                ) : (
                  <Button
                    padding="py-4 2xl:py-6"
                    className="rounded-rounded63 w-full hover:bg-mainTextColor bg-mainColor text-white text-xl 2xl:text-3xl font-medium flex justify-center mb-12"
                    text="Send Message"
                    icon=""
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
