import { useEffect, useState } from "react";
import { useUserAuth } from "../../hooks/auth.hook";
import toast from "react-hot-toast";
import { fetchRequest } from "../../utils/axios";
import { REQ } from "../../constant/api-endpoints";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTES_CONSTANT } from "../../constant";
import Button from "../../components/common/Button";
import { getQueryParams } from "../../utils/getQueryParams";
import Logo from "../../components/common/Logo";
import LeftManImage from "../../components/Auth/LeftMan";
import RightManImage from "../../components/Auth/RightMan";
import Cross from "../../components/svg/Cross";

const VerifyAccount = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { email, token } = getQueryParams(search);

  const [isLoading, setIsLoading] = useState(false);
  const { updateUserDetails, loggedInUser } = useUserAuth();

  useEffect(() => {
    if (!email && !token) {
      navigate(ROUTES_CONSTANT.SIGN_IN);
    }
  }, []);

  const handleClick = () => {
    setIsLoading(true);

    toast
      .promise(
        fetchRequest({
          url: REQ.AUTH.VERIFY_ACCOUNT,
          type: "post",
          body: { token, email },
        }),
        {
          loading: "Please wait...",
          success: "Account Verified Successfully",
          error: "an error occurred",
        }
      )
      .then((res) => {
        updateUserDetails(res.data.user);
        loggedInUser({
          access: res.data.accessToken,
          refresh: res.data.refreshToken,
        });
        navigate(ROUTES_CONSTANT.HOME);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="w-full flex justify-center bg-loginBgColor min-h-[100vh]">
      <NavLink
        to={ROUTES_CONSTANT.HOME}
        className="absolute top-6 md:top-8 left-6 md:left-10 z-20 cursor-pointer"
      >
        <Cross className="" />
      </NavLink>
      <div className="w-full h-full transition-all duration-300 relative">
        <LeftManImage
          className="max-sm:hidden hidden md:block"
          imgSize="w-full"
        />
        <RightManImage
          className="hidden md:block max-sm:hidden"
          imgSize="w-full"
        />
        <div className="relative w-full h-full flex items-center justify-center">
          <div className="absolute rounded-[10px] h-[300px] bg-white custom-shadow pt-10 pb-7 px-4 md:px-12 flex flex-col justify-center items-center w-[90%] md:w-[53%] lg:w-[45%] xl:w-[35%] 2xl:w-[50%] py-5">
            <Logo />
            <p className="font-medium text-xl md:text-[24px] text-mainTextColor my-3">
              Verify your account
            </p>

            <Button
              padding=""
              className="pt-[14px] pb-[13px] bg-mainColor text-white w-full rounded-[10px] mt-5 disabled:cursor-not-allowed flex gap-2 justify-center items-center font-semibold mb-1 border group"
              icon=""
              text="Verify"
              onClick={handleClick}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
