import React from "react";
import { Checkbox, CheckboxProps } from "@mui/material";

interface propTypes extends CheckboxProps {
  label: string;
  error: string;
}

const CheckBox = React.forwardRef(
  ({ label, error, className, ...props }: propTypes, ref: any) => {
    return (
      <div className={`flex items-center ${className}`}>
        <p className="font-medium text-sm md:text-base text-mainTextColor 2xl:text-lg">
          {label}
        </p>
        <Checkbox
          {...props}
          ref={ref}
          sx={{
            color: "#FF7425",
            "&.Mui-checked": {
              color: "#FF7425",
            },
          }}
        />

        {error && <p className="text-red-500 text-sm mt-3">{error}</p>}
      </div>
    );
  }
);

export default CheckBox;
