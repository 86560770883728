import React from 'react'
import Check from '../../svg/Check'

interface propsTypes {
  text: string,
  checkBg: string,
  textColor: string,
  stroke: string,
}

const RequirementBox = ({ text, checkBg, textColor, stroke }: propsTypes) => {
  return (
    <div className="flex items-center gap-3">
      <Check stroke={stroke} checkBg={checkBg} />
      <p className={`${textColor}`}>{text}</p>
    </div>
  )
}

export default RequirementBox