import {
  SectionFiveDataInterface,
  SectionFourDataInterface,
  SectionOneDataInterface,
  SectionSevenDataInterface,
  SectionSixDataInterface,
  SectionThreeDataInterface,
  SectionTwoDataInterface,
} from "../../interfaces";

export const SectionOneData: SectionOneDataInterface = {
  heading: "",
  paragraph: "",
  imageOne: "",
  imageTwo: "",
};

export const SectionTwoData: SectionTwoDataInterface = {
  headingOne: "",
  headingTwo: "",
  paragraph: "",
};

export const SectionThreeData: SectionThreeDataInterface = {
  heading: "",
  desktopImage1: "",
  mobileImage1: "",
  desktopImage2: "",
  mobileImage2: "",
  desktopImage3: "",
  mobileImage3: "",
};

export const SectionFourData: SectionFourDataInterface = {
  heading: "",
  paragraph: "",
  image: "",
};

export const SectionFiveData: SectionFiveDataInterface = {
  heading: "",
  paragraph: "",
  image: "",
};

export const SectionSixData: SectionSixDataInterface = {
  heading: "",
  review: [],
};

export const SectionSevenData: SectionSevenDataInterface = {
  image: "",
};
