import React from "react";
import { useLocation } from "react-router-dom";
import { ROUTES_CONSTANT } from "../constant";
import { useUi } from "../hooks/user-interface";

const FilterModal = () => {
  const location = useLocation();
  const { hideModal } = useUi();
  return (
    <div
      id="filterModal"
      className="bg-black bg-opacity-20 w-full h-full flex items-center justify-center fixed top-0 left-0 z-50 py-5 my-auto overflow-y-auto hideScrollBar lg:hidden"
    >
      <div
        className={`bg-white rounded-3xl px-2 md:px-3 pt-10 xl:pt-12 pb-8 w-11/12 md:w-10/12 relative m-auto grid md:grid-cols-4 ${
          location.pathname.includes(
            ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
          )
            ? "grid-cols-2"
            : "grid-cols-3"
        } items-center gap-x-3 gap-y-5`}
      >
        <img
          alt="filterModal"
          onClick={hideModal}
          className="absolute -top-3 -right-3"
          src="/images/Close Circle.svg"
        />

        {/* <SelectBox
          placeHolder="Type"
          badgeContent={
            <div className="h-4 w-4 rounded-full bg-mainColor flex items-center justify-center text-xs text-white font-medium">
              <p>1</p>
            </div>
          }
        /> */}
        {/* <SelectBox options={INDUSTRY_OPTIONS} placeHolder="Industry" />
            <SelectBox options={GEOGRAPHY_OPTIONS} placeHolder="Geography" />
            <SelectBox
              options={STAGE_OPTIONS}
              placeHolder="Stage"
              badgeContent={
                <div className="h-4 w-4 rounded-full bg-mainColor flex items-center justify-center text-xs text-white font-medium">
                  <p>1</p>
                </div>
              }
            /> */}
        {/* <SelectBox options={LOCATION_OPTIONS} placeHolder="Location" /> */}
        {/* <SelectBox menuOpen={false} placeHolder="Leading" /> */}
        {/* <SelectBox menuOpen={false} placeHolder="Diversity" /> */}
        {/* <SelectBox menuOpen={false} placeHolder="Title" /> */}
      </div>
    </div>
  );
};

export default FilterModal;
