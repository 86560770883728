import React, { useState } from "react";
import Button from "../../components/common/Button";
import { ROLE_TYPE } from "../../constant";
import OutReachTable from "../../components/OutReach/OutReachTable";

const OutReach = () => {
  const [activeOption, setActiveOption] = useState(ROLE_TYPE.INVESTOR);

  const onActiveOptions = (link: string) => {
    setActiveOption(link);
  };

  return (
    <section id="outReach" className="w-full">
      <div className="w-full flex justify-center md:justify-end items-center mb-8">
        <div className="flex items-center w-auto md:w-9/12 xl:w-4/6 justify-center md:justify-between">
          <div className="py-2 2xl:py-4 px-3 rounded-full gap-3 shadow-customShadow flex items-center bg-white">
            <div onClick={() => onActiveOptions(ROLE_TYPE.INVESTOR)}>
              <Button
                padding="py-2 px-3 md:px-4"
                className={`rounded-rounded63 font-medium text-sm 2xl:text-2xl ${
                  activeOption === ROLE_TYPE.INVESTOR
                    ? "bg-mainColor text-white hover:bg-opacity-80"
                    : "bg-white text-mainTextColor"
                }`}
                text="Investors"
                icon=""
              />
            </div>
            <div onClick={() => onActiveOptions(ROLE_TYPE.INFLUENCER)}>
              <Button
                padding="py-2 px-3 md:px-4"
                className={`rounded-rounded63 font-medium text-sm 2xl:text-2xl ${
                  activeOption === ROLE_TYPE.INFLUENCER
                    ? "bg-mainColor text-white hover:bg-opacity-80"
                    : "bg-white text-mainTextColor"
                }`}
                text="Influencers"
                icon=""
              />
            </div>
            <div onClick={() => onActiveOptions(ROLE_TYPE.FUND)}>
              <Button
                padding="py-2 px-3 md:px-4"
                className={`rounded-rounded63 font-medium text-sm 2xl:text-2xl ${
                  activeOption === ROLE_TYPE.FUND
                    ? "bg-mainColor text-white hover:bg-opacity-80"
                    : "bg-white text-mainTextColor"
                }`}
                text="Funds"
                icon=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dataTableContainer w-full bg-white px-3 py-8 rounded-2xl">
        {activeOption === ROLE_TYPE.INVESTOR && (
          <OutReachTable role={ROLE_TYPE.INVESTOR} />
        )}
        {activeOption === ROLE_TYPE.INFLUENCER && (
          <OutReachTable role={ROLE_TYPE.INFLUENCER} />
        )}
        {activeOption === ROLE_TYPE.FUND && (
          <OutReachTable role={ROLE_TYPE.FUND} />
        )}
      </div>
    </section>
  );
};

export default OutReach;
