import React, { useState } from "react";
import { fetchRequest } from "../../utils/axios";
import { REQ } from "../../constant/api-endpoints";
import toast, { LoaderIcon } from "react-hot-toast";
import { useProps } from "../../contexts/PropsContext";
import { useUserAuth } from "../../hooks/auth.hook";

const UserInActive = () => {
  const [loading, setLoading] = useState(false);
  // const { } = useProps();
  const { user } = useUserAuth();

  const handleClick = () => {
    setLoading(true);
    toast
      .promise(fetchRequest({ url: REQ.AUTH.RESEND_EMAIL }), {
        loading: "Please wait...",
        success: "Email sent successfully",
        error: "An error occurred",
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="w-7/12 mx-auto flex flex-col items-center">
      <div className="flex flex-col items-center gap-5">
        <img
          className="h-28 w-28 rounded-full"
          src="/images/UserStatus/email.jpg"
        />
        <h1 className="text-mainTextColor text-2xl">Verify your email</h1>
        <p className=" text-mainTextColor text-center">
          We've sent an email to{" "}
          <span className="font-semibold">{user.email}</span> to verify your
          email address and active your account. The link in the email will
          expire in <span className="font-semibold">24 hours</span>.
        </p>
        <div className="flex items-center gap-3">
          <button
            onClick={handleClick}
            className="flex items-center gap-2 ms-0 text-sm font-medium mt-3 text-white md:ms-1 md:inline-flex bg-mainColor rounded-md py-2 px-4 hover:bg-mainTextColor transition-all duration-300"
          >
            Resend Email {loading && <LoaderIcon />}
          </button>
          <button className="flex items-center gap-2 ms-0 text-sm font-medium mt-3 text-white md:ms-1 md:inline-flex bg-mainColor rounded-md py-2 px-4 hover:bg-mainTextColor transition-all duration-300">
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInActive;
