import React from "react";

const HomeCross = () => {
  return (
    <svg
      className="h-5 w-5 md:h-auto md:w-auto"
      width="25"
      height="25"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 5.43367L5.00008 25.4336M5 5.43359L25 25.4336"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HomeCross;
