import React from 'react'

interface propTypes {
    className : string;
}

const SideBarFilterIcon = ({className} : propTypes) => {
  return (
    <svg className={`${className}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 5H13" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M22 13C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11L22 13ZM1.29289 11.2929C0.902369 11.6834 0.902369 12.3166 1.29289 12.7071L7.65685 19.0711C8.04738 19.4616 8.68054 19.4616 9.07107 19.0711C9.46159 18.6805 9.46159 18.0474 9.07107 17.6569L3.41421 12L9.07107 6.34315C9.46159 5.95262 9.46159 5.31946 9.07107 4.92893C8.68054 4.53841 8.04738 4.53841 7.65685 4.92893L1.29289 11.2929ZM22 11L2 11L2 13L22 13L22 11Z" fill="white"/>
        <path d="M22 19H13" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}

export default SideBarFilterIcon;