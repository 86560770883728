import React from "react";
import { RotatingLines } from "react-loader-spinner";

const SmallLoader: React.FC<{
  width?: string;
  color?: string;
  className?: string;
}> = ({ width = "30", color = "#FF7425", className = "w-full h-full" }) => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <RotatingLines
        strokeColor={color}
        strokeWidth="5"
        animationDuration="0.75"
        width={width}
        visible={true}
      />
    </div>
  );
};

export default SmallLoader;
