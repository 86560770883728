import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loggedIn, logout, updateUser } from "../store/slices/auth.slice";
import { ROUTES_CONSTANT } from "../constant";
import { deleteCookie, setCookie } from "../utils/cookies";
import { verifyUser } from "../store/action/user-verify";
import { AuthStateInterface, UserAuthHook, UserInterface } from "../interfaces";

export const useUserAuth = (): UserAuthHook => {
  const state = useSelector(
    (state: { auth: AuthStateInterface }) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateUserDetails = (obj: UserInterface) => dispatch(updateUser(obj));
  const refetchUser = () => dispatch(verifyUser() as any);

  const logoutUser = () => {
    dispatch(logout());
    deleteCookie("access_token");
    deleteCookie("refresh_token");
    localStorage.clear();
    navigate(ROUTES_CONSTANT.SIGN_IN);
  };

  const loggedInUser = ({
    access,
    refresh,
  }: {
    access: string;
    refresh: string;
  }) => {
    dispatch(loggedIn());
    setCookie("access_token", access);
    refresh && setCookie("refresh_token", refresh);
  };

  return {
    ...state,
    refetchUser,
    updateUserDetails,
    logoutUser,
    loggedInUser,
  };
};
