import { nanoid } from "@reduxjs/toolkit";
import { CardEmojiInterface, ReactSelectInterface } from "../../interfaces";

export const TYPE_OPTIONS: ReactSelectInterface[] = [
  {
    label: "Abc",
    value: "abc",
  },
];

export const INDUSTRY_OPTIONS: ReactSelectInterface[] = [
  {
    label: "Web3",
    value: "web3",
  },
  {
    label: "Blockchain",
    value: "blockchain",
  },
  {
    label: "Crypto",
    value: "crypto",
  },
  {
    label: "ML",
    value: "ml",
  },
  {
    label: "AI",
    value: "ai",
  },
];

export const GEOGRAPHY_OPTIONS: ReactSelectInterface[] = [
  {
    label: "Italy",
    value: "italy",
  },
  {
    label: "Germany",
    value: "germany",
  },
  {
    label: "France",
    value: "france",
  },
  {
    label: "Spain",
    value: "spain",
  },
];

export const STAGE_OPTIONS: ReactSelectInterface[] = [
  {
    label: "Pre-seed",
    value: "pre-seed",
  },
  {
    label: "Seed",
    value: "seed",
  },
];

export const LOCATION_OPTIONS: ReactSelectInterface[] = [
  {
    label: "Italy",
    value: "italy",
  },
  {
    label: "Germany",
    value: "germany",
  },
  {
    label: "France",
    value: "france",
  },
  {
    label: "Spain",
    value: "spain",
  },
];

export const CARDS_DATA: CardEmojiInterface[] = [
  {
    id: nanoid(),
    url: "https://res.cloudinary.com/pte-prep/image/upload/v1703050953/Seed_huiehb.svg",
  },
  {
    id: nanoid(),
    url: "https://res.cloudinary.com/pte-prep/image/upload/v1703050953/MoneyFly_itrew1.svg",
  },
  {
    id: nanoid(),
    url: "https://res.cloudinary.com/pte-prep/image/upload/v1703050952/MoneyBag_qa3po3.svg",
  },
  {
    id: nanoid(),
    url: "https://res.cloudinary.com/pte-prep/image/upload/v1703050953/Partnership_f7xudw.svg",
  },
];

export const FILTER_DATA = {
  type: [],
  industries: [],
  geography: [],
  stages: [],
  location: [],
};

export const PRICING_PLAN_BASIC_FEATURES = [
  {
    text: "Access to 38K+ investors",
    checkBg: "fill-gray-300",
    stroke: "black",
  },
  {
    text: "5 results per search",
    checkBg: "fill-gray-300",
    stroke: "black",
  },
  {
    text: "Search filtering",
    checkBg: "fill-gray-300",
    stroke: "black",
  },
  {
    text: "Investor info",
    checkBg: "fill-gray-300",
    stroke: "black",
  },
  {
    text: "Full contact info",
    checkBg: "fill-gray-300",
    stroke: "black",
  },
  {
    text: "Export 300 contacts (CSV)",
    checkBg: "fill-gray-300",
    stroke: "black",
  },
  {
    text: "50 outreach messages",
    checkBg: "fill-gray-300",
    stroke: "black",
  },
];

export const PRICING_PLAN_PRO_FEATURES = [
  {
    text: "Access to 38K+ investors",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
  {
    text: "5 results per search",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
  {
    text: "Search filtering",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
  {
    text: "Investor info",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
  {
    text: "Full contact info",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
  {
    text: "Export 1,000 contacts (CSV)",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
  {
    text: "150 outreach messages",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
  {
    text: "Priority support",
    checkBg: "fill-mainTextColor opacity-90",
    stroke: "white",
  },
];

export const TONE_OPTIONS = [
  {
    label: "Einstein Focus",
    image: "/images/Tone/einstien.png",
    value: "einstein-focus",
  },
  {
    label: "Shakespearean Elegance",
    image: "/images/Tone/shakespear.png",
    value: "shakespearean-elegance",
  },
  {
    label: "Churchillian Resolve",
    image: "/images/Tone/churchill.png",
    value: "churchillian-resolve",
  },
  {
    label: "Monroe Magnetism",
    image: "/images/Tone/marilyn.png",
    value: "monroe-magnetism",
  },
  {
    label: "Jobsian Vision",
    image: "/images/Tone/jobs.png",
    value: "jobsian-vision",
  },
  {
    label: "Gandhian Empathy",
    image: "/images/Tone/gandhi.png",
    value: "gandhian-empathy",
  },
  {
    label: "Hemingway Economy",
    image: "/images/Tone/hemingway.png",
    value: "hemingway-economy",
  },
  {
    label: "Da Vinci Creativity",
    image: "/images/Tone/da vinci.png",
    value: "da-vinci-creativity",
  },
  {
    label: "Chaplin's Charm",
    image: "/images/Tone/chaplin.png",
    value: "chaplin-s-charm",
  },
  {
    label: "Cleopatra Command",
    image: "/images/Tone/cleopatra.png",
    value: "cleopatra-command",
  },
];

export const PURPOSE_OPTIONS = [
  { label: "Investment Inquiry", image: "", value: "investment-inquiry" },
  {
    label: "Collaboration Proposal",
    image: "",
    value: "collaboration-proposal",
  },
  { label: "PR Opportunity", image: "", value: "pr-opportunity" },
  { label: "Backlink Request", image: "", value: "backlink-request" },
  { label: "Other", image: "", value: "other" },
];

export const URGENCY_LEVEL_OPTIONS = [
  { label: "Low", image: "", value: "low" },
  { label: "Medium", image: "", value: "medium" },
  { label: "High", image: "", value: "high" },
];

export const OUTREACH_STATUS_OPTIONS = [
  {
    label: "Initial Icebreaker Sent",
    value: "initial-icebreaker-sent",
    color: "#3498db",
  },
  {
    label: "Consider Follow-Up",
    value: "consider-follow-up",
    color: "#f39c12",
  },
  {
    label: "Follow-Up Needed",
    value: "follow-up-needed",
    color: "#27ae60",
  },
  {
    label: "No Interest/Unqualified",
    value: "no-interest-or-unqualified",
    color: "#e74c3c",
  },
  {
    label: "Engagement Scheduled",
    value: "engagement-scheduled",
    color: "#2ecc71",
  },
];

export const plusButtonStyle: React.CSSProperties = {
  height: "32px",
  width: "32px",
  backgroundColor: "white",
  border: "1px solid gray",
  fontSize: "20px",
  padding: "0px",
  color: "black",
  maxWidth: "30px",
  maxHeight: "30px",
  minWidth: "30px",
  minHeight: "30px",
};

export const saveButtonStyle: React.CSSProperties = {
  backgroundColor: "white",
  border: "1px solid #FF7425",
  fontSize: "16px",
  padding: "0px",
  color: "black",
  maxWidth: "100px",
  maxHeight: "40px",
  minWidth: "100px",
  minHeight: "40px",
  borderRadius: "63px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  textTransform: "capitalize",
};

export const USER_ROLE_OPTIONS = [
  { label: "Investor", image: "", value: "investor" },
  { label: "Influencer", image: "", value: "influencer" },
  { label: "Fund", image: "", value: "fund" },
];
