import React from "react";

interface propsTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeHolder: string;
  error: string;
}

const Input = React.forwardRef(
  ({ label, placeHolder, error, ...props }: propsTypes, ref: any) => {
    return (
      <div>
        <p className="mb-2 text-xl 2xl:text-2xl text-mainTextColor font-medium max-sm:text-sm  max-sm:tracking-tight max-sm:font-medium">
          {label}
        </p>
        <input
          {...props}
          ref={ref}
          type="text"
          placeholder={placeHolder}
          className="w-full p-3 pl-5 2xl:p-4 h-14 rounded-xl bg-gray-50 text-sm 2xl:text-xl border focus:border-mainColor outline-none transition-all text-gray-600 duration-300 max-md:h-11 2xl:h-20"
        />

        {error && <p className="mt-5 text-sm text-red-600">{error}</p>}
      </div>
    );
  }
);

export default Input;
