import React from "react";
import Input from "../components/Auth/Input";
import { useUi } from "../hooks/user-interface";
const SearchModal = () => {
  const { hideModal } = useUi();

  return (
    <div
      id="searchModal"
      className="bg-black bg-opacity-20 w-full h-full flex items-center justify-center fixed top-0 left-0 z-50 py-5 my-auto overflow-y-auto hideScrollBar md:hidden"
    >
      <div className="bg-white rounded-3xl px-6 pt-10 pb-8 w-11/12 relative m-auto flex justify-center gap-5">
        <img
          alt="SearchModal"
          onClick={hideModal}
          className="absolute -top-3 -right-3"
          src="/images/Close Circle.svg"
        />
        <div className="w-full">
          <Input
            className="mb-2 rounded-lg"
            label="Search"
            placeHolder="search..."
          />
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
