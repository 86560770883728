import React, { useState } from "react";
import Input from "../components/common/Input/Input";
import Cross from "../components/svg/Cross";
import Button from "../components/common/Button";
import { useUi } from "../hooks/user-interface";
import { CARDS_DATA, USER_ROLE_OPTIONS } from "../constant/data";
import { CardEmojiInterface, SaveFiltersModalInterface } from "../interfaces";
import FilterDisplay from "../components/SaveModal/FilterDisplay";
import EmojiSelection from "../components/SaveModal/EmojiSelection";
import { useForm } from "react-hook-form";
import { SaveFilterSchema } from "../validation-schema";
import toast from "react-hot-toast";
import { useUserAuth } from "../hooks/auth.hook";
import { useProps } from "../contexts/PropsContext";
import SelectBox from "../components/ReachOutModal/SelectBox";

const SaveModal = () => {
  const { hideModal, modalState } = useUi();
  const { onSaveFilters } = useProps();
  const { user } = useUserAuth();

  const state = modalState as SaveFiltersModalInterface;

  const {
    register,
    handleSubmit: fromSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: SaveFilterSchema });

  const [cardState, setCardState] = useState<CardEmojiInterface[]>(CARDS_DATA);
  const [activeCard, setActiveCard] = useState<CardEmojiInterface>(
    cardState[0]
  );

  const onSaveData = (values: { name: string; type: string }) => {
    const payload = {
      type: values.type,
      name: values.name,
      emoji: activeCard.url,
      user: user.id,
    };

    onSaveFilters(payload);
    hideModal();
  };

  return (
    <div
      id="saveModal"
      className="bg-black bg-opacity-20 w-full h-full flex items-center justify-center fixed top-0 left-0 z-50 py-5 my-auto overflow-y-auto hideScrollBar"
    >
      <div className="bg-white rounded-3xl px-4 md:px-12 xl:px-16 pt-10 xl:pt-12 pb-8 w-11/12 md:w-3/5 xl:w-2/5 relative m-auto">
        <div className="w-full flex flex-col items-center gap-5 xl:gap-7">
          <div onClick={hideModal}>
            <Cross className="h-8 w-8 md:h-10 md:w-10 absolute top-4 left-3 md:left-7" />
          </div>
          <h1 className="text-mainTextColor text-2xl md:text-3xl font-semibold">
            Save Search
          </h1>
          <form onSubmit={fromSubmit(onSaveData)}>
            <div className="flex flex-col gap-6 w-full">
              <Input
                {...register("name", { required: true })}
                placeHolder="Name"
                label="Name"
                error={errors.name?.message || ""}
              />
              <SelectBox
                {...register("type", { required: true })}
                label="List Type"
                placeHolder="List Type"
                labelClassName="!text-xl !2xl:text-2xl"
                options={[
                  {
                    label: "Select List Type",
                    value: "",
                    isDisabled: true,
                  },
                  ...USER_ROLE_OPTIONS,
                ]}
                onChange={(e: any) => setValue("type", e.value)}
                error={errors.type?.message}
              />
              <div className="flex flex-col gap-2">
                <p className="text-xl max-sm:text-sm font-medium text-mainTextColor">
                  Select Emoji
                </p>
                <EmojiSelection
                  cardState={cardState}
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  setCardState={setCardState}
                />
              </div>
              {/* <div className="flex flex-col gap-2">
                <p className="text-xl max-sm:text-sm font-medium text-mainTextColor">
                  Used Filters
                </p>
                {state && Object.entries(state.filters).length > 0 ? (
                  <>
                    {Object.entries(state.filters).map(([key, value]) => (
                      <FilterDisplay
                        key={key}
                        filterType={key.charAt(0).toUpperCase() + key.slice(1)}
                        filterItems={value}
                      />
                    ))}
                  </>
                ) : (
                  <p className="text-sm">No Filters Selected</p>
                )}
              </div> */}
            </div>
            <Button
              padding="py-3"
              className="bg-mainColor mt-10 hover:bg-opacity-80 text-white text-xl font-medium w-full flex justify-center rounded-lg"
              text="Save"
              icon=""
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SaveModal;
