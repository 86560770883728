import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES_CONSTANT } from "../../../constant";
import HeaderButton from "../../common/HeaderButton";
import Logo from "../../common/Logo";
import HomeCross from "../../svg/HomeCross";

interface sideBarProps {
  setSideBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomeSideBar = ({ setSideBar }: sideBarProps) => {
  return (
    // SideBar Start

    <div
      id="_SideBar"
      className={`top-0 right-0 h-full w-full bg-black flex justify-end fixed visible bg-opacity-20 z-20 transition-all duration-500`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`w-full md:w-3/5 transition-all duration-500 bg-white px-4 md:px-6 pt-5 md:pt-7 flex flex-col gap-10 md:gap-16`}
      >
        <div className="w-full flex items-center justify-between">
          <Logo />
          <div onClick={() => setSideBar(false)}>
            <HeaderButton
              padding="py-1 px-1"
              className="rounded-md bg-mainTextColor"
              text=""
              icon={<HomeCross />}
            />
          </div>
        </div>
        <div className="flex flex-col gap-5 md:gap-8 text-lg md:text-2xl font-medium text-black px-2">
          <NavLink
            to={ROUTES_CONSTANT.PRICING}
            className={({ isActive }) => (isActive ? "text-mainColor" : "")}
          >
            Pricing
          </NavLink>
          <NavLink
            to={ROUTES_CONSTANT.FAQ}
            className={({ isActive }) => (isActive ? "text-mainColor" : "")}
          >
            FAQ
          </NavLink>
        </div>
        <NavLink to={ROUTES_CONSTANT.SIGN_UP}>
          <HeaderButton
            padding="py-3"
            className="text-sm md:text-base w-full rounded-3xl text-white font-medium bg-mainColor border-none"
            text="Sign Up"
            icon=""
          />
        </NavLink>
      </div>
    </div>

    // SideBar End
  );
};

export default HomeSideBar;
