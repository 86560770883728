import React from "react";
import Globe from "../../svg/Globe";

const Contacts: React.FC<{
  website: string;
  email: string;
  twitter: string;
  linkedin: string;
}> = ({ website, email, twitter, linkedin }) => {
  return (
    <div className="flex items-center space-x-2 py-5 border border-gray-50 border-r-0 border-l-0 h-20">
      <div
        className="cursor-pointer p-1 flex items-center justify-center rounded-md bg-white shadow-md gap-x-2"
        onClick={() => window.open(website)}
      >
        <Globe />
        <p className="text-xs 2xl:text-xl font-medium">Website</p>
      </div>
      <div
        className="p-1 flex items-center justify-center rounded-md bg-white shadow-md cursor-pointer"
        onClick={() =>
          window.open(`mailto:${email}?subject=Subject&body=Body%20goes%20here`)
        }
      >
        <img
          alt="mail"
          className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
          src="/images/AllInvestors/mail.svg"
        />
      </div>
      <div
        className="cursor-pointer p-1 flex items-center justify-center rounded-md bg-white shadow-md"
        onClick={() => window.open(twitter)}
      >
        <img
          alt="twitter"
          className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
          src="/images/AllInvestors/Twitter.svg"
        />
      </div>
      <div
        className="cursor-pointer p-1 flex items-center justify-center rounded-md bg-white shadow-md"
        onClick={() => window.open(linkedin)}
      >
        <img
          alt="linkedIn"
          className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
          src="/images/AllInvestors/Linkedin.svg"
        />
      </div>
    </div>
  );
};

export default Contacts;
