import React from "react";

const UserBlock = () => {
  return (
    <div className="w-7/12 mx-auto flex flex-col items-center">
      <div className="flex flex-col items-center gap-5">
        <img className="h-28 w-28" src="/images/UserStatus/block.png" />
        <h1 className="text-mainTextColor text-2xl">Your Account is blocked</h1>
        <p className=" text-mainTextColor text-center">
          Your account has been blocked. Contact support to reactify your
          account.
        </p>
        <div className="flex items-center gap-3">
          <button className="flex items-center gap-2 ms-0 text-sm font-medium mt-3 text-white md:ms-1 md:inline-flex bg-mainColor rounded-md py-2 px-4 hover:bg-mainTextColor transition-all duration-300 w-full">
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserBlock;
