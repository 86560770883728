import React from "react";
import MoreDetails from "../../components/Pricing/MoreDetailSection";
import { NavLink } from "react-router-dom";
import {
  PRICING_PLAN,
  PRICING_PLAN_NAME,
  ROUTES_CONSTANT,
} from "../../constant";
import Button from "../../components/common/Button";
import SubscriptionCard from "../../components/Pricing/SubscriptionCard";
import Header from "../../components/Layout/HomeHeader/Header";
import Footer from "../../components/Layout/HomeFooter/Footer";
import {
  PRICING_PLAN_BASIC_FEATURES,
  PRICING_PLAN_PRO_FEATURES,
} from "../../constant/data";

const Pricing = () => {
  const [activePlanOption, setActivePlanOption] = React.useState<string>(
    PRICING_PLAN.MONTHLY
  );

  return (
    <>
      {/* Main Section Start */}

      <Header />

      <section id="rightPlanSection" className="w-full mb-8 2xl:mb-60">
        <div className="max-w-contentSmallScreenWidth md:max-w-contentLargeScreenWidth mx-auto relative pt-28 lg:pt-40 2xl:pt-80 flex flex-col items-center gap-28 md:gap-40 lg:gap-32 2xl:gap-0">
          <div className="flex flex-col items-center z-10">
            <h1 className="text-3xl md:text-5xl lg:text-6xl xl:text-eightyPixel font-extrabold text-center mb-1 leading-relaxed md:leading-relaxed lg:leading-relaxed w-4/5">
              The Right <span className="text-mainColor">Plan</span> For Your{" "}
              <span className="text-mainColor">Business</span>
            </h1>
            <p className="text-sm md:text-base lg:text-xl 2xl:text-3xl font-medium text-mainTextColor text-center mb-12">
              Founder-friendly pricing. Designed to bring you to success.
            </p>
          </div>
        </div>
      </section>

      {/* Main Section End */}

      {/* Subscription Section Start */}

      <section
        id="subscriptionSection"
        className="w-full mb-16 md:mb-28 lg:mb-40 2xl:mb-60"
      >
        <div className="max-w-contentSmallScreenWidth md:max-w-contentLargeScreenWidth mx-auto w-full flex flex-col items-center gap-12 lg:gap-20">
          <div className="flex items-center rounded-sixtyThreePixel bg-gray-100 pt-4 md:pt-3 pb-3 md:pb-2 px-6 md:px-4">
            <Button
              padding="py-1 px-2 md:px-3"
              className={`rounded-rounded63 text-mainBlackColor font-medium text-xs md:text-sm 2xl:text-2xl 
              ${
                activePlanOption === PRICING_PLAN.MONTHLY
                  ? "bg-mainTextColor text-white hover:bg-opacity-80"
                  : ""
              }
              `}
              text="Monthly"
              icon={""}
              onClick={() => setActivePlanOption(PRICING_PLAN.MONTHLY)}
            />
            <Button
              padding="py-1 px-2 md:px-3"
              className={`rounded-rounded63 text-mainBlackColor font-medium text-xs md:text-sm 2xl:text-2xl ${
                activePlanOption === PRICING_PLAN.YEARLY
                  ? "bg-mainTextColor text-white hover:bg-opacity-80"
                  : ""
              }`}
              text="Yearly"
              icon={""}
              onClick={() => setActivePlanOption(PRICING_PLAN.YEARLY)}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-10 lg:gap-4 xl:gap-6 md:w-9/12 lg:w-8/12 xl:w-8/12 2xl:w-6/12">
            {activePlanOption === PRICING_PLAN.MONTHLY ? (
              <>
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/propricing_icon.svg"
                  levels={PRICING_PLAN_NAME.BASIC.NAME}
                  levelsTextColor="text-mainTextColor"
                  price={PRICING_PLAN_NAME.BASIC.PRICE.MONTHLY}
                  planDuration={PRICING_PLAN.MONTHLY}
                  priceTextColor="text-mainTextColor"
                  priceTextSpanColor="text-mainColor"
                  startedTextColor="text-mainColor"
                  subscriptionsDivBackground="bg-gray-50"
                  buttonStyling="bg-mainTextColor hover:bg-mainColor text-white"
                  diamondBackground="bg-gray-200"
                  requirementTextColor="text-mainTextColor"
                  requirements={PRICING_PLAN_BASIC_FEATURES}
                />
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/basicpricing_icon.svg"
                  levels={PRICING_PLAN_NAME.PRO.NAME}
                  levelsTextColor="text-white"
                  price={PRICING_PLAN_NAME.PRO.PRICE.MONTHLY}
                  planDuration={PRICING_PLAN.MONTHLY}
                  priceTextColor="text-white"
                  priceTextSpanColor="text-mainTextColor"
                  startedTextColor="text-mainColor"
                  subscriptionsDivBackground="bg-mainColor/80"
                  buttonStyling="bg-white hover:bg-mainTextColor hover:text-white text-mainTextColor"
                  diamondBackground="bg-mainColor"
                  requirementTextColor="text-white"
                  requirements={PRICING_PLAN_PRO_FEATURES}
                />
              </>
            ) : (
              <>
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/propricing_icon.svg"
                  levels={PRICING_PLAN_NAME.BASIC.NAME}
                  levelsTextColor="text-mainTextColor"
                  price={PRICING_PLAN_NAME.BASIC.PRICE.YEARLY}
                  planDuration={PRICING_PLAN.YEARLY}
                  priceTextColor="text-mainTextColor"
                  priceTextSpanColor="text-mainColor"
                  startedTextColor="text-mainColor"
                  subscriptionsDivBackground="bg-gray-50"
                  buttonStyling="bg-mainTextColor hover:bg-mainColor text-white"
                  diamondBackground="bg-gray-200"
                  requirementTextColor="text-mainTextColor"
                  requirements={PRICING_PLAN_BASIC_FEATURES}
                />
                <SubscriptionCard
                  subscriptionsImg="/images/Pricing/basicpricing_icon.svg"
                  levels={PRICING_PLAN_NAME.PRO.NAME}
                  levelsTextColor="text-white"
                  price={PRICING_PLAN_NAME.PRO.PRICE.YEARLY}
                  planDuration={PRICING_PLAN.YEARLY}
                  priceTextColor="text-white"
                  priceTextSpanColor="text-mainTextColor"
                  startedTextColor="text-mainColor"
                  subscriptionsDivBackground="bg-mainColor/80"
                  buttonStyling="bg-white hover:bg-mainTextColor hover:text-white text-mainTextColor"
                  diamondBackground="bg-mainColor"
                  requirementTextColor="text-white"
                  requirements={PRICING_PLAN_PRO_FEATURES}
                />
              </>
            )}
          </div>
        </div>
      </section>

      {/* Subscription Section End */}

      {/* More Detail Section Start */}

      <section
        id="moreDetailSection"
        className="w-full mb-8 md:mb-20 lg:mb-32 2xl:mb-52 hidden lg:block"
      >
        <div className="max-w-contentSmallScreenWidth md:max-w-contentLargeScreenWidth mx-auto w-full flex flex-col items-center gap-12 lg:gap-20">
          <h1 className="text-5xl xl:text-6xl font-bold text-mainTextColor leading-snug md:leading-snug lg:leading-snug">
            More <span className="text-mainColor">Details</span> About Our{" "}
            <span className="text-mainColor">Pricing</span>
          </h1>
          <div className="flex flex-col items-center gap-12 w-full">
            <MoreDetails />
            <p className="text-xl font-medium text-mainTextColor">
              *You can buy extra export rows & messages anytime you want.
            </p>
          </div>
        </div>
      </section>

      {/* More Detail Section End */}

      {/* Starting With Raiderly Section Start */}

      <section
        id="startingWithRaiderlySection"
        className="w-full mb-16 md:mb-28 lg:mb-40 2xl:mb-60"
      >
        <div className="max-w-contentSmallScreenWidth md:max-w-contentLargeScreenWidth mx-auto w-full flex flex-col items-center gap-8 lg:gap-12">
          <h1 className="text-2xl lg:text-3xl 2xl:text-5xl font-bold text-mainTextColor text-center">
            Starting With <span className="text-mainColor">Raiderly</span> Is{" "}
            <span className="text-mainColor">Easy</span>,{" "}
            <span className="text-mainColor">Fast</span>, And{" "}
            <span className="text-mainColor">Free</span>.
          </h1>
          <NavLink to={ROUTES_CONSTANT.SIGN_UP}>
            <Button
              padding="py-3 lg:py-4 px-9"
              className="hover:bg-mainTextColor bg-mainColor text-base lg:text-xl text-white rounded-rounded63"
              text="Get Started"
              icon=""
            />
          </NavLink>
        </div>
      </section>

      {/* Starting With Raiderly Section End */}

      <Footer />
    </>
  );
};

export default Pricing;
