import React, { useState } from "react";
import { useUi } from "../hooks/user-interface";
import Button from "../components/common/Button";
import Card from "../components/ChooseSubscription/Card";
import WhiteCross from "../components/svg/WhiteCross";
import { PRICING_PLAN, PRICING_PLAN_NAME, ROUTES_CONSTANT } from "../constant";
import {
  PRICING_PLAN_BASIC_FEATURES,
  PRICING_PLAN_PRO_FEATURES,
} from "../constant/data";
import { useNavigate } from "react-router-dom";

const ChooseSubscriptionModal = () => {
  const { hideModal } = useUi();

  const navigate = useNavigate();

  const [selectedSubscription, setSelectedSubscription] = useState<string>(
    PRICING_PLAN.MONTHLY
  );

  const [activePlan, setActivePlan] = useState<string>(
    PRICING_PLAN_NAME.BASIC.NAME
  );

  return (
    <div className="w-11/12 md:w-5/5 xl:w-4/5 relative m-auto rounded-3xl">
      <div className="z-20 relative" onClick={hideModal}>
        <WhiteCross className="h-4 w-4 md:h-8 md:w-8 absolute top-5 left-3 cursor-pointer" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="h-full w-full pt-12 pb-4 md:pb-0 md:pt-20 pl-5 bg-mainColor flex flex-col gap-6 rounded-t-3xl md:rounded-3xl md:rounded-r-none subscriptionGradient">
          <h1 className="text-xl md:text-4xl xl:text-5xl text-white font-semibold leading-tight">
            Get info about investors
          </h1>
          <ul className="flex flex-col gap-1 font-semibold text-sm md:text-lg lg:text-xl text-white">
            <li>— Investors preferences</li>
            <li>— Location & Geo</li>
            <li>— Number of investments, exits</li>
            <li>— Contact info, including emails</li>
            <li>— Social media links</li>
            <li>— And many more</li>
          </ul>
          <p className="text-white text-sm md:text-base">
            Learn more about our plans
          </p>
        </div>
        <div className="h-full w-full bg-white rounded-b-3xl md:rounded-3xl md:rounded-l-none px-5 lg:px-8 py-6 flex flex-col items-center gap-6">
          <h1>Choose your subscription:</h1>
          <div className="flex flex-col gap-6 w-full items-center">
            <div className="flex p-1 border rounded-xl gap-1">
              <Button
                padding="p-2"
                text="Monthly"
                className={`${
                  selectedSubscription === PRICING_PLAN.MONTHLY
                    ? "bg-mainTextColor text-white"
                    : "bg-white text-mainTextColor"
                } font-semibold text-xs md:text-sm rounded-lg capitalize`}
                icon=""
                onClick={() => {
                  setSelectedSubscription(PRICING_PLAN.MONTHLY);
                  setActivePlan(PRICING_PLAN_NAME.BASIC.NAME);
                }}
              />
              <Button
                padding="p-2"
                text="Yearly"
                className={`${
                  selectedSubscription === PRICING_PLAN.YEARLY
                    ? "bg-mainTextColor text-white"
                    : "bg-white text-mainTextColor"
                } font-semibold text-xs md:text-sm rounded-lg capitalize`}
                icon=""
                onClick={() => {
                  setSelectedSubscription(PRICING_PLAN.YEARLY);
                  setActivePlan(PRICING_PLAN_NAME.BASIC.NAME);
                }}
              />
            </div>
            {selectedSubscription === PRICING_PLAN.MONTHLY ? (
              <>
                <Card
                  className={
                    activePlan === PRICING_PLAN_NAME.BASIC.NAME
                      ? "border-mainTextColor"
                      : ""
                  }
                  plan={PRICING_PLAN_NAME.BASIC.NAME}
                  price={PRICING_PLAN_NAME.BASIC.PRICE.MONTHLY}
                  requirements={PRICING_PLAN_BASIC_FEATURES}
                  onClick={() => setActivePlan(PRICING_PLAN_NAME.BASIC.NAME)}
                />
                <Card
                  className={
                    activePlan === PRICING_PLAN_NAME.PRO.NAME
                      ? "border-mainTextColor"
                      : ""
                  }
                  plan={PRICING_PLAN_NAME.PRO.NAME}
                  price={PRICING_PLAN_NAME.PRO.PRICE.MONTHLY}
                  requirements={PRICING_PLAN_PRO_FEATURES}
                  onClick={() => setActivePlan(PRICING_PLAN_NAME.PRO.NAME)}
                />
              </>
            ) : (
              <>
                <Card
                  className={
                    activePlan === PRICING_PLAN_NAME.BASIC.NAME
                      ? "border-mainTextColor"
                      : ""
                  }
                  plan={PRICING_PLAN_NAME.BASIC.NAME}
                  price={PRICING_PLAN_NAME.BASIC.PRICE.YEARLY}
                  requirements={PRICING_PLAN_BASIC_FEATURES}
                  onClick={() => setActivePlan(PRICING_PLAN_NAME.BASIC.NAME)}
                />
                <Card
                  className={
                    activePlan === PRICING_PLAN_NAME.PRO.NAME
                      ? "border-mainTextColor"
                      : ""
                  }
                  plan={PRICING_PLAN_NAME.PRO.NAME}
                  price={PRICING_PLAN_NAME.PRO.PRICE.YEARLY}
                  requirements={PRICING_PLAN_PRO_FEATURES}
                  onClick={() => setActivePlan(PRICING_PLAN_NAME.PRO.NAME)}
                />
              </>
            )}

            <Button
              padding="py-3"
              className="w-full bg-mainTextColor text-white font-medium hover:bg-opacity-80 rounded-xl text-lg flex justify-center"
              text="Continue"
              icon=""
              onClick={() => {
                hideModal();
                navigate(ROUTES_CONSTANT.PLANS);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseSubscriptionModal;
