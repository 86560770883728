import React from 'react'

const Plus = () => {
  return (
    <svg className='h-5 w-5 md:h-auto md:w-auto' width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.625 13.5H13.5M13.5 13.5H3.375M13.5 13.5V3.375M13.5 13.5V23.625" stroke="#111111" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}

export default Plus