export const faqItems = [
  {
    question: 'How many investors are on Raiderly?',
    answer:
      'Our network includes thousands of verified investors and influencers, and it continues to grow every day. Sign up for free to get the up-to-date number.',
  },
  {
    question: 'What kind of investors do you have?',
    answer: 'Answer for the second question goes here.',
  },
  {
    question: 'What kind of Influencers do you have?',
    answer: 'Answer for the third question goes here.',
  },
  {
    question: 'Are investors and influencers on the platform opted in?',
    answer: 'Answer for the fourth question goes here.',
  },
  {
    question: 'What does an AI generation mean?',
    answer: 'Answer for the fifth question goes here.',
  },
];