import React from "react";
import AllFundsTable from "../../components/AllFunds/AllFundsTable";

const AllFunds = () => {
  return (
    <section id="allFunds" className="w-full bg-white px-3 py-8 rounded-2xl">
      <AllFundsTable />
    </section>
  );
};

export default AllFunds;
