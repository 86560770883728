import React, { useEffect, useState } from "react";
import SelectBox from "../../../common/SelectBox/SelectBox";
import {
  FILTER_DATA,
  GEOGRAPHY_OPTIONS,
  INDUSTRY_OPTIONS,
  LOCATION_OPTIONS,
  STAGE_OPTIONS,
  TYPE_OPTIONS,
} from "../../../../constant/data";
import { ReactSelectInterface } from "../../../../interfaces";
import { useFilterQuery } from "../../../../hooks/filterQuery.hook";
import {
  CleanObject,
  ConvertArrayIntoSelectOptions,
} from "../../../../utils/functions";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { FetchBaseQueryMeta, MutationDefinition } from "@reduxjs/toolkit/query";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { FetchArgs } from "@reduxjs/toolkit/query";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useProps } from "../../../../contexts/PropsContext";
import { QUERY_TYPE } from "../../../../constant";

interface FilterBarProps {
  isSelectBoxsDisabled: boolean;
  url?: string;
  callback?: MutationTrigger<
    MutationDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      never,
      any,
      "fetchAllQuery"
    >
  >;
  queryType: string;
}

const FilterBar: React.FC<FilterBarProps> = ({
  isSelectBoxsDisabled,
  url,
  callback,
  queryType,
}) => {
  const {
    setSelectedFilters,
    filterTypeData,
    filterIndustryData,
    filterGeographyData,
    filterStageData,
    filterLocationData,
  } = useProps();
  const { query, clearAllQuery } = useFilterQuery();

  const [selectedType, setSelectedType] = useState<ReactSelectInterface[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<
    ReactSelectInterface[]
  >([]);
  const [selectedGeography, setSelectedGeography] = useState<
    ReactSelectInterface[]
  >([]);
  const [selectedStage, setSelectedStage] = useState<ReactSelectInterface[]>(
    []
  );
  const [selectedLocation, setSelectedLocation] = useState<
    ReactSelectInterface[]
  >([]);

  useEffect(() => {
    return () => {
      setSelectedFilters(FILTER_DATA);
      clearAllQuery();
      setSelectedType([]);
      setSelectedIndustry([]);
      setSelectedGeography([]);
      setSelectedStage([]);
      setSelectedLocation([]);
    };
  }, []);

  useEffect(() => {
    if (query) {
      setSelectedType(ConvertArrayIntoSelectOptions(query["type"]) || []);
      setSelectedIndustry(
        ConvertArrayIntoSelectOptions(query["industries"]) || []
      );
      setSelectedGeography(
        ConvertArrayIntoSelectOptions(query["geography"]) || []
      );
      setSelectedStage(ConvertArrayIntoSelectOptions(query["stages"]) || []);
      setSelectedLocation(
        ConvertArrayIntoSelectOptions(query["location"]) || []
      );
    }

    (async () => {
      if (callback) {
        let body = {};

        if (queryType === QUERY_TYPE.FILTERS) {
          const cleanQuery = CleanObject(query);
          const filterBody = { type: "filters", ...cleanQuery };
          body = Object.entries(cleanQuery).length > 0 ? { ...filterBody } : {};

          setSelectedFilters(cleanQuery);
        }

        if (queryType === QUERY_TYPE.SEARCH) {
          const searchBody = { type: "search", ...query };
          body = { ...searchBody };
        }

        await callback({
          url,
          body,
        });
      }
    })();
  }, [query]);

  return (
    <div className="hidden lg:flex items-center gap-2">
      <SelectBox
        options={
          filterTypeData &&
          filterTypeData[0]?.filters?.map((filter: string) => ({
            label: filter.charAt(0).toUpperCase() + filter.slice(1),
            value: filter.toLowerCase(),
          }))
        }
        placeHolder="Type"
        selectedOptions={selectedType}
        setSelectedOptions={setSelectedType}
        label={"type"}
        disabled={isSelectBoxsDisabled}
      />

      <SelectBox
        options={
          filterIndustryData &&
          filterIndustryData[0]?.filters?.map((filter: any) => ({
            label: filter.charAt(0).toUpperCase() + filter.slice(1),
            value: filter.toLowerCase(),
          }))
        }
        placeHolder="Industry"
        selectedOptions={selectedIndustry}
        setSelectedOptions={setSelectedIndustry}
        label={"industries"}
        disabled={isSelectBoxsDisabled}
      />
      <SelectBox
        options={
          filterGeographyData &&
          filterGeographyData[0]?.filters?.map((filter: string) => ({
            label: filter.charAt(0).toUpperCase() + filter.slice(1),
            value: filter.toLowerCase(),
          }))
        }
        placeHolder="Geography"
        selectedOptions={selectedGeography}
        setSelectedOptions={setSelectedGeography}
        label={"geography"}
        disabled={isSelectBoxsDisabled}
      />
      <SelectBox
        options={
          filterStageData &&
          filterStageData[0]?.filters?.map((filter: any) => ({
            label: filter.charAt(0).toUpperCase() + filter.slice(1),
            value: filter.toLowerCase(),
          }))
        }
        placeHolder="Stage"
        selectedOptions={selectedStage}
        setSelectedOptions={setSelectedStage}
        label={"stages"}
        disabled={isSelectBoxsDisabled}
      />
      <SelectBox
        options={
          filterLocationData &&
          filterLocationData[0]?.filters?.map((filter: any) => ({
            label: filter.charAt(0).toUpperCase() + filter.slice(1),
            value: filter.toLowerCase(),
          }))
        }
        placeHolder="Location"
        selectedOptions={selectedLocation}
        setSelectedOptions={setSelectedLocation}
        label={"location"}
        disabled={isSelectBoxsDisabled}
      />
    </div>
  );
};

export default FilterBar;
