import React from "react";
import AllInfluencersTable from "../../components/AllInfluencers/AllInfluencersTable";

const AllInfluencer = () => {
  return (
    <section
      id="allInfluencers"
      className="w-full bg-white px-3 py-8 rounded-2xl"
    >
      <AllInfluencersTable />
    </section>
  );
};

export default AllInfluencer;
