import React from 'react'

const Dots = () => {
  return (
<svg className='h-3 w-3 md:h-auto md:w-auto' width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 1.67773V1.68973M2 7.00631V7.01832M2 12.3109V12.3229" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default Dots