import React, { useEffect } from "react";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import Globe from "../svg/Globe";
import { useProps } from "../../contexts/PropsContext";
import SmallLoader from "../Loaders/SmallLoader";
import { FundInterface } from "../../interfaces";
import { PAGE_TITLE, ROLE_TYPE, ROUTES_CONSTANT } from "../../constant";
import { useNavigate } from "react-router-dom";
import { useUi } from "../../hooks/user-interface";
import { modalType } from "../../store/slices/ui.slice";
import { Badge } from "primereact/badge";
import AddFilterDropDown from "../common/AddFilterDropDown";
import { plusButtonStyle } from "../../constant/data";
import ArrowDown from "../svg/ArrowDown";

const AllFundsTable = () => {
  const { updateModal } = useUi();

  const navigate = useNavigate();
  const {
    fundData,
    fundDataLoading,
    dataTableLoading,
    fundPage,
    fundLimit,
    setFundPage,
    setFundLimit,
    setHeaderData,
  } = useProps();

  useEffect(() => {
    if (fundData) {
      setHeaderData({
        pageTitle: PAGE_TITLE.FUNDS,
        dataCount: fundData.count,
      });
    }

    return () => {
      setHeaderData({
        pageTitle: "",
        pageIcon: "",
        dataCount: 0,
      });
    };
  }, [fundData]);

  const onPaginate = (event: DataTablePageEvent) => {
    setFundPage(event.first / event.rows + 1);
    setFundLimit(event.rows);
  };

  return (
    <>
      {fundDataLoading ? (
        <SmallLoader />
      ) : (
        fundData && (
          <DataTable
            value={fundData.data || []}
            lazy
            resizableColumns
            columnResizeMode="fit"
            className="w-full"
            paginator
            onPage={onPaginate}
            totalRecords={fundData.count || 0}
            rows={fundLimit}
            first={(fundPage - 1) * fundLimit || 0}
            loading={dataTableLoading}
            pageLinkSize={fundData.totalPage || 0}
            emptyMessage={"No Funds Found"}
          >
            <Column
              header="Name"
              style={{ minWidth: "300px", maxWidth: "400px" }}
              headerClassName="py-5 bg-gray-200 font-medium rounded-tl-xl pl-3 text-base 2xl:text-2xl"
              body={(rowData: FundInterface) => (
                <div
                  className="w-fit flex items-center cursor-pointer gap-x-3 py-5 2xl:py-16 border border-gray-50 pl-2 border-r-0 border-l-0 h-20"
                  onClick={() =>
                    navigate(
                      ROUTES_CONSTANT.FUND_DETAIL.replace(":id", rowData.id)
                    )
                  }
                >
                  <img
                    className="w-12 h-12 rounded-full"
                    src={
                      rowData.avatar
                        ? rowData.avatar
                        : "/images/Funds/FundsUserImg.svg"
                    }
                    alt={"fund avatar"}
                  />
                  <div className="flex flex-col gap-1">
                    <h1 className="w-fit font-medium text-sm 2xl:text-2xl text-mainTextColor flex items-center gap-3">
                      {rowData.name}{" "}
                      {rowData.isVerified && (
                        <img
                          className="w-[22px] h-[22px]"
                          src={"/images/AllInvestors/Circle Wavy Check.svg"}
                          alt="Check"
                        />
                      )}
                    </h1>
                    <p className="text-xs 2xl:text-lg text-gray-600 font-medium w-[40%] truncate">
                      {rowData.description}
                    </p>
                  </div>
                </div>
              )}
            />
            <Column
              header="Industries"
              style={{ minWidth: "220px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(rowData: FundInterface) => (
                <div className="flex items-center space-x-2 py-5 2xl:py-16 border border-gray-50 border-r-0 border-l-0 h-20">
                  {rowData.industries.length > 0 &&
                    rowData.industries
                      .slice(0, 2)
                      .map((industry: string, index: number) => (
                        <Badge
                          key={index}
                          value={industry}
                          className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                        />
                      ))}
                  {rowData.industries.length - 2 > 0 && (
                    <Badge
                      value={`${rowData.industries.length - 2}+`}
                      className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                    />
                  )}
                </div>
              )}
            />
            <Column
              header="Geography"
              style={{ minWidth: "180px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(rowData: FundInterface) => (
                <div className="flex items-center space-x-2 py-5 2xl:py-16 border border-gray-50 border-r-0 border-l-0 h-20">
                  {rowData.geography.length > 0 &&
                    rowData.geography
                      .slice(0, 2)
                      .map((industry: string, index: number) => (
                        <Badge
                          key={index}
                          value={industry}
                          className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                        />
                      ))}
                  {rowData.geography.length - 2 > 0 && (
                    <Badge
                      value={`${rowData.geography.length - 2}+`}
                      className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                    />
                  )}
                </div>
              )}
            />
            <Column
              header="Stage"
              style={{ minWidth: "150px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(rowData: FundInterface) => (
                <div className="flex items-center space-x-2 py-5 2xl:py-16 border border-gray-50 border-r-0 border-l-0 h-20">
                  {rowData.stages.length > 0 &&
                    rowData.stages
                      .slice(0, 2)
                      .map((industry: string, index: number) => (
                        <Badge
                          key={index}
                          value={industry}
                          className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                        />
                      ))}
                  {rowData.stages.length - 2 > 0 && (
                    <Badge
                      value={`${rowData.stages.length - 2}+`}
                      className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                    />
                  )}
                </div>
              )}
            />
            <Column
              header="Contacts"
              style={{ minWidth: "320px", overflow: "visible" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(rowData: FundInterface) => (
                <div className="flex items-center space-x-2 py-5 border border-gray-50 border-r-0 border-l-0 h-20">
                  <div
                    className="cursor-pointer p-1 flex items-center justify-center rounded-md bg-white shadow-md gap-x-2"
                    onClick={() => window.open(rowData.website)}
                  >
                    <Globe />
                    <p className="text-xs 2xl:text-xl font-medium">Website</p>
                  </div>
                  <div
                    className="cursor-pointer p-1 flex items-center min-w-max justify-center rounded-md bg-white shadow-md"
                    onClick={() =>
                      window.open(
                        `mailto:${rowData.email}?subject=Subject&body=Body%20goes%20here`
                      )
                    }
                  >
                    <img
                      alt="mail"
                      className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
                      src="/images/AllInvestors/mail.svg"
                    />
                  </div>
                  <div
                    className="cursor-pointer p-1 flex items-center justify-center rounded-md bg-white shadow-md"
                    onClick={() =>
                      window.open(`https://twitter.com/${rowData.twitter}`)
                    }
                  >
                    <img
                      alt="twitter"
                      className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
                      src="/images/AllInvestors/Twitter.svg"
                    />
                  </div>
                  <div
                    className="cursor-pointer p-1 flex items-center justify-center rounded-md bg-white shadow-md"
                    onClick={() =>
                      window.open(
                        `https://www.linkedin.com/company/${rowData.linkedin}`
                      )
                    }
                  >
                    <img
                      alt="linkedIn"
                      className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
                      src="/images/AllInvestors/Linkedin.svg"
                    />
                  </div>
                  <div
                    onClick={() =>
                      updateModal({
                        type: modalType.reachout_modal,
                        state: { type: ROLE_TYPE.FUND, fund: rowData },
                      })
                    }
                    className="p-1 flex items-center justify-center rounded-md bg-white shadow-md cursor-pointer hover:bg-black hover:bg-opacity-5 transition-all duration-300"
                  >
                    <p className="text-xs 2xl:text-xl font-medium">Reach Out</p>
                  </div>
                </div>
              )}
            />
            <Column
              header="Action"
              style={{ minWidth: "170px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(rowData: FundInterface) => (
                <AddFilterDropDown
                  rowData={[rowData]}
                  text="+"
                  style={plusButtonStyle}
                />
              )}
            />
          </DataTable>
        )
      )}
    </>
  );
};

export default AllFundsTable;
