import React from "react";
import LeftManImage from "../../components/Auth/LeftMan";
import RightManImage from "../../components/Auth/RightMan";
import SignText from "../../components/Auth/SignText";
import Input from "../../components/Auth/Input";
import CheckAccount from "../../components/Auth/CheckAccount";
import SignInWithGoogle from "../../components/Auth/SignInWithGoogle";
import Button from "../../components/common/Button";
import { NavLink } from "react-router-dom";
import Cross from "../../components/svg/Cross";
import { useUi } from "../../hooks/user-interface";
import { modalType } from "../../store/slices/ui.slice";
import { useForm } from "react-hook-form";
import { fetchRequest } from "../../utils/axios";
import { REQ } from "../../constant/api-endpoints";
import toast from "react-hot-toast";
import {
  HTTP_METHODS,
  HTTP_STATUS_CODES,
  ROUTES_CONSTANT,
} from "../../constant";
import SmallLoader from "../../components/Loaders/SmallLoader";
import { useUserAuth } from "../../hooks/auth.hook";
import { SignInSchema } from "../../validation-schema";

const SignIn = () => {
  const { updateUserDetails, loggedInUser } = useUserAuth();

  const { updateModal } = useUi();

  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit: fromSubmit,
    formState: { errors },
  } = useForm({ resolver: SignInSchema });

  const handleSubmit = async (body: any) => {
    try {
      setLoading(true);

      const response = await fetchRequest({
        url: REQ.AUTH.SIGN_IN,
        type: HTTP_METHODS.POST,
        body,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        updateUserDetails(response.data.user);
        loggedInUser({
          access: response.data.accessToken,
          refresh: response.data.refreshToken,
        });

        window.location.reload();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="signIn" className="w-full h-full relative">
      <NavLink
        to={ROUTES_CONSTANT.HOME}
        className="absolute top-6 md:top-8 left-6 md:left-10 z-20 cursor-pointer"
      >
        <Cross className="" />
      </NavLink>
      <img
        alt="_signIn"
        className="absolute left-0 top-0 w-full h-full object-cover"
        src="/images/SignIn/loginPattern.svg"
      />
      <div className="w-full py-28 md:py-20 lg:py-16 z-10 relative min-h-vh100">
        <LeftManImage
          className="max-sm:hidden hidden md:block"
          imgSize="w-full"
        />
        <RightManImage
          className="hidden md:block max-sm:hidden"
          imgSize="w-full"
        />
        <form className="max-md:px-3" onSubmit={fromSubmit(handleSubmit)}>
          <div className="border rounded-3xl w-2/5 mx-auto max-xl:w-[449px] max-sm:w-full bg-white bg-opacity-50">
            <div className="px-10 py-10 max-lg:pb-0 max-md:px-4 pb-0 2xl:py-16">
              <div className="gap-12 grid max-md:gap-12 2xl:gap-16">
                <div className="flex justify-between">
                  <SignText spanText="in" text="" />
                  <NavLink to={ROUTES_CONSTANT.SIGN_UP}>
                    <CheckAccount account="No Account ?" type="Sign up" />
                  </NavLink>
                </div>
                <SignInWithGoogle text={"Sign in with Google"} />
                <Input
                  {...register("email", { required: true })}
                  className="mb-4"
                  inputClassName="rounded-full"
                  label="Email address"
                  placeHolder="Email Address"
                  error={errors.email?.message}
                />
                <div className="relative mb-4">
                  <Input
                    {...register("password", { required: true })}
                    className="mb-4"
                    type={"password"}
                    inputClassName="rounded-full"
                    label="Password"
                    placeHolder="Password"
                  />
                  {errors.password?.message && (
                    <p className="text-sm text-red-600 mt-5">
                      {errors.password?.message as any}
                    </p>
                  )}
                  <p
                    onClick={() =>
                      updateModal({
                        type: modalType.forgot_password,
                        state: {},
                      })
                    }
                    className="cursor-pointer text-right text-xs 2xl:text-xl text-mainTextColor font-semibold mt-3"
                  >
                    Forgot Password ?
                  </p>
                </div>
                {loading ? (
                  <div className="py-4 2xl:py-6 mb-12">
                    <SmallLoader />
                  </div>
                ) : (
                  <Button
                    padding="py-4 2xl:py-6"
                    className="rounded-rounded63 w-full hover:bg-mainTextColor bg-mainColor text-white text-xl 2xl:text-3xl font-medium flex justify-center mb-12"
                    text="Sign in"
                    icon=""
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SignIn;
