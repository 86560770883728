import React from "react";
import Select, { Props, StylesConfig } from "react-select";

const customStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: "#f9fafb",
    paddingTop: "10px",
    paddingBottom: "7px",
    fontSize: "14px",
    width: "100%",
    boxShadow: state.isFocused ? "orange" : "none",
    border: state.isFocused
      ? "1px solid #FF7425"
      : "1px solid rgba(0, 0, 0, 0.25)",
    "&:hover": {
      border: state.isFocused
        ? "1px solid #FF7425"
        : "1px solid rgba(0, 0, 0, 0.25)",
    },
    minWidth: "71px",
    outline: state.isFocused ? "" : "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#ffe9dd" : "white",
    cursor: "pointer",
    outline: "none",
  }),
};

interface Option {
  value: string;
  label: string;
  image?: string;
  isDisabled?: boolean;
}

interface propTypes {
  label: string;
  labelClassName?: string;
  placeHolder: string;
  options: Option[];
  error: any;
  onChange: (e: any) => void;
}

const SelectBox = React.forwardRef<any, propTypes>(
  (
    {
      label,
      placeHolder,
      labelClassName,
      options,
      error,
      onChange,
      ...props
    }: propTypes,
    ref: any
  ) => {
    return (
      <div>
        <p
          className={`mb-2 font-medium text-sm md:text-base text-mainTextColor 2xl:text-lg ${labelClassName}`}
        >
          {label}
        </p>
        <Select
          {...props}
          ref={ref}
          options={options}
          placeholder={placeHolder}
          styles={customStyles}
          onChange={onChange}
          formatOptionLabel={(options: any) => (
            <div className="country-option flex items-center gap-2">
              {options.image && <img className="h-7 w-7" src={options.image} />}
              <span className="text-mainTextColor text-sm">
                {options.label}
              </span>
            </div>
          )}
        />

        {error && <p className="text-red-500 text-sm mt-3">{error}</p>}
      </div>
    );
  }
);

export default SelectBox;
