import React, { useEffect } from "react";
import { DataTable, DataTablePageEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { useNavigate, useParams } from "react-router-dom";
import {
  HTTP_METHODS,
  HTTP_STATUS_CODES,
  ROLE_TYPE,
  ROUTES_CONSTANT,
} from "../../../constant";
import { useProps } from "../../../contexts/PropsContext";
import { REQ } from "../../../constant/api-endpoints";
import { useGetQuery } from "../../../store/store-hook";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../../../interfaces";
import SmallLoader from "../../Loaders/SmallLoader";
import { useUi } from "../../../hooks/user-interface";
import { modalType } from "../../../store/slices/ui.slice";
import {
  CapitalizeFirstLetter,
  GetCurrentRole,
} from "../../../utils/functions";
import DeleteButton from "../DeleteButton";
import RemoveSearchDropDown from "../../common/RemoveSearchDropDown";
import { IoIosMore } from "react-icons/io";
import Button from "../../common/Button";
import { fetchRequest } from "../../../utils/axios";
import toast from "react-hot-toast";
import ExportCSVButton from "../../ExportCSVButton";

const SavedFiltersTable = () => {
  const navigate = useNavigate();
  const { updateModal } = useUi();

  const { id: filterId } = useParams();
  const { searchText, setHeaderData, refetchSavedFilterNames } = useProps();

  const [pageSize, setPageSize] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(10);

  const [showRemoveSearchDropDown, setShowRemoveSearchDropDown] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    data: saveFilterData,
    isLoading,
    refetch,
  } = useGetQuery(
    `${
      REQ.SAVED_FILTER.GET_BY_ID
    }?text=${searchText}&page=${+pageSize}&limit=${+pageLimit}`.replace(
      ":id",
      filterId ?? ""
    ),
    {
      skip: !filterId,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (saveFilterData) {
      const { type, name, emoji, investorsId, influencersId, fundsId } =
        saveFilterData;
      setHeaderData({
        pageTitle: name,
        pageIcon: emoji,
        dataCount:
          type === ROLE_TYPE.INFLUENCER
            ? influencersId.count
            : type === ROLE_TYPE.INVESTOR
            ? investorsId.count
            : fundsId.count,
      });
    }

    return () => {
      setHeaderData({
        pageTitle: "",
        pageIcon: "",
        dataCount: 0,
      });
    };
  }, [saveFilterData]);

  const onDeleteSavedFilter = async (saveFilterId: string | undefined) => {
    try {
      if (!saveFilterId) {
        return;
      }
      setLoading(true);
      const response = await fetchRequest({
        url: REQ.SAVED_FILTER.DELETE_BY_ID.replace(":id", saveFilterId),
        type: HTTP_METHODS.DELETE,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        refetchSavedFilterNames();
        toast.success("Deleted successfully");
        navigate(ROUTES_CONSTANT.ALL_INVESTORS);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onPaginate = (event: DataTablePageEvent) => {
    setPageSize(event.first / event.rows + 1);
    setPageLimit(event.rows);
  };

  const onNavigateDetailPage = (type: string, id: string) => {
    if (type === ROLE_TYPE.INFLUENCER) {
      navigate(ROUTES_CONSTANT.INFLUENCER_DETAIL.replace(":id", id));
    }

    if (type === ROLE_TYPE.INVESTOR) {
      navigate(ROUTES_CONSTANT.INVESTOR_DETAIL.replace(":id", id));
    }

    if (type === ROLE_TYPE.FUND) {
      navigate(ROUTES_CONSTANT.FUND_DETAIL.replace(":id", id));
    }
  };

  return (
    <>
      {!saveFilterData ? (
        <SmallLoader />
      ) : (
        <>
          <div className="flex items-center w-full justify-end gap-3 relative mt-5 mb-6">
            <Button
              padding="py-2 2xl:py-3 px-3 lg:px-4 2xl:px-5"
              className="bg-mainColor text-white hidden md:block text-sm 2xl:text-2xl font-medium border border-white hover:bg-opacity-70 transition-all duration-300 rounded-rounded63"
              icon=""
              text="Message All"
              onClick={() =>
                updateModal({
                  type: modalType.message_all,
                  state: {
                    data: {
                      type: saveFilterData.type,
                    },
                  },
                })
              }
            />
            <ExportCSVButton
              name={CapitalizeFirstLetter(saveFilterData.name)}
              data={saveFilterData.data || []}
            />
            <Button
              onClick={() => {
                setShowRemoveSearchDropDown(!showRemoveSearchDropDown);
              }}
              padding=""
              className="bg-mainColor text-white hidden md:flex text-sm 2xl:text-2xl font-medium border border-white hover:bg-opacity-70 transition-all duration-300 rounded-md items-center justify-center h-8 w-8"
              icon={<IoIosMore />}
              text=""
            />

            <RemoveSearchDropDown
              className={`${
                showRemoveSearchDropDown
                  ? "opacity-1 visible"
                  : "opacity-0 invisible"
              }`}
              loading={loading}
              setShowRemoveSearchDropDown={setShowRemoveSearchDropDown}
              onClick={() => onDeleteSavedFilter(filterId)}
            />
          </div>

          <DataTable
            value={
              saveFilterData.type === ROLE_TYPE.INFLUENCER
                ? saveFilterData.influencersId.data
                : saveFilterData.type === ROLE_TYPE.INVESTOR
                ? saveFilterData.investorsId.data
                : saveFilterData.fundsId.data || []
            }
            lazy
            resizableColumns
            columnResizeMode="fit"
            className="w-full"
            paginator
            onPage={onPaginate}
            totalRecords={
              saveFilterData.type === ROLE_TYPE.INFLUENCER
                ? saveFilterData.influencersId.count
                : saveFilterData.type === ROLE_TYPE.INVESTOR
                ? saveFilterData.investorsId.count
                : saveFilterData.fundsId.count || 0
            }
            rows={pageLimit}
            first={(pageSize - 1) * pageLimit || 0}
            loading={isLoading}
            pageLinkSize={
              saveFilterData.type === ROLE_TYPE.INFLUENCER
                ? saveFilterData.influencersId.totalPage
                : saveFilterData.type === ROLE_TYPE.INVESTOR
                ? saveFilterData.investorsId.totalPage
                : saveFilterData.fundsId.totalPage || 0
            }
            emptyMessage={"No Record Found"}
          >
            <Column
              header="Name"
              style={{ minWidth: "300px", maxWidth: "400px" }}
              headerClassName="py-5 bg-gray-200 font-medium rounded-tl-xl pl-3 text-base 2xl:text-2xl"
              body={(rowData: any) => (
                <div
                  className="w-fit flex items-center cursor-pointer gap-x-3 py-5 2xl:py-16 border border-gray-50 pl-2 border-r-0 border-l-0 h-20"
                  onClick={() =>
                    onNavigateDetailPage(saveFilterData.type, rowData._id)
                  }
                >
                  <img
                    className="w-12 h-12 rounded-full"
                    src={
                      rowData.avatar
                        ? rowData.avatar
                        : "/images/Funds/FundsUserImg.svg"
                    }
                    alt={"fund avatar"}
                  />
                  <div className="flex flex-col gap-1">
                    <h1 className="w-fit font-medium text-sm 2xl:text-2xl text-mainTextColor flex items-center gap-3">
                      {rowData.name}{" "}
                      {rowData.isVerified && (
                        <img
                          className="w-[22px] h-[22px]"
                          src={"/images/AllInvestors/Circle Wavy Check.svg"}
                          alt="Check"
                        />
                      )}
                    </h1>
                    <p className="text-xs 2xl:text-lg text-gray-600 font-medium">
                      {saveFilterData.type === ROLE_TYPE.FUND
                        ? rowData.description.length > 50
                          ? rowData.description.slice(0, 50) + "..."
                          : rowData.description
                        : rowData.jobTitle.length > 50
                        ? rowData.jobTitle.slice(0, 50) + "..."
                        : rowData.jobTitle}
                    </p>
                  </div>
                </div>
              )}
            />
            <Column
              header="Industries"
              style={{ minWidth: "220px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(
                rowData: InvestorInterface | InfluencerInterface | FundInterface
              ) => (
                <div className="flex items-center space-x-2 py-5 2xl:py-16 border border-gray-50 border-r-0 border-l-0 h-20">
                  {rowData.industries.length > 0 &&
                    rowData.industries
                      .slice(0, 2)
                      .map((industry: string, index: number) => (
                        <Badge
                          key={index}
                          value={industry}
                          className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                        />
                      ))}
                  {rowData.industries.length - 2 > 0 && (
                    <Badge
                      value={`${rowData.industries.length - 2}+`}
                      className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                    />
                  )}
                </div>
              )}
            />
            <Column
              header="Geography"
              style={{ minWidth: "180px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(
                rowData: InvestorInterface | InfluencerInterface | FundInterface
              ) => (
                <div className="flex items-center space-x-2 py-5 2xl:py-16 border border-gray-50 border-r-0 border-l-0 h-20">
                  {rowData.geography.length > 0 &&
                    rowData.geography
                      .slice(0, 2)
                      .map((industry: string, index: number) => (
                        <Badge
                          key={index}
                          value={industry}
                          className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                        />
                      ))}
                  {rowData.geography.length - 2 > 0 && (
                    <Badge
                      value={`${rowData.geography.length - 2}+`}
                      className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                    />
                  )}
                </div>
              )}
            />
            <Column
              header="Stage"
              style={{ minWidth: "150px" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(
                rowData: InvestorInterface | InfluencerInterface | FundInterface
              ) => (
                <div className="flex items-center space-x-2 py-5 2xl:py-16 border border-gray-50 border-r-0 border-l-0 h-20">
                  {rowData.stages.length > 0 &&
                    rowData.stages
                      .slice(0, 2)
                      .map((industry: string, index: number) => (
                        <Badge
                          key={index}
                          value={industry}
                          className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                        />
                      ))}
                  {rowData.stages.length - 2 > 0 && (
                    <Badge
                      value={`${rowData.stages.length - 2}+`}
                      className="bg-orange-200 bg-opacity-75 text-gray-600 px-2 py-1 text-xs 2xl:text-lg font-medium rounded-full"
                    />
                  )}
                </div>
              )}
            />
            <Column
              header="Contacts"
              style={{ minWidth: "250px", overflow: "visible" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl "
              body={(
                rowData: InvestorInterface | InfluencerInterface | FundInterface
              ) => (
                <div className="flex items-center space-x-2 py-5 border border-gray-50 border-r-0 border-l-0 h-20">
                  <div
                    className="p-1 flex items-center justify-center rounded-md bg-white shadow-md cursor-pointer"
                    onClick={() =>
                      window.open(
                        `mailto:${rowData.email}?subject=Subject&body=Body%20goes%20here`
                      )
                    }
                  >
                    <img
                      alt="mail"
                      className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
                      src="/images/AllInvestors/mail.svg"
                    />
                  </div>
                  <div
                    className="p-1 flex items-center justify-center rounded-md bg-white shadow-md cursor-pointer"
                    onClick={() => window.open(rowData.twitter)}
                  >
                    <img
                      alt="twitter"
                      className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
                      src="/images/AllInvestors/Twitter.svg"
                    />
                  </div>
                  <div
                    className="p-1 flex items-center justify-center rounded-md bg-white shadow-md cursor-pointer"
                    onClick={() => window.open(rowData.linkedin)}
                  >
                    <img
                      alt="linkedIn"
                      className="h-auto w-auto 2xl:w-6 2xl:min-w-max 2xl:h-6"
                      src="/images/AllInvestors/Linkedin.svg"
                    />
                  </div>
                  <div
                    onClick={() => {
                      let query: any = {
                        type: GetCurrentRole(rowData.type),
                      };

                      if (GetCurrentRole(rowData.type) === ROLE_TYPE.INVESTOR) {
                        query = { ...query, investor: rowData._id };
                      }
                      if (
                        GetCurrentRole(rowData.type) === ROLE_TYPE.INFLUENCER
                      ) {
                        query = { ...query, influencer: rowData._id };
                      }
                      if (GetCurrentRole(rowData.type) === ROLE_TYPE.FUND) {
                        query = { ...query, fund: rowData._id };
                      }

                      updateModal({
                        type: modalType.reachout_modal,
                        state: { ...query },
                      });
                    }}
                    className="p-1 flex items-center justify-center rounded-md bg-white shadow-md"
                  >
                    <p className="text-xs 2xl:text-xl font-medium">Reach Out</p>
                  </div>
                </div>
              )}
            />
            <Column
              header="Action"
              style={{ minWidth: "100px", overflow: "visible" }}
              headerClassName="py-5 bg-gray-200 font-medium text-base 2xl:text-2xl"
              body={(
                rowData: InvestorInterface | InfluencerInterface | FundInterface
              ) => (
                <DeleteButton
                  filterId={filterId ?? ""}
                  rowData={rowData}
                  refetch={refetch}
                />
              )}
            />
          </DataTable>
        </>
      )}
    </>
  );
};

export default SavedFiltersTable;
