import React, { useEffect } from "react";
import { useUi } from "../hooks/user-interface";
import Cross from "../components/svg/Cross";
import Button from "../components/common/Button";
import { EmailClientInterface, OutReachInterface } from "../interfaces";
import toast from "react-hot-toast";
import { REQ } from "../constant/api-endpoints";
import {
  PURPOSE_OPTIONS,
  TONE_OPTIONS,
  URGENCY_LEVEL_OPTIONS,
} from "../constant/data";
import { ROLE_TYPE } from "../constant";
import SmallLoader from "../components/Loaders/SmallLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  CapitalizeFirstLetter,
  RemoveBeforeFirstString,
  ReplaceNewlineWithBreak,
  onOpenEmailClient,
} from "../utils/functions";
import { htmlToText } from "html-to-text";
import { useUserAuth } from "../hooks/auth.hook";

const GenerateEmailModal: React.FC<{
  isApiEnabled: boolean;
}> = ({ isApiEnabled }) => {
  const { hideModal, modalState } = useUi();

  const { user } = useUserAuth();

  const { data } = modalState as {
    data: OutReachInterface | any;
  };

  const [loading, setLoading] = React.useState<boolean>(true);
  const [emailText, setEmailText] = React.useState<string>("");
  const [disableEditor, setDisableEditor] = React.useState<boolean>(true);

  const onGenerateEmail = async () => {
    try {
      if (isApiEnabled) {
        const {
          purpose,
          tone,
          highlightPoint,
          previousInteraction,
          urgencyLevel,
          type,
          companyName,
          companyWebsite,
          callToAction,
        } = data;

        const getRoleFormData = (roleType: string) => {
          const roleFormData = new FormData();
          roleFormData.append(
            `${roleType.toLowerCase()}_id`,
            data[roleType.toLowerCase()].id
          );
          roleFormData.append("category", roleType);
          return roleFormData;
        };

        const formData = new FormData();

        formData.append(
          "outreach",
          PURPOSE_OPTIONS.find((item) => item.value === purpose)?.label || ""
        );
        formData.append(
          "com_tone",
          TONE_OPTIONS.find((item) => item.value === tone)?.label || ""
        );
        formData.append("usp", highlightPoint);
        formData.append(
          "prev_interactions",
          previousInteraction ? "YES" : "NO"
        );
        formData.append(
          "urgency_level",
          URGENCY_LEVEL_OPTIONS.find((item) => item.value === urgencyLevel)
            ?.label || ""
        );
        formData.append("entity_name", companyName);
        formData.append("links", companyWebsite);
        formData.append("action_calls", callToAction);

        if (type === ROLE_TYPE.INVESTOR) {
          const investorFormData: any = getRoleFormData(ROLE_TYPE.INVESTOR);
          for (const pair of investorFormData.entries()) {
            formData.append(pair[0], pair[1]);
          }
        } else if (type === ROLE_TYPE.INFLUENCER) {
          const influencerFormData: any = getRoleFormData(ROLE_TYPE.INFLUENCER);
          for (const pair of influencerFormData.entries()) {
            formData.append(pair[0], pair[1]);
          }
        } else if (type === ROLE_TYPE.FUND) {
          const fundFormData: any = getRoleFormData(ROLE_TYPE.FUND);
          for (const pair of fundFormData.entries()) {
            formData.append(pair[0], pair[1]);
          }
        }

        const SERVER = process.env.REACT_APP_AI_URL;

        const response = await fetch(SERVER + REQ.GENERATE_AI_EMAIL, {
          method: "POST",
          body: formData,
          redirect: "follow",
        });

        if (response.ok) {
          const { Email }: { Email: string } = await response.json();
          setEmailText(
            ReplaceNewlineWithBreak(RemoveBeforeFirstString(Email, "Hello"))
          );
        }
      } else {
        setEmailText(
          `Hello ${CapitalizeFirstLetter(
            data.type + "s"
          )}, </br></br> @Write your message here... </br></br> Regards</br>${CapitalizeFirstLetter(
            user.username
          )}`
        );
      }
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await onGenerateEmail();
    })();
  }, []);

  const onSendEmail = async () => {
    const plainText = htmlToText(emailText, {
      wordwrap: 150,
    });

    const payload: EmailClientInterface = {
      to: [data.investor?.email || data.influencer?.email || data.fund?.email],
      subject: "",
      body: plainText,
    };

    onOpenEmailClient(payload);
  };

  return (
    <div className="bg-white rounded-3xl px-4 md:px-12 xl:px-12 py-5 pb-8 w-11/12 md:w-4/5 xl:w-1/2 relative m-auto">
      <div onClick={hideModal}>
        <Cross className="h-8 w-8 md:h-10 md:w-10 absolute top-4 left-3 md:left-7" />
      </div>
      <div className="flex flex-col items-center gap-9 pt-0">
        <h1 className="font-semibold text-2xl">Generate Email</h1>
        <div className="w-full flex flex-col gap-7">
          {loading ? (
            <div className="flex gap-3 items-center justify-center">
              <SmallLoader className="w-fit" />
              Generating Email...
            </div>
          ) : (
            <>
              <div className="w-full flex flex-col gap-7">
                <CKEditor
                  editor={ClassicEditor}
                  disabled={disableEditor}
                  data={emailText}
                  onReady={() => setDisableEditor(false)}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEmailText(data);
                  }}
                />
              </div>
              <Button
                padding="py-3"
                className="w-full rounded-xl bg-mainColor text-white text-lg flex justify-center cursor-pointer hover:bg-opacity-70"
                text="Send Email"
                icon=""
                onClick={onSendEmail}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateEmailModal;
