import { StylesConfig } from 'react-select';

export const customStyles: StylesConfig = {
    control: (provided ,state) => ({
      ...provided,
      cursor: "pointer",
      minHeight:"none",
      paddingTop:"4px",
      paddingBottom:"4px",
      border:"1px solid #E4E4E4",
      backgroundColor: '#E4E4E460',
      borderRadius: '28px',
      paddingLeft: "0px",
      paddingRight: "4px",
      fontSize: "12px",
      width: "100%",
      fontWeight:"600",
      color:"#111111",
      boxShadow: state.isFocused ? "orange" : "",
      '&:hover': {
        border: state.isFocused ? "1px solid white" : "1px solid white"
      },
      '@media (min-width: 1024px) and (max-width: 2700px)': {
        backgroundColor: 'white',
        border:"1px solid white",
      },  
      '@media (min-width: 1536px) and (max-width: 2700px)': {
        fontSize: "20px"
      },  
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "5px",
      borderRadius: "14px",
      width:"15vw",
      '@media (min-width: 0px) and (max-width: 400px)': {
        width: '50vw',
      },
      '@media (min-width: 401px) and (max-width: 599px)': {
        width: '40vw',
      },
      '@media (min-width: 600px) and (max-width: 767px)': {
        width: '27vw',
      },
      '@media (min-width: 768px) and (max-width: 1023px)': {
        width: '26vw',
      },
      '@media (min-width: 1024px) and (max-width: 1279px)': {
        width: '20vw',
      },
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: "14px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    placeholder: (provided) => {
      return {
          ...provided,
          color: '#111111',
      };
    },
  }