import React, { useState } from "react";
import SideBar from "../components/Layout/Sidebar";
import Header from "../components/Layout/Header";
import { useLocation } from "react-router-dom";
import { ROUTES_CONSTANT } from "../constant";
import { useUserAuth } from "../hooks/auth.hook";
import UserInActive from "../components/UserStatus/UserInActive";
import UserBlock from "../components/UserStatus/UserBlock";

interface LayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: LayoutProps) => {
  const { user, isAuthenticated } = useUserAuth();

  const location = useLocation();
  const [showSideBar, setShowSideBar] = useState(false);

  const onShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <main id="main" className="flex bg-gray-50">
      <SideBar show={showSideBar} />
      <div
        className={`w-full flex justify-start lg:justify-end ${
          location.pathname === ROUTES_CONSTANT.OUTREACH ||
          location.pathname === ROUTES_CONSTANT.INVESTOR_DETAIL ||
          location.pathname === ROUTES_CONSTANT.INFLUENCER_DETAIL ||
          location.pathname === ROUTES_CONSTANT.INFLUENCER_DETAIL ||
          location.pathname === ROUTES_CONSTANT.FUND_DETAIL ||
          location.pathname === ROUTES_CONSTANT.PLANS ||
          location.pathname.includes(
            ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
          ) ||
          location.pathname.includes(
            ROUTES_CONSTANT.INVESTOR_DETAIL.replace(":id", "")
          ) ||
          location.pathname.includes(
            ROUTES_CONSTANT.INFLUENCER_DETAIL.replace(":id", "")
          ) ||
          location.pathname.includes(
            ROUTES_CONSTANT.FUND_DETAIL.replace(":id", "")
          )
            ? "mt-36 2xl:mt-48"
            : "mt-44 2xl:my-56"
        }`}
      >
        <div
          className={`w-full lg:w-100vw-247px flex flex-col px-4 ${
            location.pathname === ROUTES_CONSTANT.OUTREACH ||
            location.pathname === ROUTES_CONSTANT.INVESTOR_DETAIL ||
            location.pathname === ROUTES_CONSTANT.INFLUENCER_DETAIL ||
            location.pathname === ROUTES_CONSTANT.FUND_DETAIL ||
            location.pathname === ROUTES_CONSTANT.PLANS ||
            location.pathname.includes(
              ROUTES_CONSTANT.SAVED_FILTERS.replace(":id", "")
            ) ||
            location.pathname.includes(
              ROUTES_CONSTANT.INVESTOR_DETAIL.replace(":id", "")
            ) ||
            location.pathname.includes(
              ROUTES_CONSTANT.INFLUENCER_DETAIL.replace(":id", "")
            ) ||
            location.pathname.includes(
              ROUTES_CONSTANT.FUND_DETAIL.replace(":id", "")
            )
              ? "!pt-0"
              : "pt-10 lg:pt-20 xl:pt-10 2xl:pt-20"
          } pb-2`}
        >
          <Header onShowSidebar={onShowSideBar} />
          {isAuthenticated ? (
            user.status === "active" ? (
              children
            ) : user.status === "in-active" ? (
              <UserInActive />
            ) : (
              <UserBlock />
            )
          ) : (
            children
          )}
        </div>
      </div>
    </main>
  );
};

export default MainLayout;
