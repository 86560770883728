import React from "react";
import { useUi } from "../hooks/user-interface";
import { modalType } from "../store/slices/ui.slice";
import Modal from "../components/Modal";
import ForgotPass from "./ForgotPass";
import SearchModal from "./SearchModal";
import FilterModal from "./FilterModal";
import SaveModal from "./SaveModal";
import StartUpInfoModal from "./StartUpInfoModal";
import ReachOutModal from "./ReachOutModal";
import ChooseSubscriptionModal from "./ChooseSubscriptionModal";
import GenerateEmailModal from "./GenerateEmailModal";

const ModalWrapper = () => {
  const { modal } = useUi();

  const AllModal = {
    [modalType.none]: <></>,
    [modalType.filter_modal]: <FilterModal />,
    [modalType.forgot_password]: <ForgotPass />,
    [modalType.save_modal]: <SaveModal />,
    [modalType.search_modal]: <SearchModal />,
    [modalType.start_up_modal]: <StartUpInfoModal />,
    [modalType.reachout_modal]: <ReachOutModal />,
    [modalType.choose_subscription_modal]: <ChooseSubscriptionModal />,
    [modalType.generate_email]: <GenerateEmailModal isApiEnabled={true} />,
    [modalType.message_all]: <GenerateEmailModal isApiEnabled={false} />,
  };

  return <>{modal && <Modal>{AllModal[modal]}</Modal>}</>;
};

export default ModalWrapper;
