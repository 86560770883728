import React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef(({ ...props }: InputProps, ref: any) => {
  return (
    <input
      {...props}
      ref={ref}
      className="py-3 text-sm 2xl:text-2xl w-full text-white rounded-3xl outline-none border pl-10 2xl:pl-16 placeholder-white border-white bg-white bg-opacity-10"
      placeholder="Search here..."
    />
  );
});

export default Input;
