import { ROUTES_CONSTANT, SIDE_NAVIGATION } from "../../../constant";
import ChatSquare from "../../svg/ChatSquare";
import GraphNew from "../../svg/GraphNew";
import Mail from "../../svg/Mail";
import MoneyBag from "../../svg/MoneyBag";
import RaiderlyIcon from "../../svg/RaiderlyIcon";

interface IconProps {
  className: string;
}

interface SidebarItem {
  icon: React.ReactElement<IconProps>;
  name: React.ReactElement;
  link: string;
  active: boolean;
  total?: string;
}

export const sideBarData = (
  location: { pathname: string },
  data: { influencer: number; investor: number; fund: number }
): SidebarItem[] => [
  {
    icon: (
      <MoneyBag
        className={`${
          location.pathname === ROUTES_CONSTANT.ALL_INVESTORS
            ? "stroke-mainTextColor"
            : "stroke-grayColor"
        } group-hover:stroke-mainTextColor`}
      />
    ),
    name: <span>{SIDE_NAVIGATION.ALL_INVESTORS}</span>,
    link: ROUTES_CONSTANT.ALL_INVESTORS,
    active: location.pathname === ROUTES_CONSTANT.ALL_INVESTORS ? true : false,
    total: data.investor.toString(),
  },
  {
    icon: (
      <GraphNew
        className={`${
          location.pathname === ROUTES_CONSTANT.ALL_INFLUENCERS
            ? "stroke-mainTextColor"
            : "stroke-grayColor"
        } group-hover:stroke-mainTextColor`}
      />
    ),
    name: <span>{SIDE_NAVIGATION.ALL_INFLUENCERS}</span>,
    link: ROUTES_CONSTANT.ALL_INFLUENCERS,
    active:
      location.pathname === ROUTES_CONSTANT.ALL_INFLUENCERS ? true : false,
    total: data.influencer.toString(),
  },
  {
    icon: (
      <ChatSquare
        className={`${
          location.pathname === ROUTES_CONSTANT.ALL_FUNDS
            ? "stroke-mainTextColor"
            : "stroke-grayColor"
        } group-hover:stroke-mainTextColor`}
      />
    ),
    name: <span>{SIDE_NAVIGATION.ALL_FUNDS}</span>,
    link: ROUTES_CONSTANT.ALL_FUNDS,
    active: location.pathname === ROUTES_CONSTANT.ALL_FUNDS ? true : false,
    total: data.fund.toString(),
  },
  {
    icon: (
      <Mail
        className={`${
          location.pathname === ROUTES_CONSTANT.OUTREACH
            ? "stroke-mainTextColor"
            : "stroke-grayColor"
        } group-hover:stroke-mainTextColor`}
      />
    ),
    name: <span>{SIDE_NAVIGATION.OUTREACH}</span>,
    link: ROUTES_CONSTANT.OUTREACH,
    active: location.pathname === ROUTES_CONSTANT.OUTREACH ? true : false,
  },
  {
    icon: <RaiderlyIcon />,
    name: (
      <span className="text-mainColor">{SIDE_NAVIGATION.RAIDERLY_PRO}</span>
    ),
    link: ROUTES_CONSTANT.PLANS,
    active: location.pathname === ROUTES_CONSTANT.PLANS ? true : false,
  },
];
